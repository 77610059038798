<template>
  <div>
    <a-row style="padding-right: 10px">
      <a-col class="content-row" :span="24">
        <h3>PAGAMENTO PERSONALIZADO</h3>
        <div class="list">
          <a-row class="infos" :gutter="20">
            <a-col :span="12">
              <span class="label">Nome da forma de Pagamento:</span>
              {{ tempContract.payment_methods_custom_payment_name }}
            </a-col>

            <a-col :span="12">
              <span class="label">Valor total do pagamento:</span>
              {{
                formatPricePtBr(
                  tempContract.payment_methods_custom_payment_total_value
                )
              }}
            </a-col>

            <a-col
              v-if="tempContract.payment_methods_custom_payment_observation"
              class="mt-10"
              :span="24"
            >
              <span class="label"> Observações:</span>
              {{ tempContract.payment_methods_custom_payment_observation }}
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
    <!-- {{ tempContract }} -->
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";

export default {
  name: "ContractPaymentMethodsCustomPaymentDetails",
  props: {
    tempContract: Object,
  },
  mixins: [formatThings],
  data() {
    return {};
  },
};
</script>

<style lang="sass" scoped>
.content-row
  font-size: 14px
  font-weight: 600
  .label
    display: block
    font-size: 12px
    font-weight: 500
    color: #aaa
  h4
    font-size: 10px !important
    font-weight: 600
    color: #f142aa
  .travellers
    .traveller
      padding: 0 0 8px 0
      margin: 0 0 8px
      border-bottom: 1px solid #eee
    .traveller:last-child
      border: 0
      padding-bottom:0
    .font
      font-weight: 600
      border-radius: 4px
      font-size: 12px
      background: #eee
      padding: 1px 5px 2px
      margin: 4px 4px 0px 0
      text-transform: uppercase
      word-break: break-all
      float: left
  .p-row.sub
    .name
      top: 8px
      background: #FFF
      padding: 0 10px 0 22px
  .p-row.no-dot
    border: 0
    .name
      max-width: 100%
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    .name.hotel
      color: #00c100
      font-weight: 600
    .name.service
      color: #ff8000
      font-weight: 600
    .name.flight
      font-weight: 600
      color: #1890ff
  .p-row
    font-size: 12px
    border-bottom: 2px dotted #ccc
    margin: 4px
    .val
      top: 8px
      background: #FFF
      padding: 0 0px 0 5px
    .ico
      width: 25px
      text-align: center
      display: inline-block
    .name
      top: 8px
      background: #FFF
      padding: 0 10px 0 0px
      max-width: 220px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

      .num
        font-weight: 700
  .t1
    color: #00cd6b
  .t2
    color: #ff4545
  .t3
    color: #ff9a3e
  h3
    padding: 0 0 6px 0
    font-size: 14px
    font-weight: 600
    margin-bottom: 15px
    color: #ed0e93
    text-transform: uppercase
    border-bottom: 1px solid #ececec
</style>
