<template>
  <div>
    <a-row class="header" :gutter="20">
      <a-col :span="8">
        <h2>ID {{ milesCard.id }} {{ milesCard.miles_program }}</h2>
        <div class="txt name">
          {{ milesCard.supplier.first_name }}
          {{ milesCard.supplier.last_name }}
        </div>
        <div class="txt email">
          {{ milesCard.supplier.email }}
        </div>
        <div
          v-if="$route.path.split('/')[1] == 'contracts'"
          class="txt email mb-10"
        >
          Nascimento:
          {{
            milesCard.supplier != undefined
              ? formatMultiDates(milesCard.supplier.birthday)
              : ""
          }}
        </div>
        <div v-if="!viewOnly" class="txt phone">
          {{ milesCard.supplier.mobile_phone
          }}{{
            milesCard.supplier.phone ? " - " + milesCard.supplier.phone : ""
          }}
        </div>
        <div v-if="!viewOnly" class="txt date">
          Data de cadastro:
          {{ formatDateTime(milesCard.created) }}
        </div>
        <a-tag
          v-if="milesCard.status == 'blocked'"
          color="#f00"
          style="font-size: 10px; line-height: 16px; border-radius: 3px"
        >
          BLOQUEADO
        </a-tag>

        <a-tag
          v-if="milesCard.status == 'canceled'"
          color="#f00"
          style="font-size: 10px; line-height: 16px; border-radius: 3px"
        >
          CANCELADO
        </a-tag>
        <a-tag
          v-if="milesCard.status == 'available'"
          color="#00aa21"
          style="font-size: 10px; line-height: 16px; border-radius: 3px"
        >
          DISPONÍVEL
        </a-tag>
      </a-col>

      <a-col v-if="!viewOnly" class="cards" :span="16">
        <a-row :gutter="20">
          <a-col :span="8">
            <div class="box total">
              <div class="name">TOTAL</div>
              <div class="number">
                {{ milesCard.total_miles }}
              </div>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="box available">
              <div class="name">DISPONÍVEL</div>
              <div class="number">
                {{ milesCard.available_miles }}
              </div>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="box used">
              <div class="name">UTILIZADO</div>
              <div class="number">
                {{ milesCard.spent_miles }}
              </div>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>

    <a-tabs
      type="card"
      class="miles-cards-tabs"
      :defaultActiveKey="activeMilesCardTab"
      @change="onChangeTabs"
    >
      <a-tab-pane key="card" tab="Dados do Cartão" force-render>
        <a-row class="view-data" :gutter="20" style="padding: 0px 10px">
          <a-row
            type="flex"
            justify="end"
            style="padding: 0px 10px 10px"
            :gutter="20"
            v-if="!viewOnly"
          >
            <a-col>
              <a-tooltip
                v-if="editMilesCard == false"
                placement="top"
                title="Editar"
                style="position: relative; top: 4px"
              >
                <a class="edit" @click="editMilesCard = true">
                  <a-icon type="edit-svg" />
                </a>
              </a-tooltip>

              <a-tooltip
                v-if="editMilesCard == true"
                placement="top"
                title="Cancelar edição"
                style="
                  position: relative;
                  top: 2px;
                  color: #999;
                  font-size: 18px;
                "
              >
                <a class="edit" @click="editMilesCard = false">
                  <a-icon type="close-circle" />
                </a>
              </a-tooltip>

              <a-divider type="vertical" style="margin: 0 15px" />
              <a-tooltip
                placement="top"
                title="Pagamentos"
                style="position: relative; top: 3px; font-size: 20px"
              >
                <a
                  @click="
                    $router.push(
                      `/miles-finances/payments/view/${milesCard.id}`
                    )
                  "
                >
                  <a-icon style="color: #ff9800" type="calculator" />
                </a>
              </a-tooltip>
              <a-divider type="vertical" style="margin: 0 15px" />

              <a-tooltip
                placement="top"
                title="Detalhamento"
                style="position: relative; top: 3px; font-size: 20px"
              >
                <a class="edit" @click="view(milesCard.id)">
                  <a-icon type="printer" />
                </a>
              </a-tooltip>
              <a-divider type="vertical" style="margin: 0 15px" />
              <a-button
                v-if="milesCard.status == 'available'"
                type="danger"
                size="small"
                ghost
                @click="blockCard = true"
                style="font-size: 12px"
              >
                <a-icon type="stop" />
                Bloquear
              </a-button>

              <a-popconfirm
                title="Tem certeza que deseja desbloquear?"
                ok-text="Sim"
                cancel-text="Não"
                @confirm="activateCard"
              >
                <a-button
                  class="cgreen"
                  v-if="milesCard.status == 'blocked'"
                  type="primary"
                  size="small"
                  style="font-size: 12px; border-color: #00aa21"
                  ghost
                >
                  <a-icon type="check-circle" /> Desbloquear
                </a-button>
              </a-popconfirm>

              <a-popconfirm
                title="Tem certeza que deseja cancelar o cartão?"
                ok-text="Sim"
                cancel-text="Não"
                @confirm="cancelMilesCard"
              >
                <a-button
                  class="ml-20"
                  v-if="milesCard.status != 'canceled'"
                  type="danger"
                  size="small"
                  style="font-size: 12px"
                  ghost
                >
                  <a-icon type="stop" /> Cancelar
                </a-button>
              </a-popconfirm>

              <a-popconfirm
                title="Tem certeza que deseja ativar o cartão?"
                ok-text="Sim"
                cancel-text="Não"
                @confirm="activatelMilesCard"
              >
                <a-button
                  class="cgreen"
                  v-if="milesCard.status == 'canceled'"
                  type="primary"
                  size="small"
                  style="font-size: 12px; border-color: #00aa21"
                  ghost
                >
                  <a-icon type="check" /> Ativar
                </a-button>
              </a-popconfirm>
            </a-col>
          </a-row>

          <a-modal
            :visible="blockCard"
            :footer="false"
            @cancel="blockCard = false"
            width="380px"
          >
            <template slot="title">
              <a-icon class="mr-5 red" type="stop" /> BLOQUEAR CARTÃO
            </template>

            <div class="travel-input-outer mt-20" style="position: relative">
              <label :class="reasonForBlocking ? 'filled' : 'filled'">
                Motivo do bloqueio
              </label>
              <a-select
                class="travel-input"
                show-search
                placeholder="Selecione o motivo do bloqueio"
                style="width: 100%"
                v-model="reasonForBlocking"
              >
                <a-select-option
                  v-for="(reason, index) of reasonsForBlocking"
                  :key="index"
                  :value="reason"
                >
                  {{ reason }}
                </a-select-option>
              </a-select>
            </div>

            <a-button
              @click="blockMilesCard(milesCard)"
              :loading="reasonsForBlockingLoading"
              type="danger"
              class="mt-20"
              style="width: 100%"
            >
              CONFIRMAR BLOQUEIO
            </a-button>
          </a-modal>

          <a-col :span="6">
            <div class="label">Cia aérea</div>
            <div class="value">
              {{ milesCard.airline.name }}
            </div>
          </a-col>

          <a-col :span="6">
            <div class="label">Programa de Milhas</div>
            <div class="value">
              {{ milesCard.miles_program }}
            </div>
          </a-col>

          <a-col :span="12">
            <div class="label">Fornecedor</div>
            <div class="value">
              {{ milesCard.supplier.supplier_id }} -
              {{ milesCard.supplier.first_name }}
              {{ milesCard.supplier.last_name }}
            </div>
          </a-col>

          <!-- <a-col :span="12">
            <div class="label">Usuário</div>
            <div class="value">
              {{ milesCard.user }}
            </div>
          </a-col>

          <a-col :span="6">
            <div class="label">Número</div>
            <div class="value">
              {{ milesCard.number }}
            </div>
          </a-col> -->

          <!-- {{ editCard }} -->

          <a-col v-if="editMilesCard == true" :span="6" style="height: 52px">
            <div class="label">Usuário</div>
            <a-input
              class="travel-input normal-font"
              placeholder="Senha"
              v-model="editCard.user"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-col>

          <a-col v-if="editMilesCard == true" :span="6" style="height: 52px">
            <div class="label">Número</div>
            <a-input
              class="travel-input normal-font"
              placeholder="Senha"
              v-model="editCard.number"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-col>

          <a-col v-if="editMilesCard == true" :span="6" style="height: 52px">
            <div class="label">Senha</div>
            <a-input
              class="travel-input normal-font"
              placeholder="Senha"
              v-model="editCard.password"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-col>

          <a-col v-if="editMilesCard == true" :span="6" style="height: 52px">
            <div class="label">Senha de resgate</div>
            <a-input
              class="travel-input"
              placeholder="Digite a senha"
              v-model="editCard.password_recovery"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-col>

          <a-col v-if="editMilesCard == true" :span="6">
            <div class="label">Expiração das milhas</div>

            <a-date-picker
              class="travel-input"
              placeholder="Expiração das milhas"
              format="DD/MM/YYYY"
              v-mask="'##/##/####'"
              :showToday="false"
              :disabled-date="minDate"
              v-model="editCard.expires_at"
            >
            </a-date-picker>
          </a-col>

          <a-col v-if="editMilesCard == false" :span="6">
            <div class="label">Usuário</div>
            <div class="value normal-font">
              {{ milesCard.user }}
            </div>
          </a-col>

          <a-col v-if="editMilesCard == false" :span="6">
            <div class="label">Número</div>
            <div class="value normal-font">
              {{ milesCard.number }}
            </div>
          </a-col>

          <a-col v-if="editMilesCard == false" :span="6">
            <div class="label">Senha</div>
            <div class="value normal-font">
              {{ milesCard.password }}
            </div>
          </a-col>

          <a-col v-if="editMilesCard == false" :span="6">
            <div class="label">Senha de resgate</div>
            <div class="value normal-font">
              {{ milesCard.password_recovery }}
            </div>
          </a-col>

          <a-col v-if="editMilesCard == false" :span="6">
            <div class="label">Expiração das milhas</div>
            <div class="value">
              {{ formatDate(milesCard.expires_at) }}
            </div>
          </a-col>

          <a-col class="a-right mt-30" v-if="editMilesCard == true" :span="24">
            <a-divider />
            <a-button @click="editMilesCard = false" class="mr-20"
              >CANCELAR EDIÇÃO</a-button
            >
            <a-button
              @click="updateMilesCard(milesCard)"
              :loading="editCard.loading"
              type="primary"
            >
              SALVAR ALTERAÇÕES
            </a-button>
          </a-col>
        </a-row>
      </a-tab-pane>

      <a-tab-pane v-if="!viewOnly" key="supplier" tab="Fornecedor" force-render>
        <a-row class="view-data" :gutter="20" style="padding: 0px 10px">
          <a-col :span="4">
            <div class="label">ID</div>
            <div class="value">
              {{ milesCard.supplier.supplier_id }}
            </div>
          </a-col>
          <a-col :span="10">
            <div class="label">Nome do Fornecedor</div>
            <div class="value">
              {{ milesCard.supplier.first_name }}
              {{ milesCard.supplier.last_name }}
            </div>
          </a-col>
          <a-col :span="10">
            <div class="label">E-mail</div>
            <div class="value">
              {{ milesCard.supplier.email }}
            </div>
          </a-col>
          <a-col :span="8">
            <div class="label">Data de nascimento</div>
            <div class="value">
              {{ formatMultiDates(milesCard.supplier.birthday) }}
            </div>
          </a-col>
          <a-col :span="8">
            <div class="label">CPF</div>
            <div class="value">
              {{ milesCard.supplier.cpf }}
            </div>
          </a-col>
          <a-col :span="8">
            <div class="label">RG</div>
            <div class="value">
              {{ milesCard.supplier.rg }} - {{ milesCard.supplier.rg_emissor
              }}{{ milesCard.supplier.rg_state }}
            </div>
          </a-col>
          <a-col :span="8">
            <div class="label">Telefone fixo</div>
            <div class="value">
              {{ milesCard.supplier.phone }}
            </div>
          </a-col>
          <a-col :span="8">
            <div class="label">Celular</div>
            <div class="value">
              {{ milesCard.supplier.mobile_phone }}
            </div>
          </a-col>
          <a-col :span="8">
            <div class="label">CEP</div>
            <div class="value">
              {{ milesCard.supplier.cep }}
            </div>
          </a-col>
          <a-col :span="16">
            <div class="label">Logradouro</div>
            <div class="value">
              {{ milesCard.supplier.location_street }}
            </div>
          </a-col>
          <a-col :span="8">
            <div class="label">Número</div>
            <div class="value">
              {{ milesCard.supplier.location_number }}
            </div>
          </a-col>
          <a-col :span="16">
            <div class="label">Complemento</div>
            <div class="value">
              {{ milesCard.supplier.location_complement }}
            </div>
          </a-col>
          <a-col :span="8">
            <div class="label">Bairro</div>
            <div class="value">
              {{ milesCard.supplier.location_neightborhood }}
            </div>
          </a-col>
          <a-col :span="8">
            <div class="label">Cidade</div>
            <div class="value">
              {{ milesCard.supplier.location_city }}
            </div>
          </a-col>
          <a-col :span="8">
            <div class="label">Estado</div>
            <div class="value">
              {{ milesCard.supplier.location_state }}
            </div>
          </a-col>
        </a-row>
      </a-tab-pane>

      <a-tab-pane
        v-if="milesCard.transactions && !viewOnly"
        key="extract"
        force-render
      >
        <template slot="tab">
          Extrato
          <a-tag class="ml-5" color="blue">
            {{
              milesCardTransactions.pagination.total != 0
                ? milesCardTransactions.pagination.total
                : milesCard.transactions.meta.total
            }}
          </a-tag>
        </template>

        <a-row
          class="travel-filters mt-0 mb-0 pt-0 pb-0 bt-0 bb-0"
          :gutter="20"
        >
          <a-col :span="4">
            <div class="travel-input">
              <label class="filled">ID Operação</label>
              <a-input
                placeholder=""
                v-model="milesCardTransactions.filters.operationId"
                @change="getMilesCardTransactions()"
              />
            </div>
          </a-col>
          <a-col :span="4">
            <div class="travel-input">
              <label class="filled">ID contrato</label>
              <a-input
                placeholder=""
                v-model="milesCardTransactions.filters.contractId"
                @change="getMilesCardTransactions()"
              />
            </div>
          </a-col>

          <a-col :span="8">
            <div class="travel-input">
              <label class="filled">Operação</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                mode="multiple"
                v-model="milesCardTransactions.filters.operation.selected"
                show-search
                style="width: 100%"
                @change="getMilesCardTransactions()"
              >
                <a-select-option
                  v-for="(item, index) of milesCardTransactions.filters
                    .operation.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>
          <a-col v-if="$root.isAdmin()" :span="8">
            <div class="travel-input">
              <label class="filled">Usuários</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                mode="multiple"
                v-model="milesCardTransactions.filters.users.selected"
                optionFilterProp="txt"
                show-search
                style="width: 100%"
                @change="getMilesCardTransactions()"
              >
                <a-select-option
                  v-for="(item, index) of milesCardTransactions.filters.users
                    .list"
                  :key="index"
                  :value="item.id"
                  :txt="item.first_name"
                >
                  {{ item.first_name }} {{ item.last_name }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>
        </a-row>

        <a-row class="mb-10" type="flex" justify="end">
          <a-col>
            <a-button
              size="small"
              @click="openAdjustModal = true"
              :disabled="false"
              style="font-size: 12px"
            >
              <i class="fas fa-sliders-h mr-5"></i>
              Ajuste</a-button
            ></a-col
          >

          <a-modal
            title="AJUSTE"
            :footer="false"
            width="360px"
            :visible="openAdjustModal"
            @cancel="openAdjustModal = false"
          >
            <div class="travel-input relative">
              <label class="filled">Motivo do ajuste</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                v-model="adjustMiles.reason.selected"
                optionFilterProp="txt"
                show-search
                style="width: 100%"
              >
                <a-select-option
                  v-for="(item, index) of adjustMiles.reason.list"
                  :key="index"
                  :value="item.name"
                  :txt="item.name"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>

            <!-- <div class="mt-10 relative" style="font-size: 12px">
                            <a-radio-group>
                                <a-radio class="mr-20" value="Pagar">
                                    Pagar
                                </a-radio>

                                <a-radio value="Receber"> Receber </a-radio>
                            </a-radio-group>
                        </div> -->

            <div class="mt-30 travel-input relative">
              <label class="filled">Quantidade de milhas</label>
              <a-input
                class="travel-input"
                placeholder="Milhas"
                v-currency="{
                  currency: {
                    prefix: '',
                    suffix: '',
                  },
                  distractionFree: false,
                  valueAsInteger: false,
                  precision: 0,
                  autoDecimalMode: true,
                  allowNegative: false,
                }"
                v-model="adjustMiles.miles"
              >
              </a-input>
            </div>

            <div class="mt-30 relative">
              <a-form-item class="travel-input-outer">
                <label class="filled">ID do Voyage</label>
                <a-input
                  class="travel-input"
                  :placeholder="'ID do Voyage (opcional)'"
                  v-model="adjustMiles.voyageId"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </div>

            <div class="mt-30 a-center">
              <a-button
                :loading="adjustMiles.loading"
                @click="submitMilesAdjust"
                type="primary"
              >
                CONFIRMAR AJUSTE
              </a-button>
            </div>
          </a-modal>
        </a-row>

        <div class="travel-table last-itens">
          <HayaTable
            :data-source="milesCardTransactions.list"
            :loading="milesCardTransactions.loading"
            :pagination="false"
            :paginator="milesCardTransactions.pagination"
            @getList="getMilesCardTransactions()"
            :columns="[
              {
                title: 'ID Operação',
                dataIndex: 'milescard_transaction_id',
                key: 'milescard_transaction_id',
                class: 'milescard_transaction_id',
              },
              {
                title: 'Qtd. Milhas',
                scopedSlots: {
                  customRender: 'total_miles',
                },
              },
              {
                title: 'Valor (R$)',
                dataIndex: 'value',
                key: 'value',
              },
              {
                title: 'Valor/1000',
                dataIndex: 'price_per_miles',
                key: 'price_per_miles',
              },
              {
                title: 'Preço Especial (R$)',
                dataIndex: 'special_price',
                key: 'special_price',
              },
              {
                title: 'Operação',
                scopedSlots: {
                  customRender: 'operation',
                },
              },
              {
                title: 'ID Contrato',

                scopedSlots: {
                  customRender: 'contract_id',
                },
              },
              {
                title: 'ID Pedido',
                scopedSlots: {
                  customRender: 'milescard_order_id',
                },
              },

              {
                title: 'Data/Hora',
                scopedSlots: {
                  customRender: 'created',
                },
              },
              {
                title: '',
                dataIndex: 'user',
                key: 'user',
                scopedSlots: {
                  customRender: 'user',
                },
              },
              {
                title: '',
                align: 'right',
                fixed: 'right',
                scopedSlots: { customRender: 'action' },
              },
            ]"
          >
            <div slot="total_miles" slot-scope="record">
              <div :class="record.is_canceled == '1' ? 'line-through' : ''">
                {{ formatMilesOperation(record) }}
              </div>
            </div>

            <div slot="user" slot-scope="record">
              <a-tooltip>
                <template slot="title">
                  {{ record.first_name }}
                  {{ record.last_name }}
                </template>
                <span id="avatar">
                  <a-avatar
                    v-if="record.avatar"
                    :src="record.avatar"
                    :size="30"
                  />
                  <a-avatar
                    v-else
                    :size="30"
                    style="color: #f56a00; background-color: #fde3cf"
                  >
                    {{ record.first_name }}
                  </a-avatar>
                </span>
              </a-tooltip>
            </div>

            <div slot="value" slot-scope="record">
              {{ record }}
            </div>

            <div slot="milescard_order_id" slot-scope="record">
              {{
                record.milescard_order_id != 0 ? record.milescard_order_id : ""
              }}
            </div>

            <div slot="operation" slot-scope="record">
              <span v-html="formatOperation(record)" />
              <a-tooltip v-if="record.extra_info">
                <template slot="title">
                  {{ record.extra_info }}
                </template>
                <a-icon
                  class="ml-5 relative c-pointer"
                  style="font-size: 12px; color: #aaa; top: 2px"
                  type="info-circle"
                />
              </a-tooltip>
            </div>

            <div slot="contract_id" slot-scope="record">
              <div v-if="record.contract_id > 0">
                <a
                  :href="`/contracts/edit/${record.contract_id}`"
                  target="_blank"
                >
                  {{ record.contract_id }}
                </a>
              </div>
            </div>

            <template slot="created" slot-scope="record">
              {{ record.created }}
            </template>

            <div class="table-actions" slot="action" slot-scope="record">
              <!-- {{record.is_canceled}} == {{record.operation }} -->

              <a-dropdown
                overlayClassName="actions-dropdown"
                v-if="
                  (record.operation == 'add' && record.is_vcm == '0') ||
                  (record.operation == 'new' && record.is_vcm == '0') ||
                  (record.operation == 'subtract' &&
                    record.is_vcm == '0' &&
                    record.is_canceled == '0')
                "
                :trigger="['click']"
                placement="bottomRight"
              >
                <a style="font-size: 12px; color: #aaa">
                  <i class="fas fa-ellipsis-h"></i>
                </a>

                <a-menu slot="overlay">
                  <a-menu-item v-if="record.operation == 'subtract'">
                    <a-popconfirm
                      title="Tem certeza que deseja reembolsar esta transação?"
                      ok-text="Sim"
                      cancel-text="Não"
                      @confirm="refundMilesCardTransaction(record)"
                    >
                      <i class="fas fa-exchange-alt mr-5"></i>
                      Reembolso
                    </a-popconfirm>
                  </a-menu-item>

                  <a-menu-item v-if="record.operation == 'subtract'">
                    <a-popconfirm
                      title="Tem certeza que deseja cancelar esta transação?"
                      ok-text="Sim"
                      cancel-text="Não"
                      @confirm="cancelMilesCardTransaction(record)"
                    >
                      <a-icon type="close-circle" />
                      Cancelar
                    </a-popconfirm>
                  </a-menu-item>

                  <a-menu-item
                    v-if="record.operation == 'new' && record.is_vcm == '0'"
                  >
                    <a-popconfirm
                      title="Tem certeza que deseja marcar como milhas VCM?"
                      ok-text="Sim"
                      cancel-text="Não"
                      placement="bottom"
                      @confirm="updateTransactionIsVcm(record)"
                    >
                      <img
                        class="mr-5"
                        src="../../../assets/images/v-cm.png"
                        alt="vcm"
                        height="10"
                      />
                      Milhas do VCM
                    </a-popconfirm>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </HayaTable>
        </div>
      </a-tab-pane>

      <a-tab-pane
        v-if="milesCard.transactions && !viewOnly"
        key="audit"
        force-render
      >
        <template slot="tab"> Auditoria </template>

        <a-row
          class="travel-filters mt-0 mb-0 pt-0 pb-0 bt-0 bb-0"
          :gutter="20"
        >
          <a-col :span="4">
            <div class="travel-input">
              <label class="filled">ID Operação</label>
              <a-input
                placeholder=""
                v-model="milesCardTransactions.filters.operationId"
                @change="getMilesCardTransactions()"
              />
            </div>
          </a-col>

          <a-col :span="8">
            <div class="travel-input">
              <label class="filled">Operação</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                mode="multiple"
                v-model="milesCardTransactions.filters.operation.selected"
                show-search
                style="width: 100%"
                @change="getMilesCardTransactions()"
              >
                <a-select-option
                  v-for="(item, index) of milesCardTransactions.filters
                    .operation.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>
          <a-col v-if="$root.isAdmin()" :span="8">
            <div class="travel-input">
              <label class="filled">Usuários</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                mode="multiple"
                v-model="milesCardTransactions.filters.auditUsers.selected"
                optionFilterProp="txt"
                show-search
                style="width: 100%"
                @change="getMilesCardTransactions()"
              >
                <a-select-option
                  v-for="(item, index) of milesCardTransactions.filters.users
                    .list"
                  :key="index"
                  :value="item.id"
                  :txt="item.first_name"
                >
                  {{ item.first_name }} {{ item.last_name }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>
        </a-row>

        <a-row class="mb-10" type="flex" justify="end">
          <a-modal
            title="AUDITORIA"
            :footer="false"
            width="360px"
            :visible="openAuditModal"
            @cancel="closeAuditModal"
          >
            <div class="relative">
              <div class="mt-10 travel-input relative">
                <label class="filled">Data da auditoria</label>

                <a-date-picker
                  class="travel-input"
                  placeholder="Data da auditoria"
                  format="DD/MM/YYYY HH:mm"
                  v-mask="'##/##/#### ##:##'"
                  :showTime="true"
                  showNow
                  showToday
                  v-model="auditModal.auditDate"
                >
                </a-date-picker>
              </div>

              <div class="mt-30 relative">
                <a-form-item class="travel-input-outer">
                  <label class="filled">ID do Voyage</label>
                  <a-input
                    class="travel-input"
                    :placeholder="'ID do Voyage '"
                    v-model="auditModal.voyageId"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-input>
                </a-form-item>
              </div>

              <a-form-item class="travel-input-outer">
                <label
                  class="filled"
                  style="
                    top: 0px;
                    position: relative !important;
                    display: block;
                  "
                  >Comentário da auditoria</label
                >
                <a-textarea
                  class="travel-textarea"
                  :placeholder="'Escreva aqui...'"
                  type="textarea"
                  v-model="auditModal.auditComment"
                  :rows="4"
                  :maxlength="255"
                />
              </a-form-item>
            </div>

            <div class="mt-30 a-center">
              <a-button
                :loading="auditModal.loading"
                @click="submitAudit"
                type="primary"
              >
                {{
                  auditModal.type == "edit"
                    ? "ATUALIZAR AUDITORIA"
                    : "CONFIRMAR AUDITORIA"
                }}
              </a-button>
            </div>
            <!-- <pre>
                        {{ auditModal }}
                        </pre> -->
          </a-modal>
        </a-row>

        <div class="travel-table last-itens">
          <HayaTable
            :data-source="milesCardTransactions.list"
            :loading="milesCardTransactions.loading"
            :pagination="false"
            :paginator="milesCardTransactions.pagination"
            @getList="getMilesCardTransactions()"
            :columns="[
              {
                title: 'ID Operação',
                dataIndex: 'milescard_transaction_id',
                key: 'milescard_transaction_id',
                class: 'milescard_transaction_id',
              },
              {
                title: 'Qtd. Milhas',
                scopedSlots: {
                  customRender: 'total_miles',
                },
              },

              {
                title: 'Operação',
                scopedSlots: {
                  customRender: 'operation',
                },
              },

              {
                title: 'Voyage ID',
                scopedSlots: {
                  customRender: 'voyage_id',
                },
              },
              {
                title: 'Data/Hora',
                scopedSlots: {
                  customRender: 'created',
                },
              },
              {
                title: 'Info da auditoria',
                scopedSlots: {
                  customRender: 'audit_comment',
                },
              },
              {
                title: 'Data Auditoria',
                scopedSlots: {
                  customRender: 'audit_date',
                },
              },
              {
                title: '',
                dataIndex: 'audit_user',
                key: 'audit_user',
                scopedSlots: {
                  customRender: 'audit_user',
                },
              },
              {
                title: '',
                align: 'right',
                fixed: 'right',
                scopedSlots: { customRender: 'action' },
              },
            ]"
          >
            <div slot="total_miles" slot-scope="record">
              <div :class="record.is_canceled == '1' ? 'line-through' : ''">
                {{ formatMilesOperation(record) }}
              </div>
            </div>

            <div slot="audit_user" slot-scope="record">
              <a-tooltip v-if="record.id">
                <template slot="title">
                  {{ record.first_name }}
                  {{ record.last_name }}
                </template>
                <span id="avatar">
                  <a-avatar
                    v-if="record.avatar"
                    :src="record.avatar"
                    :size="30"
                  />
                  <a-avatar
                    v-else
                    :size="30"
                    style="color: #f56a00; background-color: #fde3cf"
                  >
                    {{ record.first_name }}
                  </a-avatar>
                </span>
              </a-tooltip>
              <div v-else>
                <a-avatar
                  :size="30"
                  style="color: #999; background-color: #eee"
                >
                  <a-icon style="font-size: 12px" type="user" />
                </a-avatar>
              </div>
            </div>

            <div slot="voyage_id" slot-scope="record">
              {{ record.voyage_id != 0 ? record.voyage_id : "" }}
            </div>

            <div slot="audit_date" slot-scope="record">
              {{
                record.audit_date != "30/11/-0001 00:00"
                  ? record.audit_date
                  : ""
              }}
            </div>

            <div slot="audit_comment" slot-scope="record">
              {{ record.audit_comment }}
            </div>

            <div slot="operation" slot-scope="record">
              <span v-html="formatOperation(record)" />
              <a-tooltip v-if="record.extra_info">
                <template slot="title">
                  {{ record.extra_info }}
                </template>
                <a-icon
                  class="ml-5 relative c-pointer"
                  style="font-size: 12px; color: #aaa; top: 2px"
                  type="info-circle"
                />
              </a-tooltip>
            </div>

            <template slot="created" slot-scope="record">
              {{ record.created }}
            </template>

            <div class="table-actions" slot="action" slot-scope="record">
              <a-button
                v-if="record.audit_date == '30/11/-0001 00:00'"
                type="link"
                style="padding: 0"
                @click="theAuditModal(record, 'create')"
              >
                <a-icon class="cgreen" type="check-circle" />
              </a-button>

              <a-button
                v-if="record.audit_date != '30/11/-0001 00:00'"
                type="link"
                style="padding: 0"
                @click="theAuditModal(record, 'edit')"
              >
                <a-icon class="cgreen ml-5" type="edit" />
              </a-button>
            </div>
          </HayaTable>
        </div>
      </a-tab-pane>

      <a-tab-pane v-if="!viewOnly" key="notes" force-render>
        <template slot="tab">
          Notas
          <a-tag class="ml-5" color="blue" :id="`count-notes-${milesCard.id}`">
            0
          </a-tag>
        </template>
        <Notes module="miles-card" :moduleId="milesCard.id" />
      </a-tab-pane>

      <a-tab-pane v-if="!viewOnly" key="logs" force-render>
        <template slot="tab">
          Logs
          <a-tag class="ml-5" color="blue">
            {{ milesCard.logs.all_logs.meta.total }}
          </a-tag>
        </template>

        <a-tabs default-active-key="updates" @change="onChangeLogsTable">
          <a-tab-pane key="updates">
            <template slot="tab">
              Alterações
              <a-tag class="ml-5" color="blue">
                {{ milesCard.logs.updates.meta.total }}
              </a-tag>
            </template>
            <logs-timeline
              class="mt-10"
              :logs="milesCard.logs.updates"
              color="blue"
            />
          </a-tab-pane>
          <a-tab-pane key="blocks" force-render>
            <template slot="tab">
              Bloqueios
              <a-tag class="ml-5" color="blue">
                {{ milesCard.logs.blocks.meta.total }}
              </a-tag>
            </template>
            <logs-timeline
              class="mt-10"
              :logs="milesCard.logs.blocks"
              color="red"
            />
          </a-tab-pane>
          <a-tab-pane key="special-price">
            <template slot="tab">
              Preço Especial
              <a-tag class="ml-5" color="blue">
                {{ milesCard.logs.special_price.meta.total }}
              </a-tag>
            </template>
            <logs-timeline
              class="mt-10"
              :logs="milesCard.logs.special_price"
              color="green"
            />
          </a-tab-pane>
        </a-tabs>
      </a-tab-pane>
    </a-tabs>

    <!-- {{ milesCard }} -->
  </div>
</template>

<script>
import { format, parse } from "date-fns";

import milesCardsTransactionsMixins from "@/mixins/miles-cards-transactions/milesCardsTransactionsMixins";

import LogsTimeline from "../../../components/logs/LogsTimeline.vue";
import HayaTable from "../../general/HayaTable.vue";
import Notes from "../../general/Notes.vue";

export default {
  components: { LogsTimeline, HayaTable, Notes },
  mixins: [milesCardsTransactionsMixins],
  props: {
    milesCard: Object,
    editMilesCard: Boolean,
    editCard: Object,
    activeMilesCardTab: String,
    viewOnly: Boolean,
  },
  data() {
    return {
      // activeTab: "card",
      blockCard: false,
      openAuditModal: false,
      auditModal: {
        voyageId: "",
        auditDate: "",
        type: "",
        auditUser: "",
        auditComment: "",
        transaction: {},
        loading: false,
      },
      reasonForBlocking: undefined,
      reasonsForBlockingLoading: false,
      openAdjustModal: false,
      adjustMiles: {
        loading: false,
        reason: {
          list: [
            // {
            //     label: "Emitir milhas",
            //     value: "Emitir milhas",
            // },
            // {
            //     label: "Cliente desistiu",
            //     value: "Cliente desistiu",
            // },
          ],
          selected: undefined,
        },
        miles: undefined,
        voyageId: undefined,
      },
      transactionsPagination: {
        page: 1,
        perPage: 10,
        total: 0,
        totalPages: 0,
      },
      reasonsForBlocking: [
        "Saldo Insuficiente",
        "Telefone de cadastro interno inválido",
        "Cartão de crédito do cliente recusado",
        "Site da companhia aérea fora do ar",
        "Cliente desistiu da compra do bilhete aéreo",
        "Suspeita de Fraude",
        "Fornecedor desistiu da venda das milhas",
        "Bloqueio de Reserva",
        "Duplicidade",
        "Senha Inválida",
        "Cartão de Crédito não cadastrado",
        "Auditoria - Pagamento P.E. utilizado",
        "Lucratividade inferior ao aceitável",
        "Fim do Programa de Milhas",
        "Fraude Confirmada",
      ],
    };
  },
  mounted() {
    this.$http
      .get(
        `/category-entry/list?page=1&category_id=23&status=Ativo&per_page=300`
      )
      .then(({ data }) => {
        this.adjustMiles.reason.list = data.data;
      });
  },
  created() {
    this.$http
      .get("/user/read")
      .then(({ data }) => {
        this.milesCardTransactions.filters.users.list = data;
      })
      .catch(({ response }) => {
        response;
      });

    this.milesCardTransactions.filters.milesCardId = this.milesCard.id;
  },
  methods: {
    closeAuditModal() {
      this.openAuditModal = false;
      this.auditModal = {
        voyageId: "",
        type: "",
        auditDate: "",
        auditUser: "",
        auditComment: "",
        transaction: {},
        loading: false,
      };
    },
    theAuditModal(transaction, type) {
      this.auditModal.transaction = transaction.raw;
      this.auditModal.type = type;

      if (type == "edit") {
        this.auditModal.voyageId = transaction.raw.voyage_id;
        this.auditModal.auditDate = transaction.raw.audit_date;
        this.auditModal.auditUser = transaction.raw.audit_user;
        this.auditModal.auditComment = transaction.raw.audit_comment;
      }

      this.openAuditModal = true;
    },
    submitAudit() {
      //if (this.auditModal.auditComment && this.auditModal.auditDate) {
      this.auditModal.transaction.audit_date = this.auditModal.auditDate;
      this.auditModal.transaction.audit_comment = this.auditModal.auditComment;
      this.auditModal.transaction.audit_user_id = this.$store.state.userData.id;
      this.auditModal.transaction.voyage_id = this.auditModal.voyageId;

      this.auditModal.transaction.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      if (this.auditModal.auditComment) {
        this.auditModal.transaction.action = "audit-transaction";
      }

      this.auditModal.loading = true;
      this.$http
        .post("/miles-card-transactions/update", this.auditModal.transaction)
        .then(({ data }) => {
          this.getMilesCard();
          this.getMilesCardTransactions();
          this.$message.success(data.message);
          this.openAuditModal = false;

          this.auditModal = {
            voyageId: "",
            auditDate: "",
            type: "",
            auditUser: "",
            auditComment: "",
            transaction: {},
            loading: false,
          };
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.auditModal.loading = false;

          // this.auditModal = {
          //     voyageId: "",
          //     auditDate: "",
          //     auditUser: "",
          //     auditComment: "",
          //     transaction: {},
          //     loading: false,
          // };
        });
      // } else {
      //     this.$message.warning(
      //         "Insira o comentário e a data da auditoria."
      //     );
      // }
    },
    submitMilesAdjust() {
      if (this.adjustMiles.miles && this.adjustMiles.reason.selected) {
        let transaction = {};
        // transaction.aaaa = this.milesCard;
        transaction.is_canceled = 0;
        transaction.is_manual_emission = true;
        transaction.milescard_id = this.milesCard.raw.id;
        transaction.supplier_id = this.milesCard.raw.supplier_id;
        transaction.user_id = this.$store.state.userData.id;
        transaction.contract_id = 0;
        transaction.voyage_id = this.adjustMiles.voyageId;
        transaction.total_miles = this.adjustMiles.miles
          .replace(".", "")
          .replace(".", "")
          .replace(".", "")
          .replace(".", "");
        transaction.suggested_value = 0;
        transaction.value = 0;
        transaction.price_per_miles =
          parseFloat(this.milesCard.raw.price_per_miles) / 1000;
        transaction.special_price = this.milesCard.raw.special_price;
        transaction.is_vcm = 0;

        transaction.modified_by = {
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
          id: this.$store.state.userData.id,
        };

        if (this.adjustMiles.reason.selected == "Emitir milhas") {
          transaction.operation = "subtract";
          transaction.extra_info = `Emissão manual de milhas ${
            this.adjustMiles.voyageId
              ? "(ID Voyage:" + this.adjustMiles.voyageId + ")"
              : ""
          }`;
          transaction.action = "manual-emition";
        } else if (this.adjustMiles.reason.selected == "Cliente desistiu") {
          transaction.operation = "subtract";
          transaction.extra_info = `${this.adjustMiles.reason.selected} ${
            this.adjustMiles.voyageId
              ? "(ID Voyage:" + this.adjustMiles.voyageId + ")"
              : ""
          }`;
          transaction.action = "manual-emition";
        } else {
          transaction.operation = "subtract";
          transaction.extra_info = `${this.adjustMiles.reason.selected} ${
            this.adjustMiles.voyageId
              ? "(ID Voyage:" + this.adjustMiles.voyageId + ")"
              : ""
          }`;
        }

        let availableMiles = this.milesCard.available_miles
          .replace(".", "")
          .replace(".", "")
          .replace(".", "")
          .replace(".", "");

        if (
          parseInt(transaction.total_miles) <= parseInt(availableMiles) &&
          parseInt(availableMiles) > 1
        ) {
          this.adjustMiles.loading = true;
          this.$http
            .post("/miles-card-transactions/create", transaction)
            .then(({ data }) => {
              this.getMilesCard();
              this.getMilesCardTransactions();
              this.$message.success(data.message);
              this.adjustMiles.loading = false;
              this.openAdjustModal = false;

              this.adjustMiles.reason.selected = undefined;
              this.adjustMiles.miles = undefined;
              this.adjustMiles.voyageId = undefined;
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.adjustMiles.loading = false;
            });
        } else {
          this.$message.warning(
            "A quantidade a ser retirada não pode ser maior que o disponível."
          );
        }
      } else {
        //
        this.$message.warning("Insira o valor a ser retirado e o motivo.");
      }
    },
    onChangeLogsTable(tab) {
      tab;
    },
    activatelMilesCard() {
      this.$http
        .post("/miles-card/update-field", {
          id: this.milesCard.id,
          field: "status",
          value: "available",
          action: "activate-milescard",
          description: `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> ativou o cartão <font class="id">ID ${this.milesCard.id} ${this.milesCard.miles_program}</font>.`,
          modified_by: {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          },
        })
        .then(({ data }) => {
          this.$message.success(data.message);
          this.getMilesCard();
          this.$emit("updateMilesCardsList", true);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    cancelMilesCard() {
      this.$http
        .post("/miles-card/update-field", {
          id: this.milesCard.id,
          field: "status",
          value: "canceled",
          action: "cancel-milescard",
          description: `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> cancelou o cartão <font class="id">ID ${this.milesCard.id} ${this.milesCard.miles_program}</font>.`,
          modified_by: {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          },
        })
        .then(({ data }) => {
          this.$message.success(data.message);
          this.getMilesCard();
          this.$emit("updateMilesCardsList", true);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    cancelMilesCardTransaction(transaction) {
      transaction.raw.is_canceled = 0;
      transaction.raw.operation = "transaction-canceled";
      transaction.raw.extra_info = "Retorno de milhas por cancelamento.";
      transaction.raw.action = "cancel-milescard-transaction";
      transaction.raw.user_id = this.$store.state.userData.id;
      transaction.raw.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.$http
        .post("/miles-card-transactions/create", transaction.raw)
        .then(({ data }) => {
          this.getMilesCard();
          this.getMilesCardTransactions();
          this.$message.success(data.message);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    refundMilesCardTransaction(transaction) {
      transaction.raw.is_canceled = 0;
      transaction.raw.operation = "refund";
      transaction.raw.action = "refund-milescard-transaction";
      transaction.raw.user_id = this.$store.state.userData.id;
      transaction.raw.audit_date = "0000-00-00 00:00:00";
      transaction.raw.audit_comment = "";
      transaction.raw.audit_user_id = 0;
      transaction.raw.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.$http
        .post("/miles-card-transactions/create", transaction.raw)
        .then(({ data }) => {
          this.getMilesCard();
          this.getMilesCardTransactions();
          this.$message.success(data.message);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    getMilesCard() {
      this.$http
        .post("/miles-card/details", {
          id: this.milesCard.id,
        })
        .then(({ data }) => {
          setTimeout(() => {
            this.milesCard.status = data.status;
            this.milesCard.available_miles = data.available_miles;
            this.milesCard.spent_miles = data.spent_miles;
            this.milesCard.logs = data.logs;
            this.milesCard.transactions = data.transactions;
          }, 30);
        });
    },
    minDate(current) {
      return current && current < new Date();
    },
    updateMilesCardsList() {
      this.$emit("updateMilesCardsList", true);
    },
    onChangeTabs(tab) {
      this.activeMilesCardTab = tab;
      if (tab == "extract" || tab == "audit") {
        this.getMilesCardTransactions();
      }
    },
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd", new Date());
      return format(theDate, "dd/MM/yyyy");
    },
    formatDateTime(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(theDate, "dd/MM/yyyy HH:mm");
    },
    formatDateTimeAntd(date) {
      let theDate = parse(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", new Date());
      return format(theDate, "dd/MM/yyyy");
    },
    formatMultiDates(date) {
      if (date != undefined) {
        if (typeof date == "object") {
          return format(date._d, "dd/MM/yyyy");
        }

        if (date.length == 8) {
          let theDate = parse(date, "yyyyMMdd", new Date());
          return format(theDate, "dd/MM/yyyy");
        }

        if (date.length == 10) {
          return date;
        }

        if (date.length > 22) {
          let theDate = parse(date.split("T")[0], "yyyy-MM-dd", new Date());
          return format(theDate, "dd/MM/yyyy");
        }
      }
    },
    formatPaymentStatus(status) {
      if (status == "paid") {
        status = `<font class="tag add">PAGO</font>`;
      }

      if (status == "refund") {
        status = `<font class="tag new">REEMBOLSADO</font>`;
      }

      if (status == "pending") {
        status = `<font class="tag subtract">PENDENTE</font>`;
      }

      return status;
    },
    refundPayments(payments) {
      let refunds = [];
      payments.forEach((payment) => {
        if (payment.status == "refund") {
          refunds.push(payment);
        }
      });

      return refunds;
    },
    view(id) {
      window.open(`view/${id}`, "_blank");
    },
    updateTransactionIsVcm(transaction) {
      transaction.raw.is_vcm = 1;

      this.$http
        .post("/miles-card-transactions/update", transaction.raw)
        .then(({ data }) => {
          this.getMilesCard();
          this.getMilesCardTransactions();
          this.$message.success(data.message);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    activateCard() {
      this.$http
        .post("/miles-card/update-field", {
          id: this.milesCard.id,
          field: "status",
          value: "available",
          action: "activate-milescard",
          description: `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> desbloqueou o cartão <font class="id">ID ${this.milesCard.id} ${this.milesCard.miles_program}</font>.`,
          modified_by: {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          },
        })
        .then(({ data }) => {
          this.$message.success(data.message);
          this.getMilesCard();
          this.$emit("updateMilesCardsList", true);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    updateMilesCard(milesCard) {
      if (this.editCard.password && this.editCard.expires_at) {
        this.editCard.loading = true;
        this.$http
          .post("/miles-card/update", {
            id: milesCard.id,
            supplier_id: milesCard.supplier.supplier_id,
            airline_id: milesCard.airline.id,
            user_id: milesCard.user_id,
            miles_program: milesCard.miles_program,
            number: this.editCard.number,
            user: this.editCard.user,
            password: this.editCard.password,
            password_recovery: this.editCard.password_recovery,
            special_price: milesCard.special_price,
            status: milesCard.status,
            expires_at: this.editCard.expires_at,
            action: "update-passwords",
            description: `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> alterou senhas e data de expiração do  cartão <font class="id">ID ${this.milesCard.id} ${this.milesCard.miles_program}</font>.`,
            modified_by: {
              name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
              id: this.$store.state.userData.id,
            },
          })
          .then(({ data }) => {
            this.getMilesCard();
            this.editMilesCard = false;

            this.$message.success(data.message);
            this.editCard.loading = false;
            this.milesCard.user = this.editCard.user;
            this.milesCard.number = this.editCard.number;
            this.milesCard.password = this.editCard.password;
            this.milesCard.password_recovery = this.editCard.password_recovery;
            this.milesCard.expires_at = format(
              this.editCard.expires_at._d,
              "yyyy-MM-dd"
            );
          })
          .catch(({ response }) => {
            this.$message.error(response.data.message);
            this.editCard.loading = false;
          });
      } else {
        this.$message.error("Preencha a senha e data de expiração.");
      }
    },
    blockMilesCard(milesCard) {
      if (this.reasonForBlocking) {
        this.reasonsForBlockingLoading = true;
        this.$http
          .post("/miles-card/update", {
            id: milesCard.id,
            supplier_id: milesCard.raw.supplier_id,
            airline_id: milesCard.airline.id,
            user_id: milesCard.user_id,
            miles_program: milesCard.miles_program,
            number: milesCard.number,
            user: milesCard.user,
            password: milesCard.password,
            password_recovery: milesCard.password_recovery,
            special_price: milesCard.special_price,
            status: "blocked",
            description: `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> BLOQUEOU o cartão <font class="id">ID ${this.milesCard.id} ${this.milesCard.miles_program}</font> pelo seguinte motivo: <font class="red">${this.reasonForBlocking}</font>.`,
            expires_at: milesCard.expires_at,
            action: "block-milescard",
            modified_by: {
              name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
              id: this.$store.state.userData.id,
            },
          })
          .then(({ data }) => {
            this.$message.success(data.message);
            this.getMilesCard();
            this.$emit("updateMilesCardsList", true);
            this.blockCard = false;
            this.reasonsForBlockingLoading = false;
          })
          .catch(({ response }) => {
            this.$message.error(response.data.message);
            this.reasonsForBlockingLoading = false;
          });
      } else {
        this.$message.error("Selecione o motivo da recusa.");
      }
    },
    formatOperation(transaction) {
      let operation = "";
      let vcm = "";

      if (transaction.operation == "new") {
        if (transaction.is_vcm == "1") {
          vcm = `<font class="corange" style="font-size: 24px; font-size: 16px;"> <a-tooltip> <template slot="title"> Milhas do VCM </template> • </a-tooltip> </font>`;
        }

        operation = `<font class="tag new">NOVO ${vcm}</font>`;
      }

      if (transaction.operation == "add") {
        operation = `<font class="tag add">ADIÇÃO</font>`;
      }

      if (transaction.operation == "subtract") {
        operation = `<font class="tag return">EMISSÃO</font>`;
      }

      if (transaction.operation == "transaction-canceled") {
        operation = `<font class="tag subtract">CANCELADO</font>`;
      }

      if (transaction.operation == "canceled") {
        operation = `<font class="tag subtract">CANCELADO</font>`;
      }

      if (transaction.operation == "refund") {
        operation = `<font class="tag cprimary">REEMBOLSO</font>`;
      }

      if (transaction.operation == "adjust") {
        operation = `<font class="tag adjust">AJUSTE</font>`;
      }

      if (transaction.operation == "purchase-canceled") {
        operation = `<font class="tag cancel-purchase">CANCELAMENTO DE COMPRA</font>`;
      }

      return operation;
    },
    formatMilesOperation(transaction) {
      let miles = "";
      if (transaction.operation == "new") {
        miles = `+${transaction.total_miles}`;
      }

      if (transaction.operation == "add") {
        miles = `+${transaction.total_miles}`;
      }

      if (transaction.operation == "refund") {
        miles = `+${transaction.total_miles}`;
      }

      if (transaction.operation == "return-miles") {
        miles = `+${transaction.total_miles}`;
      }

      if (transaction.operation == "transaction-canceled") {
        miles = `+${transaction.total_miles}`;
      }

      if (transaction.operation == "canceled") {
        miles = `+${transaction.total_miles}`;
      }

      if (transaction.operation == "subtract") {
        miles = `-${transaction.total_miles}`;
      }
      return miles;
    },
  },
};
</script>
