<template>
  <div>
    <MultiFilesTable
      class="files"
      listType="multi"
      s3Folder="contracts"
      :moduleName="`contract`"
      :customerId="tempContract.customer_id"
      :moduleId="`${tempContract.id}`"
      :multiIds="[
        {
          module: 'opportunity',
          id: tempContract.opportunity_id,
        },
        {
          module: 'contract',
          id: tempContract.id,
        },
        {
          module: 'sale',
          id: tempContract.sale_id,
        },
      ]"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import MultiFilesTable from "@/components/general/MultiFilesTable.vue";

export default {
  name: "ContractDocumentsTab",
  props: {
    tempContract: Object,
    sale: Object,
  },
  components: { MultiFilesTable },
  setup() {
    const activeCollapse = ref(""),
      activeAccordeon = (current) => {
        if (current == activeCollapse.value) {
          activeCollapse.value = -1;
        } else {
          activeCollapse.value = current;
        }
      };

    return { activeCollapse, activeAccordeon };
  },
  methods: {
    reloadSale() {
      this.$emit("reloadSale");
    },
  },
};
</script>

<style lang="sass" scoped>
.collapse-row
  padding: 0 10px 0 0
  .content
    padding: 6px 3px 20px 20px
    .edit
      background: #f27935
      border-color: #f27935
      padding: 5px 10px
    .update
      background: #2ecc71
      border-color: #2ecc71
      padding: 5px 10px
  .head
    text-transform: capitalize
    background: #929496
    padding: 5px 8px
    border-radius: 5px
    margin-bottom: 5px
    color: #FFF
    text-transform: uppercase
    cursor: pointer
    &.done
      background: #2ecc71
</style>
