var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.submit}},[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v("Nome")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            `name`,
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]),expression:"[\n                            `name`,\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Ex: Promo 01"}})],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v("Regra dos pontos")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            `rule`,
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]),expression:"[\n                            `rule`,\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Ex: 1","type":"number"}})],1)],1),_vm._v(" "),(
                    _vm.form.getFieldValue(`rule`) != 0 &&
                    _vm.form.getFieldValue(`rule`) != undefined
                )?_c('a-col',{attrs:{"span":24}},[_c('a-alert',{staticClass:"f12 mb-30",attrs:{"type":"info","banner":""}},[_c('template',{slot:"message"},[_vm._v("\n                        R$ 1 = "+_vm._s(_vm.form.getFieldValue(`rule`))+" pontos\n                        Livelo.")])],2)],1):_vm._e(),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v("Custo por ponto")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency",value:({
                            precision: 3,
                        }),expression:"{\n                            precision: 3,\n                        }"},{name:"decorator",rawName:"v-decorator",value:([
                            `value`,
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]),expression:"[\n                            `value`,\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira um valor"}})],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v("Status")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            `status`,

                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]),expression:"[\n                            `status`,\n\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"travel-input select",attrs:{"placeholder":"Selecione"},on:{"change":_vm.onChangeLiveloPointsRule}},_vm._l((_vm.status),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v("\n                            "+_vm._s(item.label)+"\n                        ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v("Data inicial")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                            'start_date',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]),expression:"[\n                            'start_date',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY","showToday":false,"allowClear":false}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v("Data final")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                            'end_date',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]),expression:"[\n                            'end_date',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY","showToday":false,"allowClear":false}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v("Product Code")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            `product_code`,
                            {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]),expression:"[\n                            `product_code`,\n                            {\n                                rules: [\n                                    {\n                                        required: false,\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Ex: PROMO10"}})],1)],1),_vm._v(" "),_c('a-col',{staticClass:"a-center",attrs:{"span":24}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v("\n                    Salvar alterações\n                ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }