import _ from "lodash";
export default {
  data() {
    return {
      customers: {
        loading: false,
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          totalPages: 0,
        },
        details: {},
        list: [],
        filters: {
          loadingResults: false,
          users: {
            list: [],
            selected: [],
          },
          airlines: {
            list: [],
            selected: [],
          },
          milesPrograms: {
            list: [],
            selected: [],
          },
          status: {
            list: [
              {
                label: "Ativo",
                value: "Ativo",
              },
              {
                label: "Desativado",
                value: "Desativado",
              },
            ],
            selected: ["Ativo"],
          },
          period: {
            selected: [],
          },
          states: {
            selected: [],
          },
          supplier: "",
          id: "",
          searchTerm: "",
          customerName: "",
          available_miles: "",
          price: {
            min: "",
            max: "",
          },
          order: "desc",
          orderBy: "created",
        },
      },
      createCustomer: false,
      customersTableCollumns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 80,
          sorter: true,
        },
        {
          title: "Nome Cliente/Empresa",
          key: "name",
          width: 250,
          scopedSlots: {
            customRender: "name",
          },
        },
        {
          title: "E-mail",
          dataIndex: "email",
          key: "email",
          scopedSlots: {
            customRender: "email",
          },
          width: 280,
        },

        {
          title: "Celular",
          dataIndex: "mobile_phone",
          key: "mobile_phone",
          scopedSlots: {
            customRender: "mobile_phone",
          },
          width: 150,
        },
        {
          title: "Telefone Fixo",
          dataIndex: "phone",
          key: "phone",
          scopedSlots: {
            customRender: "phone",
          },
          width: 150,
        },
        {
          title: "Estado",
          dataIndex: "location_state",
          key: "location_state",
          width: 80,
          scopedSlots: {
            customRender: "location_state",
          },
        },
        // {
        //     title: 'Tipo de pessoa',
        //     dataIndex: 'person_type',
        //     key: 'person_type',
        //     scopedSlots: {
        //         customRender: 'person_type'
        //     },
        // },

        {
          title: "Criado em",
          dataIndex: "created",
          key: "created",
          sorter: true,
          width: 140,
        },

        {
          title: "",
          key: "user",
          scopedSlots: {
            customRender: "user",
          },
        },
        {
          title: "",
          key: "action",
          align: "right",
          fixed: "right",

          scopedSlots: {
            customRender: "action",
          },
        },
      ],
    };
  },
  methods: {
    searchCustomer: _.debounce(function(e) {
      const serachText = e?.target?.value ? e.target.value : e;
      this.searchCustomers(1, serachText);
    }, 400),
    searchCustomers(page, searchText) {
      this.loading = true;
      const params = {
        page,
        s: searchText,
        per_page: 10,
      };
      this.$http
        .get("/customer/list", {
          params,
        })
        .then(({ data }) => {
          //this.pagination.total = total;
          this.customers.list = data.data;
        })
        .catch((error) => {
          console.log(error);
          this.customers.list = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onCustomerSelected(id) {
      this.customers.details = null;
      this.createCustomer = false;
      this.getCustomer(id);
      this.tempContract.customer_id = id;
    },
    getCustomer(id) {
      // load
      this.$http
        .post("/customer/details", {
          id,
        })
        .then(({ data }) => {
          this.customers.details = data;

          this.customerForm.setFieldsValue(data);
          if (this.$route.path.split("/")[1] == "customers") {
            if (data.person_type == "Pessoa Física") {
              document.title = `${data.id} - ${data.first_name}  ${data.last_name}`;
            } else {
              document.title = `${data.id} - ${data.trading_name} `;
            }
          }

          this.tempContract.the_customer = data;
        })
        .catch(({ response }) => {
          response;
          // this.$message.error(message);
        })
        .finally(() => {
          //this.processing = false;
        });
    },
    getCustomers(samePage) {
      this.customers.loading = true;

      let filters = "";
      if (!this.$root.isAdmin()) {
        filters += `&user_id=${this.$store.state.userData.id}`;
      } else {
        if (this.customers.filters.users.selected.length > 0) {
          filters += `&user_id=${this.customers.filters.users.selected}`;
        }
      }

      if (this.customers.filters.states.selected) {
        filters += `&location_state=${this.customers.filters.states.selected}`;
      }

      if (this.customers.filters.status.selected) {
        filters += `&status=${this.customers.filters.status.selected}`;
      }

      if (this.customers.filters.searchTerm) {
        filters += `&s=${this.customers.filters.searchTerm}`;
      }

      filters += `&order=${this.customers.filters.order}&order-by=${this.customers.filters.orderBy}`;

      this.$http
        .get(
          `/customer/list?page=${this.customers.pagination.page}&per_page=${this.customers.pagination.perPage}${filters}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.customers.pagination.page = 1;
          }
          this.customers.pagination.total = data.meta.total;
          this.customers.pagination.total_general = data.meta.total_general;
          this.customers.pagination.totalPages = data.meta.total_pages;

          this.customers.list = data.data;

          this.excelFile.data = data.data;
          this.customers.loading = false;
        })
        .catch(({ response }) => {
          this.customers.pagination.total = response.data.meta.total;
          this.customers.pagination.totalPages = response.data.meta.total_pages;
          this.customers.pagination.total_general =
            response.data.meta.total_general;

          this.customers.list = [];
          this.customers.loading = false;
        });
    },
  },
};
