var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('MultiFilesTable',{staticClass:"files",attrs:{"listType":"multi","s3Folder":"contracts","moduleName":`contract`,"customerId":_vm.tempContract.customer_id,"moduleId":`${_vm.tempContract.id}`,"multiIds":[
      {
        module: 'opportunity',
        id: _vm.tempContract.opportunity_id,
      },
      {
        module: 'contract',
        id: _vm.tempContract.id,
      },
      {
        module: 'sale',
        id: _vm.tempContract.sale_id,
      },
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }