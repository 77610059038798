export default {
  data() {
    return {
      milesCardTransactions: {
        loading: false,
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          totalPages: 0,
        },
        details: {},
        list: [],
        filters: {
          auditUsers: {
            selected: [],
          },
          users: {
            list: [],
            selected: [],
          },
          operation: {
            list: [{
                label: "Adição",
                value: "add",
              },
              {
                label: "Cancelado",
                value: "canceled,transaction-canceled",
              },
              {
                label: "Emissão",
                value: "subtract",
              },
              {
                label: "Novo",
                value: "new",
              },
              {
                label: "Reembolso",
                value: "refund",
              }
            ],
            selected: [],
          },
          airlines: {
            list: [],
            selected: [],
          },
          milesPrograms: {
            list: [],
            selected: [],
          },
          period: {
            selected: [],
          },
          price: {
            min: "",
            max: "",
          },
          supplier: "",
          isCanceled: "",
          isManual: false,
          milesCardId: "",
          operationId: "",
          contractId: "",
          searchTerm: "",
          order: "desc",
          orderBy: "created",
        }
      }
    };
  },
  methods: {
    changePage(current) {
      this.milesCardTransactions.pagination.page = current;
      this.getMilesCardTransactions();
    },
    changePageSize(current, pageSize) {
      this.milesCardTransactions.pagination.page = current;
      this.milesCardTransactions.pagination.perPage = pageSize;
      this.getMilesCardTransactions();
    },
    milesCardTransactionsTableChange(pagination, filters, sorter) {
      //console.log("sorter s", sorter, pagination, filters);
      this.milesCardTransactions.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.milesCardTransactions.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getMilesCardTransactions();
    },
    getMilesCardTransactions(samePage) {

      let filters = "";

      this.milesCardTransactions.loading = true;

      if (this.milesCardTransactions.filters.searchTerm) {
        filters += `&s=${this.milesCardTransactions.filters.searchTerm}`;
      }

      if (this.milesCardTransactions.filters.contractId) {
        filters += `&contract_id=${this.milesCardTransactions.filters.contractId}`;
      }

      if (this.milesCardTransactions.filters.milesCardId) {
        filters += `&milescard_id=${      this.milesCardTransactions.filters.milesCardId}`;
      }


      if (this.milesCardTransactions.filters.supplier) {
        filters += `&supplier_id=${this.milesCardTransactions.filters.supplier}`;
      }

      if (this.milesCardTransactions.filters.isCanceled) {
        filters += `&is_canceled=${this.milesCardTransactions.filters.isCanceled}`;
      }

      if (this.milesCardTransactions.filters.isManual) {
        filters += `&is_manual_emission=${this.milesCardTransactions.filters.isManual}`;
      }

      if (this.milesCardTransactions.filters.period.selected.length > 0) {
        filters += `&period=${this.milesCardTransactions.filters.period.selected[0]}|${this.milesCardTransactions.filters.period.selected[1]}`;
      }

      if (this.milesCardTransactions.filters.operationId) {
        filters += `&milescard_transaction_id=${this.milesCardTransactions.filters.operationId}`;
      }

      if (this.milesCardTransactions.filters.operation.selected.length > 0) {
        filters += `&operation=${this.milesCardTransactions.filters.operation.selected}`;
      }

      if (this.milesCardTransactions.filters.auditUsers.selected.length > 0) {
        filters += `&audit_user_id=${this.milesCardTransactions.filters.auditUsers.selected}`;
      }

      if (this.milesCardTransactions.filters.airlines.selected.length > 0) {
        filters += `&airline_id=${this.milesCardTransactions.filters.airlines.selected}`;
      }

      if (this.milesCardTransactions.filters.milesPrograms.selected.length > 0) {
        filters += `&miles_program_id=${this.milesCardTransactions.filters.milesPrograms.selected}`;
      }

      if (this.milesCardTransactions.filters.users.selected.length > 0) {
        filters += `&user_id=${this.milesCardTransactions.filters.users.selected}`;
      }

      if (this.milesCardTransactions.filters.price.min || this.milesCardTransactions.filters.price.max) {
        let min = this.milesCardTransactions.filters.price.min;
        let max = this.milesCardTransactions.filters.price.max;

        if (min) {
          if (!max) {
            max = "9999999999";
          }
        }

        if (max) {
          if (!min) {
            min = "0";
          }
        }

        filters += `&miles-range=${min}-${max}`;
      }

      filters += `&order=${this.milesCardTransactions.filters.order}&order-by=${this.milesCardTransactions.filters.orderBy}`;

      this.$http
        .get(
          `/miles-card-transactions/list?page=${this.milesCardTransactions.pagination.page}&per_page=${this.milesCardTransactions.pagination.perPage}${filters}`
        )
        .then(({
          data
        }) => {
          if (!samePage) {
            this.milesCardTransactions.pagination.page = 1;
          }
          this.milesCardTransactions.pagination.total = data.meta.total;
          this.milesCardTransactions.pagination.totalPages = data.meta.total_pages;
          this.milesCardTransactions.list = data.data;
          this.milesCardTransactions.loading = false;

          this.milesCardTransactions.meta = data.meta;

          this.excelFile.data = data.data;

          this.excelFile.footer = [];

          this.excelFile.footer.push(" ");

          this.excelFile.footer.push(
            `TOTAL DE MILHAS: ${data.meta.total_miles}`
          );

          this.excelFile.footer.push(
            `VALOR: R$ ${data.meta.total_value}`
          );

          if (data.meta.total != 0) {
            this.excelFile.footer.push(
              `N° TRANSAÇÕES:  ${data.meta.total}`
            );
          }

          if (data.meta.total_emissions != 0) {
            this.excelFile.footer.push(
              `EMISSÕES:  ${data.meta.total_emissions}`
            );
          }

          if (data.meta.total_cancelations != 0) {
            this.excelFile.footer.push(
              `CANCELAMENTOS:  ${data.meta.total_cancelations}`
            );
          }

          if (data.meta.total_new != 0) {
            this.excelFile.footer.push(
              `NOVAS COMPRAS:  ${data.meta.total_new}`
            );
          }

          if (data.meta.total_additions != 0) {
            this.excelFile.footer.push(
              `ADIÇÕES:  ${data.meta.total_additions}`
            );
          }

          if (data.meta.total_refunds != 0) {
            this.excelFile.footer.push(
              `REEMBOLSOS:  ${data.meta.total_refunds}`
            );
          }

        })
        .catch(({
          response
        }) => {
          this.milesCardTransactions.loading = false;
          this.milesCardTransactions.list = response.data.data;
          this.pagination.total = response.data.meta.total;
          this.pagination.totalPages = response.data.meta.total_pages;

          this.milesCardTransactions.meta = response.data.meta;

          this.excelFile.data = response.data.data;
        });
    },

  }

}