import states from "@/json/states";

export default {
  data() {
    return {
      productSupplier: {
        loading: false,
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          totalPages: 0,
        },
        details: {},
        list: [],
        filters: {
          loadingResults: false,
          productExists: false,
          users: {
            list: [],
            selected: [],
          },
          airlines: {
            list: [],
            selected: [],
          },
          milesPrograms: {
            list: [],
            selected: [],
          },
          category: {
            list: [],
            selected: [],
          },
          state: {
            list: states,
            selected: [],
          },
          status: {
            list: [
              {
                label: "Ativo",
                value: "Ativo",
              },
              {
                label: "Desativado",
                value: "Desativado",
              },
            ],
            selected: [],
          },
          period: {
            selected: [],
          },
          only_specific_categories: "",
          supplier: "",
          id: "",
          searchTerm: "",
          city: "",
          available_miles: "",
          price: {
            min: "",
            max: "",
          },
          order: "desc",
          orderBy: "created",
        },
      },
      createProductSupplier: false,
      productSuppliersTableCollumns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 80,
          sorter: true,
        },
        {
          title: "Nome Fantasia",
          key: "trading_name",
          width: 250,
          scopedSlots: {
            customRender: "trading_name",
          },
        },
        {
          title: "Razão Social",
          key: "company_name",
          width: 250,
          scopedSlots: {
            customRender: "company_name",
          },
        },
        {
          title: "CNPJ",
          dataIndex: "cnpj",
          key: "cnpj",
          scopedSlots: {
            customRender: "cnpj",
          },
        },
        {
          title: "Categoria",
          scopedSlots: {
            customRender: "category",
          },
        },
        {
          title: "Cidade",
          dataIndex: "location_city",
          key: "location_city",
          scopedSlots: {
            customRender: "location_city",
          },
        },
        {
          title: "Estado",
          dataIndex: "location_state",
          key: "location_state",
          scopedSlots: {
            customRender: "location_state",
          },
        },

        {
          title: "Criado em",
          dataIndex: "created",
          key: "created",
          sorter: true,
        },
        {
          title: "",
          key: "action",
          align: "right",
          scopedSlots: {
            customRender: "action",
          },
        },
      ],
    };
  },
  methods: {
    changeProductSuppliersPage(current) {
      this.productSupplier.pagination.page = current;
      this.getProductSuppliers();
    },
    changeProductSuppliersPageSize(current, pageSize) {
      this.productSupplier.pagination.page = current;
      this.productSupplier.pagination.perPage = pageSize;
      this.getProductSuppliers();
    },
    productSupplierTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      this.productSupplier.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.productSupplier.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getProductSuppliers();
    },
    onProductSupplierSelected(id) {
      this.productSupplier.details = null;
      this.createProductSupplier = false;
      this.getProductSupplier(id);
    },
    getProductSupplier(id) {
      this.$http
        .post(`/product-supplier/details?id=${id}`)
        .then(({ data }) => {
          this.productSupplier.details = data;
          this.productSupplierForm.setFieldsValue(data);
          if (this.$route.path.split("/")[1] == "product-suppliers") {
            document.title = `${data.id} - ${data.trading_name} `;
          }
        })
        .catch(({ response }) => {
          response;
        });
    },
    getProductSuppliers(samePage) {
      this.productSupplier.loading = true;

      let filters = "";
      if (!this.$root.isAdmin()) {
        if (this.productSupplier.filters.status.selected.length > 0) {
          filters += `&status=${this.productSupplier.filters.status.selected}`;
        }

        if (this.productSupplier.filters.only_specific_categories) {
          filters += `&only_specific_categories=${this.productSupplier.filters.only_specific_categories}`;
        }

        if (this.productSupplier.filters.category.selected.length > 0) {
          filters += `&category=${this.productSupplier.filters.category.selected}`;
        }
      } else {
        if (this.productSupplier.filters.users.selected.length > 0) {
          filters += `&user_id=${this.productSupplier.filters.users.selected}`;
        }

        if (this.productSupplier.filters.period.selected.length > 0) {
          filters += `&period=${this.productSupplier.filters.period.selected[0]}|${this.productSupplier.filters.period.selected[1]}`;
        }

        if (this.productSupplier.filters.status.selected.length > 0) {
          filters += `&status=${this.productSupplier.filters.status.selected}`;
        }

        if (this.productSupplier.filters.category.selected.length > 0) {
          filters += `&category=${this.productSupplier.filters.category.selected}`;
        }

        if (this.productSupplier.filters.state.selected.length > 0) {
          filters += `&state=${this.productSupplier.filters.state.selected}`;
        }

        if (this.productSupplier.filters.only_specific_categories) {
          filters += `&only_specific_categories=${this.productSupplier.filters.only_specific_categories}`;
        }

        if (this.productSupplier.filters.city) {
          filters += `&city=${this.productSupplier.filters.city}`;
        }

        if (this.productSupplier.filters.searchTerm) {
          filters += `&s=${this.productSupplier.filters.searchTerm}`;
        }
      }

      filters += `&order=${this.productSupplier.filters.order}&order-by=${this.productSupplier.filters.orderBy}`;

      this.$http
        .get(
          `/product-supplier/list?page=${this.productSupplier.pagination.page}&per_page=${this.productSupplier.pagination.perPage}${filters}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.productSupplier.pagination.page = 1;
          }
          this.productSupplier.pagination.total = data.meta.total;
          this.productSupplier.pagination.totalPages = data.meta.total_pages;

          this.productSupplier.list = data.data;
          this.productSupplier.loading = false;
        })
        .catch(({ response }) => {
          this.productSupplier.pagination.total = response.data.meta.total;
          this.productSupplier.pagination.totalPages =
            response.data.meta.total_pages;

          this.productSupplier.list = [];
          this.productSupplier.loading = false;
        });
    },
  },
};
