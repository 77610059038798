var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-page-header',{staticClass:"mt-30 pd-0 mb-10"},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('h1',[_c('img',{staticClass:"mr-5",attrs:{"src":require("@/assets/images/dashboard/contracts/livelo-color.png"),"alt":"img","width":"20"}}),_vm._v("\n        Regra de Pontuação Livelo\n      ")])]),_vm._v(" "),_c('div',{attrs:{"slot":"extra"},slot:"extra"},[_c('a-popconfirm',{attrs:{"title":"Tem certeza que deseja adicionar nova regra?","ok-text":"Sim","placement":"left","cancel-text":"Não"},on:{"confirm":_vm.addRule}},[_c('a-button',{attrs:{"loading":_vm.createLoading,"type":"primary","ghost":""}},[_vm._v("\n          Adicionar nova regra\n        ")])],1)],1)]),_vm._v(" "),_c('a-alert',{staticClass:"f12 mb-20",attrs:{"type":"info","message":"Cadastre as regras de pontuação Livelo.","banner":""}}),_vm._v(" "),_c('a-table',{staticClass:"travel-table",attrs:{"columns":[
      {
        title: 'Nome',
        scopedSlots: { customRender: 'name' },
        width: 200,
        sorter: true,
      },

      {
        title: 'Regra',
        width: 80,
        scopedSlots: { customRender: 'rule' },
      },

      {
        title: 'Valor',
        width: 160,
        scopedSlots: { customRender: 'value' },
      },

      {
        title: 'Data inicial',
        width: 100,
        scopedSlots: { customRender: 'start_date' },
      },

      {
        title: 'Data final',
        width: 100,
        scopedSlots: { customRender: 'end_date' },
      },

      {
        title: 'Product Code',
        scopedSlots: { customRender: 'product_code' },
        width: 160,
      },

      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        class: 'status',
        sorter: true,
      },
      {
        title: '',
        key: 'action',
        align: 'right',
        width: 100,
        scopedSlots: { customRender: 'action' },
      },
    ],"data-source":_vm.liveloPointsRule.list,"loading":_vm.liveloPointsRule.loading,"pagination":false},scopedSlots:_vm._u([{key:"name",fn:function(record){return _c('div',{},[_vm._v("\n      "+_vm._s(record.name ? record.name : "Nome da regra")+"\n    ")])}},{key:"product_code",fn:function(record){return _c('div',{},[_vm._v("\n      "+_vm._s(record.product_code)+"\n    ")])}},{key:"rule",fn:function(record){return _c('div',{},[_vm._v("\n      "+_vm._s(record.rule)+"\n    ")])}},{key:"value",fn:function(record){return _c('div',{},[_vm._v("R$ "+_vm._s(record.value))])}},{key:"start_date",fn:function(record){return _c('div',{},[_vm._v("\n      "+_vm._s(record.start_date != "0000-00-00"
          ? _vm.formatDate(record.start_date)
          : "---")+"\n    ")])}},{key:"end_date",fn:function(record){return _c('div',{},[_vm._v("\n      "+_vm._s(record.end_date != "0000-00-00" ? _vm.formatDate(record.end_date) : "---")+"\n    ")])}},{key:"action",fn:function(record){return _c('div',{staticClass:"travel-actions"},[_c('a-icon',{staticStyle:{"zoom":"0.03"},attrs:{"type":"edit-svg"},on:{"click":function($event){return _vm.edit(record.raw)}}}),_vm._v(" "),_c('a-popconfirm',{attrs:{"title":"Tem certeza que deseja apagar?","ok-text":"Sim","placement":"left","cancel-text":"Não"},on:{"confirm":function($event){return _vm.deleteRow(record.raw.id)}}},[_c('a-icon',{staticClass:"red ml-10 f14 c-pointer",attrs:{"type":"delete"}})],1)],1)}}])}),_vm._v(" "),_c('div',{staticClass:"a-center mt-30",staticStyle:{"padding-bottom":"100px"}},[_c('a-pagination',{attrs:{"show-size-changer":"","default-current":_vm.liveloPointsRule.pagination.page,"page-size":_vm.liveloPointsRule.pagination.perPage,"total":_vm.liveloPointsRule.pagination.total},on:{"update:pageSize":function($event){return _vm.$set(_vm.liveloPointsRule.pagination, "perPage", $event)},"update:page-size":function($event){return _vm.$set(_vm.liveloPointsRule.pagination, "perPage", $event)},"change":_vm.changeLiveloPointsRulesPage,"showSizeChange":_vm.changeLiveloPointsRulePageSize}})],1),_vm._v(" "),_c('a-drawer',{attrs:{"placement":"right","width":"600px","closable":true,"visible":_vm.openEditRuleModal},on:{"close":function($event){_vm.openEditRuleModal = false}}},[_c('template',{slot:"title"},[_vm._v(" Editar regra de pontuação ")]),_vm._v(" "),(_vm.openEditRuleModal)?_c('LiveloPointsRuleEditModal',{attrs:{"theRule":_vm.theRule},on:{"reloadLiveloPointsRuleList":function($event){return _vm.getLiveloPointsRule()}}}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }