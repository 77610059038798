<template>
    <div>
        <a-form :form="form" @submit="submit">
            <a-row :gutter="20">
                <a-col :span="12">
                    <a-form-item class="travel-input-outer">
                        <label :class="'filled'">Nome</label>
                        <a-input
                            class="travel-input"
                            placeholder="Ex: Promo 01"
                            v-decorator="[
                                `name`,
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item class="travel-input-outer">
                        <label :class="'filled'">Regra dos pontos</label>
                        <a-input
                            class="travel-input"
                            placeholder="Ex: 1"
                            type="number"
                            v-decorator="[
                                `rule`,
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col
                    v-if="
                        form.getFieldValue(`rule`) != 0 &&
                        form.getFieldValue(`rule`) != undefined
                    "
                    :span="24"
                >
                    <a-alert class="f12 mb-30" type="info" banner>
                        <template slot="message">
                            R$ 1 = {{ form.getFieldValue(`rule`) }} pontos
                            Livelo.</template
                        >
                    </a-alert>
                </a-col>

                <a-col :span="12">
                    <a-form-item class="travel-input-outer">
                        <label :class="'filled'">Custo por ponto</label>
                        <a-input
                            class="travel-input"
                            placeholder="Insira um valor"
                            v-currency="{
                                precision: 3,
                            }"
                            v-decorator="[
                                `value`,
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item class="travel-input-outer">
                        <label :class="'filled'">Status</label>
                        <a-select
                            class="travel-input select"
                            placeholder="Selecione"
                            @change="onChangeLiveloPointsRule"
                            v-decorator="[
                                `status`,

                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-select-option
                                v-for="(item, index) of status"
                                :key="index"
                                :value="item.value"
                            >
                                {{ item.label }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item class="travel-input-outer">
                        <label :class="'filled'">Data inicial</label>
                        <a-date-picker
                            class="travel-input"
                            placeholder="Selecione"
                            format="DD/MM/YYYY"
                            :showToday="false"
                            :allowClear="false"
                            v-mask="'##/##/####'"
                            v-decorator="[
                                'start_date',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-icon slot="prefix" type="field-svg" />
                        </a-date-picker>
                    </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item class="travel-input-outer">
                        <label :class="'filled'">Data final</label>
                        <a-date-picker
                            class="travel-input"
                            placeholder="Selecione"
                            format="DD/MM/YYYY"
                            :showToday="false"
                            :allowClear="false"
                            v-mask="'##/##/####'"
                            v-decorator="[
                                'end_date',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-icon slot="prefix" type="field-svg" />
                        </a-date-picker>
                    </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item class="travel-input-outer">
                        <label :class="'filled'">Product Code</label>
                        <a-input
                            class="travel-input"
                            placeholder="Ex: PROMO10"
                            v-decorator="[
                                `product_code`,
                                {
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col :span="24" class="a-center">
                    <a-button
                        type="primary"
                        html-type="submit"
                        :loading="loading"
                    >
                        Salvar alterações
                    </a-button>
                </a-col>
            </a-row>
        </a-form>
    </div>
</template>

<script>
export default {
    props: {
        theRule: Object,
    },
    data() {
        return {
            form: this.$form.createForm(this),
            loading: false,
            status: [
                { label: "Ativo", value: "Ativo" },
                { label: "Desativado", value: "Desativado" },
            ],
        };
    },
    mounted() {
        this.form.setFieldsValue({
            name: this.theRule.name,
            rule: this.theRule.rule != 0 ? this.theRule.rule : undefined,
            value: this.theRule.value.replace(".", ","),
            status: this.theRule.status,
            start_date:
                this.theRule.start_date != "0000-00-00"
                    ? this.theRule.start_date
                    : undefined,
            end_date:
                this.theRule.end_date != "0000-00-00"
                    ? this.theRule.end_date
                    : undefined,
            product_code: this.theRule.product_code,
        });
    },
    methods: {
        submit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                console.log(values);
                if (!err) {
                    err, values;

                    values.id = this.theRule.id;
                    values.user_id = this.theRule.user_id;
                    values.value = values.value
                        ? values.value.replace(",", ".")
                        : 0;
                    values.modified_by = {
                        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                        id: this.$store.state.userData.id,
                    };

                    this.loading = true;

                    this.$http
                        .post("/livelo-points-rule/update", values)
                        .then(({ data }) => {
                            this.$message.success("Regra livelo atualizada!");
                            data;
                            this.loading = false;

                            this.$emit("reloadLiveloPointsRuleList");
                        })
                        .catch(({ response }) => {
                            response;
                            this.$message.error(
                                "Não foi possível atualizar a regra livelo."
                            );
                            this.loading = false;
                        });
                }
            });
        },
    },
};
</script>
