<template>
  <div class="no-data-wrapper">
    <div class="no-data">
      <a-icon v-if="icon" :type="icon" />
      <img v-if="img" :src="img.src" :width="img.width" alt="ico" />
      <div class="no-data-text">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoData",
  props: {
    text: {
      type: String,
    },
    icon: {
      type: String,
    },
    img: {
      type: Object,
    },
  },
};
</script>

<style lang="sass" scoped>
.no-data-wrapper
    margin: 0 auto
    display: flex
    position: absolute
    width: 100%
    justify-content: center
    top: 50%
    -ms-transform: translateY(-50%)
    transform: translateY(-50%)
    .no-data
        text-align: center
        font-size: 64px
        i
            color: #bbb
        &-text
            font-size: 18px
            color: #ccc
</style>
