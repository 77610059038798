<template>
  <aFormItem class="hf-wrapper hf-select-wrapper">
    <label for v-if="label">{{ label }}</label>
    <div class="description" v-if="description">{{ description }}</div>
    <aSelect
      class="hf-select"
      :class="fieldClass"
      :placeholder="placeholder || 'Selecione'"
      :optionFilterProp="optionFilterProp"
      :allowClear="allowClear"
      :dropdownMatchSelectWidth="dropdownMatchSelectWidth"
      :showSearch="showSearch"
      :loading="loading"
      :disabled="disabled"
      v-decorator="[
        fieldKey,
        {
          initialValue,
          rules: validationRules,
        },
      ]"
      @change="onChange"
      @search="onSearch"
      @select="onSelect"
    >
      <aSelectOption
        v-for="(item, index) of list"
        :key="index"
        :value="item.value"
        :txt="item.label"
        :obj="item"
      >
        <div v-html="item.label" />
      </aSelectOption>
    </aSelect>
  </aFormItem>
</template>

<script>
export default {
  name: "HayaSelectField",
  inheritAttrs: false,
  props: {
    label: String,
    description: String,
    fieldKey: String,
    placeholder: String,
    optionFilterProp: String,
    requiredMessage: String,
    dropdownMatchSelectWidth: Boolean,
    fieldClass: String,
    initialValue: String,
    allowClear: Boolean,
    showSearch: Boolean,
    required: Boolean,
    list: Array,
    form: Object,
    readonly: Boolean,
    loading: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      validationRules: [],
    };
  },
  beforeMount() {
    if (this.required) {
      this.validationRules.push({
        required: true,
        message: this.requiredMessage ? this.requiredMessage : "Obrigatório",
      });
    }
  },
  methods: {
    onChange(e, item) {
      this.$emit("onChangeSelectField", e, item);
    },
    onSearch(e, item) {
      this.$emit("onSearchSelectField", e, item);
    },
    onSelect(e, item) {
      this.$emit("onSelectSelectField", e, item);
    },
  },
};
</script>

<style lang="sass">

.hf-select-wrapper
  .ant-form-explain
    font-size: 9px !important
    top: -4px
    position: relative
  .ant-select-selection
    border-width: 0 0 2px 0
    border-radius: 0
</style>

<style lang="sass" scoped>
.hf-select
  width: 100%
.hf-wrapper
  label
    font-size: 10px
    font-weight: 500
    color: #ccc
    line-height: 1
    text-overflow: ellipsis
    width: 100%
    white-space: nowrap
    margin-top: -10px
    margin-bottom: 2px
    display: block

  .description
    font-size: 9px
    line-height: 1.2
    margin: 8px  0 3px
    font-weight: 500
    border-left: 2px solid #ddd
    padding-left: 7px
</style>
