<template>
  <section>
    <div class="system-logs" v-if="log.list.length > 0">
      <aTimeline class="pd-20 pl-10">
        <a-timeline-item
          v-for="(item, index) in log.list"
          :key="index"
          :color="color"
        >
          <aIcon
            slot="dot"
            style="color: red"
            type="fire"
            v-if="item.module === 'opportunity'"
          />

          <aIcon
            slot="dot"
            style="color: #52c41a"
            type="file-text"
            class="f16"
            v-if="
              item.module === 'contract' &&
              ![
                'contract-manual-anti-fraud-concluded',
                'contract-manual-anti-fraud-status',
                'send-contract-email',
              ].includes(item.action)
            "
          />

          <aIcon
            slot="dot"
            style="color: #2db7f5"
            type="mail"
            class="f16"
            v-if="
              item.module === 'contract' &&
              item.action === 'send-contract-email'
            "
          />

          <aIcon
            slot="dot"
            style="color: #0083cd"
            type="shopping"
            class="f16"
            v-if="item.module === 'sale'"
          />
          <img
            v-if="
              ['sale', 'contract'].includes(item.module) &&
              item.action === 'contract-manual-anti-fraud-concluded'
            "
            src="@/assets/images/dashboard/contracts/fraud-green.png"
            slot="dot"
            alt="ico"
            width="18"
          />
          <img
            v-if="
              ['sale', 'contract'].includes(item.module) &&
              item.action === 'contract-manual-anti-fraud-status'
            "
            src="@/assets/images/dashboard/contracts/fraud-red.png"
            slot="dot"
            alt="ico"
            width="17"
          />
          <a-tag
            v-if="showAction"
            :color="theColor(item.module)"
            class="ml-10 mr-10"
          >
            {{ item.action }}
          </a-tag>
          <span class="f13 cblack" v-html="item.description" />
          <div style="margin: -5px 0 0 -1px">
            <span class="f10" style="color: #aaa">
              <aIcon type="clock-circle" class="cgray" />
              {{ formatDate(item.created) }}
              <a-divider type="vertical" />
              {{ formatHayaModules(item.module) }}
            </span>
          </div>
        </a-timeline-item>
      </aTimeline>

      <div class="mt-20">
        <a-pagination
          show-size-changer
          :default-current="log.pagination.page"
          :page-size-options="pageSizeOptions"
          :page-size.sync="log.pagination.perPage"
          :total="log.pagination.total"
          @change="changeMultiLogPage"
          @showSizeChange="changeMultiLogPageSize"
        >
        </a-pagination>
      </div>
    </div>
    <div v-else class="no-results mt-60">
      <a-empty description="Nenhuma alteração realizada até o momento." />
    </div>
  </section>
</template>

<script>
import { format, parse } from "date-fns";
import logsMixins from "@/mixins/logs/logsMixins";
import modules from "@/json/hayaModules.json";

export default {
  mixins: [logsMixins],
  props: {
    color: String,
    showAction: Boolean,
    opportunityId: String,
    contractId: String,
    saleId: String,
    perPage: Number,
  },
  data() {
    return {
      pageSizeOptions: ["10", "25", "50", "100", "200", "500", "1000"],
      modules,
    };
  },
  mounted() {
    this.log.pagination.perPage = this.perPage ? this.perPage : 10;
    this.log.filters.opportunityId =
      this.opportunityId != 0 ? this.opportunityId : "";
    this.log.filters.contractId = this.contractId != 0 ? this.contractId : "";
    this.log.filters.saleId = this.saleId != 0 ? this.saleId : "";
    this.getMultiLogs();
  },
  methods: {
    theColor(module) {
      let color = "#4c388e";

      if (module === "opportunity") {
        color = "#4a89dc";
      }

      return color;
    },
    formatHayaModules(module) {
      let theModule = "";

      if (module) {
        let selectedModule = this.modules.filter((mod) => {
          return mod.value == module;
        });

        theModule = selectedModule.length > 0 ? selectedModule[0].name : module;
      }

      return theModule;
    },
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(theDate, "dd/MM/yyyy HH:mm");
    },
  },
};
</script>

<style lang="sass">
.system-logs
  .ant-timeline-item-content
    margin: 0 0 0 28px
</style>

<style lang="sass" scoped>
pre
  max-width: 600px

.ant-timeline
  min-height: 427px
  max-height: 425px
  overflow: auto
</style>
