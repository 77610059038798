<template>
  <div>
    <div class="contract-header">
      <aRow :gutter="10">
        <aCol :span="4">
          <div class="box">
            <div class="title cprimary">ID {{ tempContract.id }}</div>
            <div class="value dotted-phrase mt-5 f10">
              {{ contract.user ? contract.user.first_name : "" }}
              {{ contract.user ? contract.user.last_name : "" }}
            </div>
          </div>
        </aCol>

        <aCol :span="5">
          <div class="box">
            <div class="title">TOTAL</div>
            <div class="value">
              {{ formatPricePtBr(allContractData.total) }}
            </div>
          </div>
        </aCol>

        <aCol :span="5">
          <div class="box">
            <div class="title">A PAGAR</div>
            <div class="value">
              {{ formatPricePtBr(allContractData.totalNet) }}
            </div>
          </div>
        </aCol>

        <aCol :span="4">
          <div class="box">
            <div class="title">TAXAS</div>
            <div class="value">
              {{ formatPricePtBr(allContractData.totalTaxes) }}
            </div>
          </div>
        </aCol>

        <aCol :span="6">
          <div class="box">
            <div class="title">LUCRO</div>
            <div class="value cgreen">
              {{ formatPricePtBr(allContractData.totalProfit) }}
              ({{ formatPercentage(allContractData.totalProfitPercentage) }}%)
            </div>
          </div>
        </aCol>
      </aRow>
    </div>

    <div class="travel-tabs">
      <div class="tab-icons">
        <div
          class="tab grayscale"
          :class="activeTab == 'customer' ? 'active' : ''"
          @click="portalActiveTab('customer')"
        >
          <img
            src="@/assets/images/dashboard/contracts/customer-ico.png"
            alt="img"
            height="30"
          />
        </div>

        <div
          v-show="contractedServices.includes('hotel')"
          class="tab grayscale"
          :class="activeTab == 'hotel' ? 'active' : ''"
          @click="portalActiveTab('hotel')"
        >
          <img
            src="@/assets/images/dashboard/contracts/hotel-ico.png"
            alt="img"
            height="30"
          />
        </div>

        <div
          v-show="contractedServices.includes('flight')"
          class="tab grayscale"
          :class="activeTab == 'flight' ? 'active' : ''"
          @click="portalActiveTab('flight')"
        >
          <img
            src="@/assets/images/dashboard/contracts/flight-ico.png"
            alt="img"
            height="30"
          />
        </div>

        <div
          v-show="contractedServices.includes('service')"
          class="tab grayscale"
          :class="activeTab == 'service' ? 'active' : ''"
          @click="portalActiveTab('service')"
        >
          <img
            src="@/assets/images/dashboard/contracts/services-ico.png"
            alt="img"
            height="28"
          />
        </div>

        <div
          v-if="requestMiles(tempContract).show"
          class="tab grayscale"
          :class="activeTab == 'miles-card' ? 'active' : ''"
          @click="portalActiveTab('miles-card')"
        >
          <img
            src="@/assets/images/dashboard/contracts/credit-card.png"
            alt="img"
            width="28"
          />
        </div>

        <div
          class="tab grayscale"
          :class="activeTab == 'wallet' ? 'active' : ''"
          @click="portalActiveTab('wallet')"
        >
          <img
            src="@/assets/images/dashboard/contracts/wallet-ico.png"
            alt="img"
            width="30"
          />
        </div>

        <div
          v-if="
            $root.isAdmin() &&
            strToJson(tempContract.marketing_types).includes('Livelo')
          "
          class="tab grayscale"
          :class="activeTab == 'marketing' ? 'active' : ''"
          @click="portalActiveTab('marketing')"
        >
          <img
            src="@/assets/images/dashboard/contracts/marketing-ico.png"
            alt="img"
            width="30"
          />
        </div>

        <div
          class="tab grayscale"
          :class="activeTab == 'docs' ? 'active' : ''"
          @click="portalActiveTab('docs')"
        >
          <img
            src="@/assets/images/dashboard/contracts/folder.png"
            alt="img"
            width="26"
          />
        </div>

        <div
          class="tab grayscale"
          :class="activeTab == 'notes' ? 'active' : ''"
          @click="portalActiveTab('notes')"
        >
          <img
            src="@/assets/images/dashboard/contracts/notes-ico.png"
            alt="img"
            height="28"
          />
        </div>

        <div
          v-if="$root.isAdmin()"
          class="tab grayscale"
          :class="activeTab == 'logs' ? 'active' : ''"
          @click="portalActiveTab('logs')"
        >
          <img
            src="@/assets/images/dashboard/contracts/logs-ico.png"
            alt="img"
            height="23"
          />
        </div>

        <div
          v-if="$root.isAdmin()"
          class="tab grayscale"
          :class="activeTab == 'settings' ? 'active' : ''"
          @click="portalActiveTab('settings')"
        >
          <img
            src="@/assets/images/dashboard/contracts/settings.png"
            alt="img"
            width="30"
          />
        </div>
      </div>

      <div class="tab-content">
        <a-form
          :form="form"
          @onFieldsChange="updateTempContract"
          @submit="submitContract"
        >
          <div class="tab-scroll">
            <NoData
              v-if="activeTab == 0"
              text="Nenhum produto cadastrado"
              :img="{ src: noProductsIco, width: 200 }"
              style="margin-top: -50px"
            />

            <ContractCustomerDetails
              v-if="activeTab == 'customer'"
              :tempContract="tempContract"
            />

            <ContractHotelDetails
              v-if="activeTab == 'hotel'"
              :tempContract="tempContract"
            />

            <ContractFlightDetails
              v-if="activeTab == 'flight'"
              :tempContract="tempContract"
            />
            <ContractServiceDetails
              v-if="activeTab == 'service'"
              :tempContract="tempContract"
            />

            <ContractMilesCardDetails
              v-if="
                activeTab == 'miles-card' && requestMiles(tempContract).show
              "
              :form="form"
              :tempContract="tempContract"
              @updateContractsList="updateContractsList"
            />

            <ContractPaymentMethodsDetails
              v-if="activeTab == 'wallet'"
              :tempContract="tempContract"
            />

            <div class="content-row" v-if="activeTab == 'docs'">
              <h3>Documentos</h3>
              <ContractDocumentsTab
                v-if="activeTab == 'docs'"
                :tempContract="tempContract"
              />
            </div>

            <ContractMarketingDetails
              v-if="activeTab == 'marketing'"
              :tempContract="tempContract"
              :form="form"
            />

            <div class="content-row" v-if="activeTab === 'logs'">
              <h3>Logs</h3>
              <MultiLogsTimeline
                class="mt-10"
                :opportunityId="tempContract.opportunity_id"
                :contractId="tempContract.id"
                :saleId="tempContract.sale_id"
              />
            </div>

            <div class="content-row" v-if="activeTab == 'notes'">
              <h3>Anotações</h3>

              <Notes
                v-if="activeTab == 'notes'"
                listType="multi"
                moduleName="contract"
                :entry="contract"
                :noteReasonsCategoryid="21"
                :moduleId="`${contract.id}`"
                :multiIds="[
                  {
                    module: 'opportunity',
                    id: contract.opportunity_id,
                  },
                  {
                    module: 'contract',
                    id: contract.id,
                  },
                  {
                    module: 'sale',
                    id: contract.sale_id,
                  },
                ]"
              />
            </div>

            <ContractModalSettingsTab
              class="section"
              :tempContract="tempContract"
              :contract="contract"
              v-if="activeTab == 'settings' && $root.isAdmin()"
              @onUpdateContractSettings="updateContractsList"
            />
          </div>

          <div class="contract-footer">
            <aRow :gutter="20">
              <aCol :span="24">
                <span v-if="$root.isAdmin()">
                  <a class="cgreen" @click="edit(tempContract.id)">
                    <a-icon type="edit" /> Editar
                  </a>

                  <a-divider type="vertical" />
                </span>

                <span v-if="tempContract.allow_user_edit != '0'">
                  <span v-if="!$root.isAdmin() && !tempContract.contract_done">
                    <a @click="edit(tempContract.id)">
                      <a-icon type="edit" /> Editar
                    </a>

                    <a-divider type="vertical" />
                  </span>
                </span>

                <span v-if="$root.isAdmin()">
                  <a class="cgreen" @click="view(tempContract.id)">
                    <a-icon type="eye" /> Ver
                  </a>

                  <a-divider type="vertical" />
                </span>

                <span
                  v-if="!$root.isAdmin() && tempContract.status == 'concluded'"
                >
                  <a style="color: #8d8d8d" @click="view(tempContract.id)">
                    <a-icon type="eye" /> Ver
                  </a>

                  <a-divider type="vertical" />
                </span>

                <span>
                  <a-popconfirm
                    title="Tem certeza que deseja duplicar?"
                    ok-text="Sim"
                    placement="top"
                    cancel-text="Não"
                    @confirm="duplicate(tempContract.id)"
                  >
                    <a style="color: #8d8d8d">
                      <a-icon type="copy" /> Duplicar
                    </a>
                  </a-popconfirm>

                  <a-divider type="vertical" />
                </span>

                <span v-if="$root.isAdmin()">
                  <a-popconfirm
                    title="Tem certeza que deseja apagar?"
                    ok-text="Sim"
                    placement="top"
                    cancel-text="Não"
                    @confirm="confirmDelete(tempContract.id)"
                  >
                    <a class="red"> <a-icon type="delete" /> Apagar </a>
                  </a-popconfirm>

                  <a-divider type="vertical" />
                </span>

                <span v-if="tempContract['status'] != 'canceled'">
                  <a @click="cancel(tempContract)" class="red">
                    <a-icon type="close" /> Cancelar
                  </a>
                </span>

                <span v-if="tempContract['status'] == 'canceled'">
                  <a class="red"> Cancelado </a>
                </span>

                <span
                  v-if="
                    tempContract.status == 'concluded' &&
                    tempContract.contract_email != undefined
                  "
                >
                  <a-divider type="vertical" />

                  <a-popconfirm
                    ok-text="Sim"
                    placement="top"
                    cancel-text="Não"
                    @confirm="sendContractEmail()"
                  >
                    <template slot="title">
                      <div class="f12">
                        <div class="mb-10">
                          Tem certeza que deseja enviar e-mail?
                        </div>

                        Assunto*
                        <a-input
                          class="travel-input f12 mb-10"
                          placeholder="Assunto (Detalhes da sua viagem)"
                          v-model="contractEmailSubject"
                        >
                        </a-input>
                        <br />
                        <b class="mt-5">Para: </b>
                        <span class="lower">{{ contract.customer.email }}</span>
                        <br />
                        <b>Cc:</b>
                        {{ contract.user.email }}
                        <br />
                        <a-input
                          class="travel-input f12 mt-10"
                          placeholder="E-mail de cópia extra (opcional)"
                          v-model="ccoContractEmail"
                        >
                        </a-input>
                        <br />
                        <a-input
                          v-if="$store.state.userData.id == 1"
                          class="travel-input f12 mt-10"
                          placeholder="E-mail de dev (opcional)"
                          v-model="devEmail"
                        >
                        </a-input>
                      </div>
                    </template>
                    <a v-if="!loadingSendEmail" style="color: #8d8d8d">
                      <a-icon type="mail" /> Enviar E-mail
                    </a>
                    <a v-if="loadingSendEmail" style="color: #8d8d8d">
                      Enviando...
                    </a>
                  </a-popconfirm>
                </span>

                <span v-if="emailIsSent && tempContract.status == 'concluded'">
                  <a-divider type="vertical" />

                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>E-mail enviado</span>
                    </template>
                    <a class="cgreen">
                      <a-icon type="mail" />
                    </a>
                  </a-tooltip>
                </span>
              </aCol>
            </aRow>
          </div>
        </a-form>
      </div>
    </div>

    <a-modal
      :title="'CANCELAR CONTRATO - ID ' + cancelContract.id"
      :visible="cancelContract.modal"
      :footer="false"
      @cancel="closeCancelContractModal"
    >
      <aRow>
        <aCol :span="24">
          <div class="travel-input-outer mt-20" style="position: relative">
            <label class="filled"> Motivo do cancelamento do contrato </label>
            <a-select
              class="travel-input"
              placeholder="Selecione o motivo  do cancelamento do contrato"
              style="width: 100%"
              v-model="cancelContract.reason"
              :disabled="
                tempContract['contract_cancelation_reason'] != undefined
                  ? true
                  : false
              "
            >
              <a-select-option
                v-for="(reason, index) of cancelationReasonsList"
                :key="index"
                :value="reason.name"
              >
                {{ reason.name }}
              </a-select-option>
            </a-select>
          </div>
        </aCol>

        <aCol class="mt-20" :span="24">
          <a-textarea
            v-model="cancelContract.obs"
            placeholder="Observações"
            :disabled="
              tempContract['contract_cancelation_observation'] != undefined
                ? true
                : false
            "
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </aCol>

        <aCol
          v-if="tempContract['status'] != 'canceled'"
          class="a-center mt-20"
          :span="24"
        >
          <a-button
            type="danger"
            :loading="cancelContract.loading"
            @click="submitCancelContract()"
          >
            Cancelar contrato
          </a-button>
        </aCol>
      </aRow>
    </a-modal>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import contractMixins from "../mixins/contractMixins";
import ContractHotelDetails from "../tabs/ContractHotelDetails.vue";
import ContractFlightDetails from "../tabs/ContractFlightDetails.vue";
import ContractServiceDetails from "../tabs/ContractServiceDetails.vue";

import noProductsIco from "../../../assets/images/dashboard/contracts/no-products.png";
import NoData from "../../general/NoData.vue";
import ContractPaymentMethodsDetails from "../tabs/ContractPaymentMethodsDetails.vue";
import MultiLogsTimeline from "../../logs/MultiLogsTimeline.vue";
import Notes from "../../general/Notes.vue";
import ContractCustomerDetails from "../tabs/ContractCustomerDetails.vue";
import ContractMilesCardDetails from "../tabs/ContractMilesCardDetails.vue";
import ContractMarketingDetails from "../tabs/ContractMarketingDetails.vue";
import ContractDocumentsTab from "../tabs/ContractDocumentsTab.vue";
import ContractModalSettingsTab from "../tabs/ContractModalSettingsTab.vue";

export default {
  name: "ContractModal",
  components: {
    ContractHotelDetails,
    ContractFlightDetails,
    ContractServiceDetails,
    NoData,
    ContractPaymentMethodsDetails,
    MultiLogsTimeline,
    Notes,
    ContractCustomerDetails,
    ContractMilesCardDetails,
    ContractMarketingDetails,
    ContractDocumentsTab,
    ContractModalSettingsTab,
  },
  props: { contractId: String },
  mixins: [contractMixins, formatThings],
  data() {
    return {
      form: this.$form.createForm(this, {
        onValuesChange: this.updateTempContract,
      }),
      contract: {},
      tempContract: {},
      contractDataFromDatabase: {},
      updateContractLoading: false,
      contractedServices: [],
      activeTab: "customer",
      cancelationReasonsList: [],
      cancelContract: {
        id: null,
        modal: false,
        loading: false,
        reason: undefined,
        obs: undefined,
        contract: {},
      },
      ccoContractEmail: "",
      devEmail: "",
      contractEmailSubject: "Detalhes da sua viagem",
      emailIsSent: false,
      loadingSendEmail: false,
      noProductsIco,
    };
  },
  beforeMount() {
    this.getContract();
    this.$http
      .get(
        `/category-entry/list?page=1&per_page=50&category_id=16&status=Ativo&order-by=name&order=ascend`
      )
      .then(({ data }) => {
        this.cancelationReasonsList = data.data;
      });
  },
  mounted() {
    setTimeout(() => {
      if (this.tempContract.id != undefined) {
        if (this.tempContract.sale_id == 0) this.getSaleByContractId();
      }
    }, 1000);
  },
  methods: {
    sendContractEmail() {
      let fromEmail = undefined,
        ccEmail = {
          cc: [this.contract.user.email],
        },
        contractData = this.tempContract.contract_email
          ? JSON.parse(this.tempContract.contract_email)
          : {};

      if (this.contractEmailSubject) {
        this.loadingSendEmail = true;

        if (this.contract.company_branch.from_email) {
          fromEmail = {
            from: {
              name: this.contract.company_branch.name,
              email: this.contract.company_branch.from_email,
            },
          };
        } else {
          fromEmail = {
            from: {
              name: this.contract.company_branch.name,
              email: "no-reply@haya.social",
            },
          };
        }

        if (this.ccoContractEmail != "") {
          ccEmail.cc.push(this.ccoContractEmail);
        }

        if (contractData.contracted_services == undefined) {
          contractData.contracted_services = JSON.parse(
            this.tempContract.contracted_services
          );
        }

        if (contractData.package_type == undefined) {
          contractData.package_type = this.tempContract.package_type;
        }

        let emailData = {};

        if (this.devEmail) {
          emailData = {
            contract: contractData,
            to: {
              name: "Dev",
              email: this.devEmail,
            },
            ...fromEmail,
            subject: this.contractEmailSubject,
          };
        } else {
          emailData = {
            contract: contractData,
            to: {
              name: this.contract.customer.first_name,
              email: this.contract.customer.email,
            },
            ...fromEmail,
            ...ccEmail,
            subject: this.contractEmailSubject,
          };
        }

        this.$http
          .post("/email/contract/send-contract-data", emailData)
          .then(({ data }) => {
            this.$message.success(data.message);
            this.loadingSendEmail = false;

            let updateContract = {};

            updateContract = {
              id: this.tempContract.id,
              customer_id: this.tempContract.customer_id,
              company_id: this.tempContract.company_id,
              company_branch_id: this.tempContract.company_branch_id,
              id_infotera: this.tempContract.id_infotera,
              user: this.tempContract.user,
              company: this.tempContract.company,
              value: this.tempContract.value,
              contracted_services: this.tempContract.contracted_services,
              release_date: this.tempContract.release_date,
              status: this.tempContract.status,
              contract_email_is_sent: true,
              modified_by: {
                name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                id: this.$store.state.userData.id,
              },
            };

            this.$http.post("/log/create", {
              user_id: this.$store.state.userData.id,
              module_id: this.tempContract.id,
              module: "contract",
              action: "send-contract-email",
              description: `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> enviou o e-mail do contrato <font class="id">ID ${this.tempContract.id}</font>.`,
            });

            this.emailIsSent = true;

            this.$http
              .post("/contract/update", updateContract)
              .then(({ data }) => {
                data;
              });
          });
      } else {
        this.$message.warning("O assunto é obrigatório");
      }
    },
    getSaleByContractId() {
      this.$http
        .post(
          `/sale/list?page=1&per_page=1&contract-id=${this.tempContract.id}&order=desc&order-by=created`
        )
        .then(({ data }) => {
          this.tempContract.sale_id = data.data[0].id;

          this.$http.post("/contract-v2/update-field", {
            id: this.tempContract.id,
            field: "sale_id",
            value: data.data[0].id,
          });
        });
    },
    requestMiles(tempContract) {
      let flag = false;
      let airlineFlag = false;
      let totalMiles = 0;

      if (tempContract.flight_sections_rows != undefined) {
        JSON.parse(tempContract.flight_sections_rows).forEach((flight) => {
          if (tempContract[`flight_${flight.id}_type`] == "Milhas") {
            // FLIGHT AIRLINES ROWS
            flight.airlines.forEach((airline) => {
              let airlineTravellers = [];
              if (
                (tempContract[
                  `flight_${flight.id}_airline_${airline.id}_travellers`
                ] &&
                  tempContract[
                    `flight_${flight.id}_airline_${airline.id}_miles_program`
                  ]) ||
                (tempContract[
                  `flight_${flight.id}_airline_${airline.id}_miles_program`
                ] &&
                  tempContract[
                    `flight_${flight.id}_airline_${airline.id}_travellers`
                  ])
              ) {
                airlineTravellers = JSON.parse(
                  tempContract[
                    `flight_${flight.id}_airline_${airline.id}_travellers`
                  ]
                );
                if (airlineTravellers.length > 0) {
                  airlineFlag = true;
                }
              } else {
                airlineFlag = false;
              }
            });
            // FLIGHT TRAVELLERS ROWS
            flight.travellers.forEach((traveller) => {
              if (
                (tempContract[
                  `flight_${flight.id}_traveller_${traveller.id}_miles`
                ] &&
                  tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}_value`
                  ] &&
                  tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}_taxes`
                  ] &&
                  tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}_taxes_currency`
                  ]) ||
                (tempContract[
                  `flight_${flight.id}_traveller_${traveller.id}_miles`
                ] &&
                  tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}_value`
                  ] &&
                  tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}_taxes`
                  ] &&
                  tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}_taxes_currency`
                  ])
              ) {
                flag = true;
              } else {
                flag = false;
              }
            });
          }
        });
      }

      return { show: flag && airlineFlag, miles: totalMiles };
    },
    confirmDelete(id) {
      this.$http
        .post(`/contract-v2/delete?id=${id}`)
        .then(({ data }) => {
          this.$message.success(data.message + "!");
          this.$emit("updateContractsList");
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    updateContractsList() {
      this.$emit("updateContractsList");
    },
    edit(id) {
      this.$router.push(`/contracts/edit/${id}`);
    },
    view(id) {
      this.$router.push(`/contracts/view/${id}`);
    },
    duplicate(id) {
      this.$http
        .post("/contract/duplicate", {
          id: id,
          user_id: this.$store.state.userData.id,
        })
        .then(({ data }) => {
          this.$message.success(data.message);
          this.$emit("updateContractsList");
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    getContract() {
      this.$http
        .post(`/contract-v2/details?id=${this.contractId}`)
        .then(({ data }) => {
          this.contract = data;
          this.tempContract = {
            ...data,
            the_customer: data.customer,
          };

          let allFieldsSize = Object.values(data.meta).length,
            values = Object.values(data.meta),
            keys = Object.keys(data.meta);

          for (let i = 0; i < allFieldsSize; i++) {
            this.tempContract[keys[i]] = values[i];
          }

          this.tempContract.user = this.tempContract.user.id;
          this.tempContract.customer_id = this.tempContract.customer.id;

          delete this.tempContract.meta;
          delete this.tempContract.customer;

          this.contractDataFromDatabase = this.tempContract;

          this.contractedServices = data.contracted_services
            ? JSON.parse(data.contracted_services)
            : [];

          if (this.tempContract.contract_email_is_sent != undefined) {
            this.emailIsSent =
              this.tempContract.contract_email_is_sent == 1 ? true : false;
          }

          this.contractPricingData(this.tempContract);
        });
    },
    updateTempContract(props, fields) {
      let field = Object.keys(fields)[0],
        value = Object.values(fields)[0];

      this.tempContract[field] = value;

      if (Array.isArray(value)) {
        this.tempContract[field] = JSON.stringify(value);
      }

      this.tempContract = { ...this.tempContract };
    },
    compareContractObjects(newObj, oldObj) {
      if (Object.keys(oldObj).length == 0 && Object.keys(newObj).length > 0)
        return {
          data: newObj,
          contractIsEqual:
            Object.keys(oldObj).length == Object.keys(newObj).length,
        };

      let diff = {};
      for (const key in oldObj) {
        if (newObj[key] && oldObj[key] != newObj[key]) {
          diff[key] = newObj[key];
        }
      }

      if (Object.keys(diff).length > 0)
        return {
          data: diff,
          contractIsEqual: false,
        };

      return {
        data: oldObj,
        contractIsEqual:
          Object.keys(oldObj).length == Object.keys(newObj).length,
      };
    },
    submitContract(e) {
      e.preventDefault();

      this.form.validateFields((err) => {
        let updateContract = {};

        updateContract = {
          id: this.tempContract.id,
          customer_id: this.tempContract.customer_id,
          company_id: this.tempContract.company_id,
          company_branch_id: this.tempContract.company_branch_id,
          id_infotera: this.tempContract.id_infotera,
          user: this.tempContract.user,
          company: this.tempContract.company,
          value: this.tempContract.value,
          contracted_services: this.tempContract.contracted_services,
          release_date: this.tempContract.release_date,
          status: this.tempContract.status,
          modified_by: {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          },
          ...this.compareContractObjects(
            this.tempContract,
            this.contractDataFromDatabase
          ).data,
        };

        console.log("submit", updateContract);

        if (!err) {
          this.updateContractLoading = true;
          this.$http
            .post("/contract-v2/update", updateContract)
            .then(({ data }) => {
              this.$message.success(data.message);
              // this.getContract();
              // this.$emit("updateContractsList");
            })
            .finally(() => (this.updateContractLoading = false));
        }
      });
    },
    portalActiveTab(active) {
      setTimeout(() => {
        this.activeTab = active;
      }, 100);

      this.contractPricingData(this.tempContract);
    },
    closeCancelContractModal() {
      this.cancelContract = {
        id: null,
        reason: undefined,
        obs: undefined,
        modal: false,
        loading: false,
      };
    },
    submitCancelContract() {
      if (this.cancelContract.reason && this.cancelContract.obs) {
        this.cancelContract.loading = true;

        let updateContract = {};

        updateContract = {
          id: this.tempContract.id,
          customer_id: this.tempContract.customer_id,
          company_id: this.tempContract.company_id,
          company_branch_id: this.tempContract.company_branch_id,
          id_infotera: this.tempContract.id_infotera,
          user: this.tempContract.user,
          company: this.tempContract.company,
          value: this.tempContract.value,
          contracted_services: this.tempContract.contracted_services,
          release_date: this.tempContract.release_date,
          status: "canceled",
          contract_cancelation_reason: this.cancelContract.reason,
          contract_cancelation_observation: this.cancelContract.obs,
          action: "cancel-contract",
          description: `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> cancelou o contrato  <font class="id">ID ${this.tempContract.id}</font> pelo motivo: ${this.cancelContract.reason}.`,
          modified_by: {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          },
        };

        this.$http
          .post("/contract/update", updateContract)
          .then(({ data }) => {
            data;
            this.$message.success("Contrato cancelado com sucesso!");
            this.closeCancelContractModal();
            this.getContract();
            this.$emit("updateContractsList");
          })
          .catch(({ response }) => {
            this.$message.error(response.data.message);
            this.cancelContract.loading = false;
          });
      } else {
        this.$message.warning(
          "Selecione o motivo do cancelamento do contrato e a observação."
        );
      }
    },
    cancel(contract) {
      this.cancelContract.id = contract.id;
      this.cancelContract.contract = contract;
      this.cancelContract.modal = true;
      this.cancelContract.reason =
        contract.contract_cancelation_reason != undefined
          ? contract.contract_cancelation_reason
          : undefined;

      this.cancelContract.obs =
        contract.contract_cancelation_observation != undefined
          ? contract.contract_cancelation_observation
          : undefined;
    },
  },
};
</script>

<style lang="sass" scoped>

.contract-header
  padding: 10px
  border-bottom: 1px solid #e0e0e0
  .box
    border-radius: 6px
    font-size: 14px
    padding: 10px
    border-radius: 6px
    border: 1px solid #ddd
    height: 58px
    .title
      color: #bcbec0
      font-weight: 600
    .value
      color: #333
      font-weight: 600
  h1
    font-weight: 600
    font-size: 18px !important
    margin: 0
    padding: 0
    letter-spacing: -1px
    text-transform: uppercase
.travel-tabs
  height: 642px
  .tab-icons
    float: left
    border-right: 1px solid #e0e0e0
    width: 75px
    height: 642px
    text-align: center
    .tab
      float: left
      height: 60px
      width: 100%
      line-height: 56px
      cursor: pointer
      transition: .6s
      opacity: 0.6
      border-bottom: 1px solid #e0e0e0
      .alert
        position: absolute
        z-index: 10
        top: 39px
        right: 3px
        font-size: 21px
        &:hover
          background: none !important
    & :hover, .active
      filter: none
      -webkit-filter: grayscale(0)
      -webkit-filter: grayscale(0%)
      opacity: 1
  .tab-content
    position: relative
    background: #fafafa
    height: 642px
    margin-left: 75px
    .content-row
      background: #FFF
      padding: 10px
      border: 1px solid #ddd !important
      margin: 0 0 10px
      border-radius: 6px
      font-size: 13px
      font-weight: 500
      padding: 10px
      h3
        padding: 0 0 6px 0
        font-size: 14px
        font-weight: 600
        margin-bottom: 15px
        color: #be4178
        text-transform: uppercase
        border-bottom: 1px solid #ececec
    .contract-footer
      height: 42px
      padding: 9px 10px
      border-top: 1px solid #e0e0e0
      background: #fff
      border-radius: 0 6px
      a
        font-size: 12px
        font-weight: 600
    .tab-scroll
      overflow: auto
      padding: 10px 10px 10px 10px
      height: 600px
      .section
        background: #FFF
        padding: 20px 10px
        border: 1px solid #ddd
        border-radius: 6px
    .title
      display: block
      border-bottom: 1px solid #efefef
      font-size: 14px
      font-weight: 500
      color: #434244 !important
      padding: 5px 8px
.anticon svg
  background: none !important
.anticon svg:hover
  background: none !important
</style>
