<template>
  <div>
    <a-row>
      <a-col class="content-row" :span="24">
        <a-row>
          <a-col class="title-action" :span="24">
            <a-row type="flex" justify="space-between" align="top">
              <a-col> <h3 class="mb-10">Cartões de Milhas</h3></a-col>
              <a-col>
                <a-popconfirm
                  v-if="tempContract.request_miles_status == true"
                  ok-text="Sim"
                  cancel-text="Não"
                  @confirm="confirmTicketed"
                  @cancel="cancelTicketed"
                >
                  <template slot="title">
                    Tem certeza que deseja marcar o ticket
                    <br />
                    aéreo como bilhetado?
                  </template>
                  <a-checkbox
                    v-model="ticketed"
                    :disabled="loading"
                    style="
                      font-size: 13px;
                      font-weight: 500;
                      position: relative;
                    "
                  >
                    Bilhetado
                  </a-checkbox>
                </a-popconfirm>
              </a-col>
            </a-row>
          </a-col>

          <a-col :span="24">
            <RequestMiles
              :showActions="false"
              :tempContract="tempContract"
              :form="form"
              :show="true"
              :allowMilesRequest="false"
              :milesCardOrder="milesCardOrder"
              :milesRequestList="airlinesList"
              @updateTempContract="openMilesRequestModal = false"
              @closeRequestModal="openMilesRequestModal = false"
            />
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
import RequestMiles from "../forms/RequestMiles.vue";

export default {
  name: "ContractMilesCardDetails",
  components: { RequestMiles },
  props: {
    tempContract: Object,
    form: Object,
  },
  mixins: [formatThings],
  data() {
    return {
      ticketed: false,
      loading: false,
    };
  },
  mounted() {
    if (this.tempContract.flight_is_ticketed != undefined) {
      this.ticketed = this.tempContract.flight_is_ticketed == 1 ? true : false;
    }
  },
  methods: {
    confirmTicketed() {
      this.ticketed = true;
      this.loading = true;
      setTimeout(() => {
        let updateContract = {};
        updateContract = {
          id: this.tempContract.id,
          customer_id: this.tempContract.customer_id,
          company_id: this.tempContract.company_id,
          company_branch_id: this.tempContract.company_branch_id,
          id_infotera: this.tempContract.id_infotera,
          user: this.tempContract.user,
          company: this.tempContract.company,
          value: this.tempContract.value,
          contracted_services: this.tempContract.contracted_services,
          release_date: this.tempContract.release_date,
          status: this.tempContract.status,
          flight_is_ticketed: true,
          modified_by: {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          },
        };

        this.$http
          .post("/contract/update", updateContract)
          .then(({ data }) => {
            this.$message.success(data.message);
            this.$emit("updateContractsList");

            this.loading = false;

            this.$http.post("/log/create", {
              user_id: this.$store.state.userData.id,
              module_id: this.tempContract.id,
              module: "contract",
              action: "flight-ticketed-contract",
              description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} marcou o ticket aéreo como Bilhetado no contrato ID ${this.tempContract.id}.`,
            });
          })
          .catch(({ response }) => {
            this.$message.error(response.data.message);
            this.loading = false;
          });
      }, 20);
    },
    cancelTicketed() {
      this.ticketed = false;

      this.loading = true;
      setTimeout(() => {
        let updateContract = {};
        updateContract = {
          id: this.tempContract.id,
          customer_id: this.tempContract.customer_id,
          company_id: this.tempContract.company_id,
          company_branch_id: this.tempContract.company_branch_id,
          id_infotera: this.tempContract.id_infotera,
          user: this.tempContract.user,
          company: this.tempContract.company,
          value: this.tempContract.value,
          contracted_services: this.tempContract.contracted_services,
          release_date: this.tempContract.release_date,
          status: this.tempContract.status,
          flight_is_ticketed: false,
          modified_by: {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          },
        };

        this.$http
          .post("/contract/update", updateContract)
          .then(({ data }) => {
            this.$message.success(data.message);
            this.$emit("updateContractsList");

            this.loading = false;

            this.$http.post("/log/create", {
              user_id: this.$store.state.userData.id,
              module_id: this.tempContract.id,
              module: "contract",
              action: "flight-ticketed-contract",
              description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} desmarcou o ticket aéreo como Bilhetado no contrato ID ${this.tempContract.id}.`,
            });
          })
          .catch(({ response }) => {
            this.$message.error(response.data.message);
            this.loading = false;
          });
      }, 20);
    },
  },
};
</script>

<style lang="sass" scoped>
.content-row
  background: #FFF
  padding: 10px
  border: 1px solid #ddd !important
  margin: 0 0 10px
  border-radius: 6px
  font-size: 13px
  font-weight: 500
  .label
    display: block
    font-size: 12px
    font-weight: 500
    color: #aaa
  h4
    font-size: 10px !important
    font-weight: 600
    color: #be4178
  .travellers
    .traveller
      padding: 0 0 8px 0
      margin: 0 0 8px
      border-bottom: 1px solid #eee
    .traveller:last-child
      border: 0
      padding-bottom:0
    .font
      font-weight: 600
      border-radius: 4px
      font-size: 12px
      background: #eee
      padding: 1px 5px 2px
      margin: 4px 4px 0px 0
      text-transform: uppercase
      word-break: break-all
      float: left
  .p-row.sub
    .name
      top: 8px
      background: #FFF
      padding: 0 10px 0 22px
  .p-row.no-dot
    border: 0
    .name
      max-width: 100%
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    .name.hotel
      color: #00c100
      font-weight: 600
    .name.service
      color: #ff8000
      font-weight: 600
    .name.flight
      font-weight: 600
      color: #1890ff
  .p-row
    font-size: 12px
    border-bottom: 2px dotted #ccc
    margin: 4px
    .val
      top: 8px
      background: #FFF
      padding: 0 0px 0 5px
    .ico
      width: 25px
      text-align: center
      display: inline-block
    .name
      top: 8px
      background: #FFF
      padding: 0 10px 0 0px
      max-width: 220px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

      .num
        font-weight: 700
  padding: 10px
  .t1
    color: #00cd6b
  .t2
    color: #ff4545
  .t3
    color: #ff9a3e
  .title-action
    border-bottom: 1px solid #ececec
    padding: 0 0 6px 0
    margin-bottom: 10px
    h3
      padding: 0 !important
      margin: 0 !important
      font-size: 14px
      font-weight: 600
      color:#be4178
      text-transform: uppercase
</style>
