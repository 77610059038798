<template>
    <div>
        <a-timeline class="travel-timeline" v-if="logs.data.length > 0">
            <a-timeline-item v-for="(item, index) in logs.data" :key="index" :color="color">
                <a-tag v-if="showAction" color="#4c388e" class="ml-10 mr-10">
                    {{ item.action }}
                </a-tag>
                <span v-html="item.description" />
                <a-divider type="vertical" />
                <a-icon type="clock-circle" class="mr-5 cgray" />
                <strong> {{ formatDate(item.created) }}</strong>
            </a-timeline-item>
        </a-timeline>
        <div v-else class="no-results mt-60">
            <a-empty description="Nenhuma alteração realizada até o momento." />
        </div>
    </div>
</template>

<script>
import { format, parse } from "date-fns";
export default {
    props: {
        logs: Array,
        showAction: Boolean,
        color: String
    },
    data() {
        return {};
    },
    methods: {
        formatDate(date) {
            let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
            return format(theDate, "dd/MM/yyyy HH:mm");
        },
    },
};
</script>