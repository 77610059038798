<template>
  <div>
    <a-row>
      <a-col class="content-row" :span="24">
        <h3>
          <a-row type="flex" justify="space-between">
            <a-col> Cartão de Crédito </a-col>

            <a-col>
              <a-tooltip placement="top" title="Ver dados do cartão">
                <a-button
                  type="primary"
                  @click="showPasswordModal = true"
                  shape="circle"
                  icon="eye"
                  size="small"
                />
              </a-tooltip>
            </a-col>
          </a-row>
        </h3>

        <div
          v-for="(cCard, index) in creditCardRows"
          class="credit-card"
          :key="index"
        >
          <a-row class="infos" :gutter="20">
            <a-col class="mb-10" :span="24">
              <h4>
                <a-icon type="credit-card" /> #{{ cCard.id }} -
                {{
                  tempContract[
                    `payment_methods_credit_card_owner_${cCard.id}`
                  ] == "client"
                    ? "Contratante"
                    : "Terceiro"
                }}
              </h4>
            </a-col>
            <a-col :span="6">
              <span class="label">Total Cartão de Crédito:</span>
              {{
                formatPricePtBr(
                  tempContract[
                    `payment_methods_credit_card_total_value_${cCard.id}`
                  ]
                )
              }}
            </a-col>

            <a-col :span="3">
              <span class="label">Parcelas:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_installments_${cCard.id}`
                ]
              }}
            </a-col>

            <a-col :span="5">
              <span class="label">Valor da 1ª parcela:</span>

              {{
                formatMultiPricePtBr(
                  tempContract[
                    `payment_methods_credit_card_first_installment_value_${cCard.id}`
                  ]
                )
              }}
            </a-col>

            <a-col :span="4">
              <span class="label">Demais parcelas:</span>
              {{
                formatMultiPricePtBr(
                  tempContract[
                    `payment_methods_credit_card_installments_value_${cCard.id}`
                  ]
                )
              }}
            </a-col>

            <a-col :span="6">
              <span class="label">Proprietário do cartão:</span>
              {{
                tempContract[`payment_methods_credit_card_owner_${cCard.id}`] ==
                "client"
                  ? "Contratante"
                  : "Terceiro"
              }}
            </a-col>
          </a-row>

          <a-row
            class="mt-20 card-owner"
            v-if="
              tempContract[`payment_methods_credit_card_owner_${cCard.id}`] !=
              'client'
            "
          >
            <a-col class="mb-10" :span="24">
              <h4>
                <a-icon type="user" />
                {{
                  tempContract[
                    `payment_methods_credit_card_owner_${cCard.id}_name`
                  ]
                }}
              </h4>
            </a-col>

            <a-col :span="6">
              <span class="label">CPF:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_owner_${cCard.id}_CPF`
                ]
              }}
            </a-col>

            <a-col :span="6">
              <span class="label">Celular:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_owner_${cCard.id}_mobile_phone`
                ]
              }}
            </a-col>

            <a-col :span="6">
              <span class="label">Telefone fixo:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_owner_${cCard.id}_phone`
                ]
              }}
            </a-col>

            <a-col :span="6">
              <span class="label">CEP:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_owner_${cCard.id}_CEP`
                ]
              }}
            </a-col>

            <a-col class="mt-10" :span="20">
              <span class="label">Logradouro:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_owner_${cCard.id}_location_street`
                ]
              }}
            </a-col>
            <a-col class="mt-10" :span="4">
              <span class="label">Número:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_owner_${cCard.id}_location_number`
                ]
              }}
            </a-col>

            <a-col class="mt-10" :span="8">
              <span class="label">Complemento:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_owner_${cCard.id}_location_complement`
                ]
              }}
            </a-col>

            <a-col class="mt-10" :span="8">
              <span class="label">Bairro:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_owner_${cCard.id}_location_neighborhood`
                ]
              }}
            </a-col>

            <a-col class="mt-10" :span="6">
              <span class="label">Cidade:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_owner_${cCard.id}_location_city`
                ]
              }}
            </a-col>

            <a-col class="mt-10" :span="2">
              <span class="label">Estado:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_owner_${cCard.id}_location_state`
                ]
              }}
            </a-col>
          </a-row>

          <a-row class="mt-10" :gutter="20">
            <a-col :span="6">
              <span class="label">Maquininha:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_machine_id_${cCard.id}`
                ] == 14
                  ? "Direto com fornecedor"
                  : theCreditCardMachine(
                      tempContract[
                        `payment_methods_credit_card_machine_id_${cCard.id}`
                      ]
                    )
              }}
            </a-col>

            <a-col
              v-if="
                tempContract[
                  `payment_methods_credit_card_machine_id_${cCard.id}`
                ] == 14
              "
              :span="10"
            >
              <span class="label">Fornecedor:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_machine_supplier_${cCard.id}`
                ]
              }}
            </a-col>

            <a-col
              v-if="
                tempContract[
                  `payment_methods_credit_card_machine_id_${cCard.id}`
                ] != 14
              "
              :span="4"
            >
              <span class="label">Taxa PDQ:</span>
              {{
                formatMultiPricePtBr(
                  tempContract[
                    `payment_methods_credit_card_tax_value_${cCard.id}`
                  ]
                )
              }}
            </a-col>

            <a-col
              v-if="
                tempContract[
                  `payment_methods_credit_card_machine_id_${cCard.id}`
                ] != 14
              "
              :span="4"
            >
              <span class="label">Taxa Adm:</span>
              {{
                formatMultiPricePtBr(
                  tempContract[
                    `payment_methods_credit_card_advance_fee_value_${cCard.id}`
                  ]
                )
              }}
            </a-col>

            <a-col
              v-if="
                tempContract[
                  `payment_methods_credit_card_machine_id_${cCard.id}`
                ] != 14
              "
              :span="4"
            >
              <span class="label">Total Taxas:</span>
              {{
                formatMultiPricePtBr(
                  tempContract[
                    `payment_methods_credit_card_total_tax_discount_${cCard.id}`
                  ]
                )
              }}
            </a-col>
          </a-row>

          <a-row :gutter="[20, 0]">
            <a-col :span="24">
              <div
                class="travel-credit-card"
                style="margin: 12px -12px 0px; width: 102.7%; zoom: 0.8"
              >
                <div class="card">
                  <div class="front">
                    <span class="chip"
                      ><img src="../../../assets/images/chip.png" alt="chip"
                    /></span>

                    <span class="flag">
                      {{
                        tempCreditCard[
                          `payment_methods_credit_card_client_flag_${cCard.id}`
                        ]
                          ? tempCreditCard[
                              `payment_methods_credit_card_client_flag_${cCard.id}`
                            ]
                          : "xxxxxxx"
                      }}
                    </span>
                    <span class="numbers">
                      {{
                        tempCreditCard[
                          `payment_methods_credit_card_client_number_${cCard.id}`
                        ]
                          ? tempCreditCard[
                              `payment_methods_credit_card_client_number_${cCard.id}`
                            ]
                          : "xxxx xxxx xxxx xxxx"
                      }}
                    </span>
                    <span class="name">
                      <font class="txt">Nome impresso</font>
                      <font class="value">
                        {{
                          tempCreditCard[
                            `payment_methods_credit_card_client_name_${cCard.id}`
                          ]
                            ? tempCreditCard[
                                `payment_methods_credit_card_client_name_${cCard.id}`
                              ]
                            : "xxxxxxxxxxxxxxxxx"
                        }}</font
                      >
                    </span>
                    <span class="expires">
                      <font class="txt">Expiração</font>
                      <font class="value">
                        {{
                          tempCreditCard[
                            `payment_methods_credit_card_client_month_${cCard.id}`
                          ]
                            ? tempCreditCard[
                                `payment_methods_credit_card_client_month_${cCard.id}`
                              ]
                            : "xx/xxxx"
                        }}
                        {{
                          tempCreditCard[
                            `payment_methods_credit_card_client_year_${cCard.id}`
                          ]
                            ? "/" +
                              tempCreditCard[
                                `payment_methods_credit_card_client_year_${cCard.id}`
                              ]
                            : ""
                        }}</font
                      >
                    </span>
                  </div>
                  <div class="back">
                    <span class="blackbar"></span>
                    <span
                      class="cvv"
                      v-show="
                        tempContract[
                          `payment_methods_credit_card_have_cvv_${cCard.id}`
                        ]
                      "
                    >
                      <font class="txt">CVV</font>
                      <font class="value"> xxx </font>
                    </span>
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>

    <a-modal
      title="Verificação"
      :visible="showPasswordModal"
      :footer="null"
      @cancel="showPasswordModal = false"
      width="300px"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="24">
          <a-input
            class="travel-input"
            v-model="userPassword"
            type="password"
            placeholder="Insira sua senha"
          >
            <a-icon
              slot="prefix"
              type="lock"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-col>

        <a-col :span="24">
          <input
            name="email"
            style="
              color: #fff;
              opacity: 0;
              pointer-events: none;
              height: 0;
              padding: 0;
              border: 0;
            "
            placeholder="Fake email fix"
          />
          <a-input
            class="travel-input"
            v-model="authCode"
            placeholder="Código de autorização"
          >
            <a-icon
              slot="prefix"
              type="idcard"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-col>
        <a-col class="a-center mt-20" :span="24">
          <a-button
            @click="getCardData"
            class="mt-10"
            type="primary"
            :loading="getCardDataLoading"
            allowEnter
          >
            Ver dados
          </a-button>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
import cardMachinesMixins from "@/mixins/card-machines/cardMachinesMixins.js";
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";

export default {
  name: "ContractPaymentMethodsCreditCardDetails",
  props: {
    tempContract: Object,
  },
  mixins: [formatThings, cardMachinesMixins, productSupplierMixins],
  data() {
    return {
      creditCardRows: [],
      showPasswordModal: false,
      tempCreditCard: {},
      authCode: "",
      userPassword: null,
      getCardDataLoading: false,
    };
  },
  mounted() {
    this.creditCardRows = this.tempContract.credit_card_rows
      ? JSON.parse(this.tempContract.credit_card_rows)
      : [];

    this.cardMachines.filters.status.selected = "Ativo";
    this.getCardMachines();
  },
  methods: {
    theCreditCardMachine(id) {
      let selected = this.cardMachines.list.filter((machine) => {
        return machine.id == id;
      });

      return selected ? selected[0].name : "";
    },
    getCardData() {
      if (this.userPassword && this.authCode) {
        this.getCardDataLoading = true;
        this.$http
          .post("/contract/read_card", {
            email: this.$store.state.userData.email,
            password: this.userPassword,
            auth_code: this.authCode,
            contract_id: this.tempContract.id,
            card_id: 1,
            creted_by: this.$store.state.userData.id,
            modified_by: {
              name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
              id: this.$store.state.userData.id,
            },
          })
          .then(({ data }) => {
            this.$message.success(data.message);

            this.tempCreditCard = data.card;

            this.showPasswordModal = false;

            this.getCardDataLoading = false;
            this.authCode = "";
          })
          .catch(({ response }) => {
            this.$message.error(response.data.message);
            this.getCardDataLoading = false;
          });
      } else {
        this.$message.error("Insira sua senha e o código de autorização.");
      }
    },
  },
  //this.cardMachines.list
};
</script>

<style lang="sass" scoped>
.credit-card
  margin-bottom: 20px
  .card-owner
    border: 1px solid #ccc
    padding: 16px
    border-radius: 6px
  h4
    font-size: 14px
    font-weight: 600
    color: #ed0e93
    text-transform: uppercase
    margin: 0
    padding: 0
.credit-card:last-child
  border: 0
  margin-bottom: 0px

.content-row
  font-size: 14px
  font-weight: 600
  .label
    display: block
    font-size: 12px
    font-weight: 500
    color: #aaa
  h3
    padding: 0 0 6px 0
    font-size: 14px
    font-weight: 600
    margin-bottom: 15px
    color: #ed0e93
    text-transform: uppercase
    border-bottom: 1px solid #ececec
</style>
