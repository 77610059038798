<template>
  <div>
    <a-row style="padding-right: 10px">
      <a-col class="content-row" :span="24">
        <h3>TRANSFERÊNCIA BANCÁRIA</h3>
        <div class="list">
          <a-row class="infos" :gutter="20">
            <a-col :span="8">
              <span class="label">Valor total da Transferência:</span>
              {{
                formatPricePtBr(
                  tempContract.payment_methods_bank_transfer_total_value
                )
              }}
            </a-col>

            <a-col :span="8">
              <span class="label">Data de recebimento transferência:</span>
              {{
                formatMultiDates(
                  tempContract.payment_methods_bank_transfer_receipt_date
                )
              }}
            </a-col>

            <a-col v-if="banksList.length > 0" class="mt-10" :span="24">
              <span class="label">Dados Banco:</span>

              <strong style="font-weight: 600">
                {{
                  theBank(tempContract.payment_methods_bank_transfer_bank)
                    .company_name
                }}
              </strong>
              <a-divider type="vertical" />
              <strong style="font-weight: 600">CNPJ/PIX:</strong>
              {{
                theBank(tempContract.payment_methods_bank_transfer_bank).cnpj
              }}
              <div>
                Agência:
                <strong style="font-weight: 600">
                  {{
                    theBank(tempContract.payment_methods_bank_transfer_bank)
                      .agency
                  }}</strong
                >
                <a-divider type="vertical" />
                Conta:
                <strong style="font-weight: 600">
                  {{
                    theBank(tempContract.payment_methods_bank_transfer_bank)
                      .account
                  }}</strong
                >
                <a-divider type="vertical" />
                {{
                  theBank(tempContract.payment_methods_bank_transfer_bank)
                    .number
                }}
                -
                {{
                  theBank(tempContract.payment_methods_bank_transfer_bank).name
                }}
              </div>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";

export default {
  name: "ContractPaymentMethodsBankTransferDetails",
  props: {
    tempContract: Object,
  },
  mixins: [formatThings],
  data() {
    return { banksList: [] };
  },
  mounted() {
    this.$http
      .get(
        `/company-bank-accounts/list?page=1&per_page=20&status=Ativo&allow_payments=1`
      )
      .then(({ data }) => {
        this.banksList = data.data;
      });
  },
  methods: {
    theBank(number) {
      let theBank = this.banksList.filter((bank) => {
        return bank.account == number;
      });

      return theBank[0];
    },
  },
};
</script>

<style lang="sass" scoped>
.content-row
  font-size: 14px
  font-weight: 600
  .label
    display: block
    font-size: 12px
    font-weight: 500
    color: #aaa
  h3
    padding: 0 0 6px 0
    font-size: 14px
    font-weight: 600
    margin-bottom: 15px
    color: #ed0e93
    text-transform: uppercase
    border-bottom: 1px solid #ececec
</style>
