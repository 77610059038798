import formatThings from "@/mixins/general/formatThings";
export default {
  mixins: [formatThings],
  data() {
    return {
      infoteraLoading: false,
      idInfotera: null,
      infoteraFormattedData: {},
      infoteraJsonCode: {},
      travellers: [],
    };
  },
  methods: {
    getInfotera() {
      this.infoteraLoading = true;
      this.$http
        .post(`/infotera/token`)
        .then(({ data }) => {
          this.infoteraFormattedData = {};
          this.$http
            .post(`/infotera/backoffice/booking`, {
              accessToken: data.accessToken,
              id: this.idInfotera,
            })
            .then(({ data }) => {
              if (Object.keys(data).length) {
                this.$message.success(
                  `Dados do ID ${this.idInfotera} recuperados com sucesso!`
                );
                this.infoteraToHaya(data.bookingDetail);
                this.infoteraFormattedData.infotera_json = data.bookingDetail;
                this.afterFormatInfoteraData();
              } else {
                this.infoteraFormattedData = {};
                this.$message.error(`Sem dados para o ID ${this.idInfotera}.`);
              }
            })
            .finally(() => {
              this.infoteraLoading = false;
            });
        })
        .catch(() => {
          this.infoteraLoading = true;
        });
    },
    infoteraToHaya(data) {
      const contractedServices = [];

      data.bookings.forEach((product) => {
        if (
          product.bookingHotel !== undefined &&
          !contractedServices.includes("hotel")
        )
          contractedServices.push("hotel");
        if (
          product.bookingAir !== undefined &&
          !contractedServices.includes("flight")
        )
          contractedServices.push("flight");

        if (
          product.transfer !== undefined &&
          !contractedServices.includes("service")
        )
          contractedServices.push("service");

        if (
          product.vehicle !== undefined &&
          !contractedServices.includes("service")
        )
          contractedServices.push("service");
      });

      this.infoteraFormattedData.active_tab = contractedServices.length
        ? contractedServices[0]
        : "";
      this.infoteraFormattedData[`contracted_services`] =
        JSON.stringify(contractedServices);

      this.formatTravellers(data);
      this.formatHotels(data);
      this.formatFlights(data);
      this.formatServices(data);
    },
    formatServices(data) {
      const servicesRows = [];
      let p = 0;
      data.bookings.forEach((product) => {
        const travellersRows = [];

        if (product.transfer !== undefined) {
          let id = p + 1;

          product.names.forEach((traveller, t) => {
            let tId = t + 1;
            travellersRows.push({ id: tId });

            this.infoteraFormattedData[`service_${id}_traveller_${tId}`] =
              this.travellerIdByName(traveller?.firstName.toLowerCase());
          });

          servicesRows.push({ id, travellers: travellersRows });

          let serviceType = "";

          if (product.transfer?.segments[0].transportType == "IN") {
            serviceType = "Transfer In";
          } else {
            serviceType = "Transfer out";
          }

          this.infoteraFormattedData[`service_${id}_type`] = serviceType;
          this.infoteraFormattedData[
            `service_${id}_name`
          ] = `${product.transfer.name}`;
          this.infoteraFormattedData[`service_${id}_checkin`] =
            product.transfer.date;
          this.infoteraFormattedData[`service_${id}_checkout`] =
            product.transfer.date;
          this.infoteraFormattedData[`service_${id}_locator`] = this.idInfotera;

          this.infoteraFormattedData[`service_${id}_total_price`] =
            `${product.fares[0].price.amount}`.replace(".", ",");

          this.infoteraFormattedData[
            `contract_finances_service_${id}_net_value`
          ] = `${product.fares[0].priceNet.amount}`.replace(".", ",");

          this.infoteraFormattedData[
            `contract_finances_service_${id}_supplier_infotera`
          ] = `${product.provider.name}`;

          p += 1;
        }

        if (product.vehicle !== undefined) {
          let id = p + 1;
          product.names.forEach((traveller, t) => {
            let tId = t + 1;
            travellersRows.push({ id: tId });
            this.infoteraFormattedData[`service_${id}_traveller_${tId}`] =
              this.travellerIdByName(traveller?.firstName.toLowerCase());
          });

          servicesRows.push({ id, travellers: travellersRows });

          this.infoteraFormattedData[`service_${id}_type`] = "Aluguel de Carro";
          this.infoteraFormattedData[
            `service_${id}_name`
          ] = `${product.vehicle.rentalCompany.name} - ${product.vehicle.name}`;
          this.infoteraFormattedData[`service_${id}_checkin`] =
            product.vehicle.pickUpDate;
          this.infoteraFormattedData[`service_${id}_checkout`] =
            product.vehicle.dropOffDate;

          p += 1;
        }
      });

      if (servicesRows.length)
        this.infoteraFormattedData[`service_rows`] =
          JSON.stringify(servicesRows);
    },
    formatFlights(data) {
      data.bookings.forEach((product) => {
        const flightAllTravellers = [];

        product.names.forEach((traveller, t) => {
          let tId = t + 1;
          flightAllTravellers.push({
            id: tId,
            firstName: traveller.firstName,
            fares: traveller.fares,
            locators: traveller.locators,
          });
        });

        if (
          product.bookingAir !== undefined &&
          product.status === "CONFIRMED"
        ) {
          const flightSectionsRows = [];
          if (product.bookingAir.flights.length) {
            product.bookingAir.flights.forEach((flight, f) => {
              let sections = [];

              let id = f + 1;

              const flightTravellers = [];

              flightAllTravellers.forEach((traveller, t) => {
                let tId = t + 1;
                flightTravellers.push({ id: tId });
                this.infoteraFormattedData[`flight_${id}_traveller_${tId}`] =
                  this.travellerIdByName(traveller?.firstName.toLowerCase());

                traveller?.locators.forEach((locator) => {
                  this.infoteraFormattedData[
                    `flight_${id}_traveller_${tId}_locator`
                  ] = locator.code;
                });

                traveller?.fares?.forEach((value) => {
                  // tarifa
                  if (value.type == "FARE") {
                    this.infoteraFormattedData[
                      `flight_${id}_traveller_${tId}_value`
                    ] = `${value.priceNet.amount}`.replace(".", ",");
                  }
                  // taxas
                  if (value.type == "BOARDING_RATE") {
                    this.infoteraFormattedData[
                      `flight_${id}_traveller_${tId}_taxes`
                    ] = `${value.priceNet.amount}`.replace(".", ",");
                  }
                });
              });

              flight.segments.forEach((section, s) => {
                let sId = s + 1;

                let depDateTime = section.departure.split("T");
                let depTimePiece = depDateTime[1].split("-");
                let depTimeSecPieces = depTimePiece[0].split(":");
                let depTime = `${depTimeSecPieces[0]}:${depTimeSecPieces[1]}`;

                let arrDateTime = section.arrival.split("T");
                let arrTimePiece = arrDateTime[1].split("-");
                let arrTimeSecPieces = arrTimePiece[0].split(":");
                let arrTime = `${arrTimeSecPieces[0]}:${arrTimeSecPieces[1]}`;

                sections.push({
                  id: sId,
                });

                this.infoteraFormattedData[
                  `flight_${id}_section_${sId}_origin`
                ] = section.origin;

                this.infoteraFormattedData[
                  `flight_${id}_section_${sId}_destination`
                ] = section.destination;

                this.infoteraFormattedData[
                  `flight_${id}_section_${sId}_departure_date`
                ] = section.departure;

                this.infoteraFormattedData[
                  `flight_${id}_section_${sId}_arrival_date`
                ] = section.arrival;

                this.infoteraFormattedData[
                  `flight_${id}_section_${sId}_departure_time`
                ] = depTime;

                this.infoteraFormattedData[
                  `flight_${id}_section_${sId}_arrival_time`
                ] = arrTime;

                this.infoteraFormattedData[
                  `flight_${id}_section_${sId}_duration`
                ] = this.getTimeIntervalBetwennTwoDates(
                  section.departure,
                  section.arrival
                );

                this.infoteraFormattedData[
                  `flight_${id}_section_${sId}_class`
                ] = "ECONÔMICA";

                this.infoteraFormattedData[
                  `flight_${id}_section_${sId}_airline`
                ] = section.airline?.name.toUpperCase();

                this.infoteraFormattedData[
                  `flight_${id}_section_${sId}_number`
                ] = section.number;
              });

              this.infoteraFormattedData[`flight_${id}_type`] = "Regular";

              flightSectionsRows.push({
                id,
                travellers: flightTravellers,
                airlines: [],
                sections: sections,
                ticketed: false,
              });
            });

            if (flightSectionsRows.length)
              this.infoteraFormattedData[`flight_sections_rows`] =
                JSON.stringify(flightSectionsRows);
          }
        }
      });
    },
    formatHotels(data) {
      if (data.bookings.length) {
        const hotelRows = [];
        let i = 0;
        data.bookings.forEach((product) => {
          if (
            product.bookingHotel !== undefined &&
            product.status === "CONFIRMED"
          ) {
            i += 1;
            let id = i;

            this.infoteraFormattedData[`hotel_${id}_name_infotera`] =
              product?.bookingHotel?.hotel?.name.toUpperCase();

            let totalPrice = 0;
            let totalNet = 0;

            if (product.fares.length) {
              product.fares.forEach((reservation) => {
                totalPrice += reservation.price.amount;
                totalNet += reservation.priceNet.amount;
              });
            }

            this.infoteraFormattedData[`hotel_${id}_total_price`] =
              `${totalPrice.toFixed(2)}`.replace(".", ",");

            this.infoteraFormattedData[
              `contract_finances_hotel_${id}_net_value`
            ] = `${totalNet.toFixed(2)}`.replace(".", ",");

            this.infoteraFormattedData[
              `contract_finances_hotel_${id}_supplier_infotera`
            ] = product?.integrator;

            this.infoteraFormattedData[`hotel_${id}_locator`] = this.idInfotera;

            let hotelRooms = [];
            if (product?.bookingHotel?.rooms) {
              product.bookingHotel.rooms.forEach((room, r) => {
                let roomTravellers = [];
                let roomId = r + 1;

                room?.names.forEach((traveller, t) => {
                  let tId = t + 1;
                  roomTravellers.push({ id: tId });

                  this.infoteraFormattedData[
                    `hotel_${id}_accommodation_${roomId}_traveller_${tId}`
                  ] = this.travellerIdByName(
                    traveller?.firstName.toLowerCase()
                  );
                });

                hotelRooms.push({ id: roomId, travellers: roomTravellers });

                this.infoteraFormattedData[
                  `hotel_${id}_accommodation_${roomId}`
                ] = room.room.name;

                this.infoteraFormattedData[
                  `hotel_${id}_accommodation_${roomId}_type`
                ] = room.room.type === "DOUBLE" ? "DUPLO" : room.room.type;

                this.infoteraFormattedData[`hotel_${id}_checkin`] =
                  room.checkIn;

                this.infoteraFormattedData[`hotel_${id}_checkout`] =
                  room.checkOut;

                this.infoteraFormattedData[`hotel_${id}_checkin_typed`] =
                  this.formatMultiDates(room.checkIn);

                this.infoteraFormattedData[`hotel_${id}_checkout_typed`] =
                  this.formatMultiDates(room.checkOut);

                this.infoteraFormattedData[`hotel_${id}_rates`] =
                  this.getNumberOfDaysAndNightsBetweenTwoDates(
                    room.checkIn,
                    room.checkOut
                  ).days;

                this.infoteraFormattedData[`hotel_${id}_nights`] =
                  this.getNumberOfDaysAndNightsBetweenTwoDates(
                    room.checkIn,
                    room.checkOut
                  ).nights;

                this.infoteraFormattedData[`hotel_${id}_diet`] =
                  room.boardType.name;
              });
            }

            hotelRows.push({ id, accommodation: hotelRooms });
          }
        });

        if (hotelRows.length)
          this.infoteraFormattedData[`hotel_rows`] = JSON.stringify(hotelRows);
      }
    },
    travellerIdByName(name) {
      let tId = this.travellers.filter((traveller) => {
        if (traveller?.firstName.toLowerCase() == name)
          return traveller?.firstName.toLowerCase() == name;
      });

      return tId[0]?.id;
    },
    formatTravellers(data) {
      let allTravellers = [],
        travellersRows = [];
      if (data.bookings.length) {
        data.bookings.forEach((product) => {
          if (product.names.length) {
            product.names.forEach((traveller) => {
              allTravellers.push(traveller);
              //console.log("infoteraToHaya formatTravellers 2", traveller);
            });
          }
        });
      }

      this.removeDuplicates(allTravellers, this.thingsEqual);

      if (allTravellers.length) {
        this.removeDuplicates(allTravellers, this.thingsEqual);

        allTravellers.forEach((traveller, i) => {
          let travellerId = i + 1;
          travellersRows.push({
            id: travellerId,
          });

          this.travellers.push({ id: travellerId, ...traveller });

          this.infoteraFormattedData[`traveller_${travellerId}_first_name`] =
            traveller.firstName;
          this.infoteraFormattedData[`traveller_${travellerId}_last_name`] =
            traveller.lastName;
          this.infoteraFormattedData[`traveller_${travellerId}_birthday`] =
            traveller.birth;
          this.infoteraFormattedData[`traveller_${travellerId}_CPF`] =
            traveller?.document?.number;

          const stringLength = traveller.firstName.length;

          this.infoteraFormattedData[`traveller_${travellerId}_gender`] =
            traveller?.firstName.charAt(stringLength - 1) == "a"
              ? "Feminino"
              : "Masculino";

          this.infoteraFormattedData[`traveller_${travellerId}_age`] =
            traveller.type === "ADT" ? "adult" : "child";
        });
      }

      this.infoteraFormattedData.travellers_rows =
        JSON.stringify(travellersRows);
    },
    thingsEqual(thing1, thing2) {
      return (
        thing1.firstName.toLowerCase() == thing2.firstName.toLowerCase() &&
        thing1.birth == thing2.birth
      );
    },
    removeDuplicates(arr, equals) {
      let originalArr = arr.slice(0);
      let i, len, val;
      arr.length = 0;

      for (i = 0, len = originalArr.length; i < len; ++i) {
        val = originalArr[i];
        if (
          !arr.some(function (item) {
            return equals(item, val);
          })
        ) {
          arr.push(val);
        }
      }
    },
    afterFormatInfoteraData() {
      // for triggering events after import data
    },
  },
};
