export default {
  data() {
    return {
      file: {
        loading: false,
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          totalPages: 0,
        },
        details: {},
        list: [],
        filters: {
          loadingResults: false,
          listType: "",
          multiIds: [],
          users: {
            list: [],
            selected: [],
          },
          period: {
            selected: [],
          },
          id: "",
          module: "",
          customerId: "",
          searchTerm: "",
          order: "desc",
          orderBy: "created",
        },
      },
      createFile: false,
      filesTableColumns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 65,
          sorter: true,
        },
        {
          title: "Nome",
          dataIndex: "name",
          key: "name",
          width: 200,
          sorter: true,
        },
        {
          title: "Extensão",
          dataIndex: "extension",
          key: "extension",
          width: 160,
          sorter: true,
        },
        {
          title: "Tamanho",
          scopedSlots: { customRender: "size" },
          width: 100,
        },

        {
          title: "Enviado em",
          scopedSlots: { customRender: "created" },
          width: 130,
        },
        {
          title: "Usuário",
          width: 80,
          scopedSlots: { customRender: "user" },
        },
        {
          title: "",
          align: "right",
          width: 110,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  methods: {
    changeFilePage(current) {
      this.file.pagination.page = current;
      this.getFiles();
    },
    changeFilePageSize(current, pageSize) {
      this.file.pagination.page = current;
      this.file.pagination.perPage = pageSize;
      this.getFiles();
    },
    fileTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      this.file.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.file.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getFiles();
    },
    onFileSelected(id) {
      this.file.details = null;
      this.createFile = false;
      this.getFile(id);
    },
    getFile(id) {
      this.$http
        .post("/file/details?id=" + id)
        .then(({ data }) => {
          this.file.details = data;
          this.fileForm.setFieldsValue(data);
          if (this.$route.path.split("/")[1] == "files") {
            document.title = `${data.id} - ${data.module} `;
          }
        })
        .catch(({ response }) => {
          response;
        });
    },
    getFiles(samePage) {
      this.file.loading = true;

      let filters = "";
      // if (!this.$root.isAdmin()) {
      //   filters += `&user_id=${this.$store.state.userData.id}`;
      // }

      if (this.file.filters.id) {
        filters += `&module_id=${this.file.filters.id}`;
      }

      if (this.file.filters.customerId) {
        filters += `&customer_id=${this.file.filters.customerId}`;
      }

      if (this.file.filters.module) {
        filters += `&module=${this.file.filters.module}`;
      }

      if (this.file.filters.period.selected.length > 0) {
        filters += `&period=${this.file.filters.period.selected[0]}|${this.file.filters.period.selected[1]}`;
      }

      if (this.file.filters.searchTerm) {
        filters += `&s=${this.file.filters.searchTerm}`;
      }

      if (this.file.filters.listType === "multi") {
        filters += `&list_type=multi`;

        this.file.filters.multiIds.forEach((item) => {
          if (item.module === "opportunity")
            filters += `&opportunity_id=${item.id}`;
          if (item.module === "contract") filters += `&contract_id=${item.id}`;
          if (item.module === "sale") filters += `&sale_id=${item.id}`;
        });
      }

      filters += `&order=${this.file.filters.order}&order-by=${this.file.filters.orderBy}`;

      this.$http
        .get(
          `/file/list?page=${this.file.pagination.page}&per_page=${this.file.pagination.perPage}${filters}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.file.pagination.page = 1;
          }
          this.file.pagination.total = data.meta.total;
          this.file.pagination.totalPages = data.meta.total_pages;

          this.file.list = data.data;
          this.file.loading = false;

          document.getElementById(`count-files-${this.id}`).innerHTML =
            data.meta.total;
        })
        .catch(({ response }) => {
          this.file.pagination.total = response.data.meta.total;
          this.file.pagination.totalPages = response.data.meta.total_pages;

          this.file.list = [];
          this.file.loading = false;
        });
    },
  },
};
