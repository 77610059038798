<template>
  <section id="notes">
    <div class="a-right">
      <aButton type="primary" class="f13" @click="onClickAddNote">
        <a-icon type="form" /> Adicionar nota
      </aButton>
    </div>

    <aRow class="mt-10" v-if="createNote" :gutter="20">
      <aCol class="mb-20" :span="12">
        <a-select
          class="travel-input"
          placeholder="Assunto da anotação"
          optionFilterProp="txt"
          v-model="noteReason"
          show-search
          style="width: 100%"
          @change="onChangeNoteReason"
        >
          <a-select-option
            v-for="(item, index) of reasons"
            :key="index"
            :value="item.name"
            :txt="item.name"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </aCol>

      <aCol v-if="allowSendNotification == 'Sim'" class="mb-20" :span="12">
        <aAlert
          class="f12"
          type="info"
          :message="`Uma notificação será enviada.`"
          banner
        />
      </aCol>

      <aCol :span="24">
        <HayaRichTextField
          :module="moduleName"
          :subFolder="'text-editor'"
          :id="moduleId"
          :value="writtenNote"
          :placeholder="'Digite o texto aqui'"
          formField="writtenNote"
          @textChange="onChangeText"
        />
      </aCol>

      <aCol class="mt-20" :span="18">
        <div class="obs mb-10">
          <span style="color: red">*importante:</span>
          Após a sua anotação ser salva não será possível removê-la.
        </div>
      </aCol>

      <aCol class="a-right mt-20" :span="6">
        <a-button
          type="primary"
          :loading="loadingPublishNote"
          :disabled="false"
          @click="publishNote"
        >
          Publicar <a-icon type="right" />
        </a-button>
      </aCol>
    </aRow>

    <a-timeline
      mode="left"
      class="travel-timeline travel-notes mt-20 mb-20"
      v-if="notes.list.length > 0"
    >
      <a-timeline-item
        v-for="(note, index) in notes.list"
        :key="index"
        color="green"
      >
        <div class="datetime">
          <a-tooltip v-if="note.user.id">
            <template slot="title">
              {{ note.user.first_name }}
              {{ note.user.last_name }}
            </template>
            <div id="avatar">
              <a-avatar
                v-if="note.user.avatar"
                :src="note.user.avatar"
                :size="50"
              />
              <a-avatar
                v-else
                :size="50"
                style="color: #f56a00; background-color: #fde3cf"
              >
                {{ note.user.first_name }}
              </a-avatar>
            </div>
          </a-tooltip>
          <div class="mt-10">
            <a-icon type="clock-circle-o" />
            <div class="time" v-html="formatDateTimeline(note.created)"></div>
          </div>

          <div
            v-if="reasonsAllowedToSendEmail.includes(note.reason)"
            class="mt-10"
          >
            <aRow>
              <aCol :span="12">
                <a-tooltip v-if="note.email_is_sent == 1">
                  <template slot="title"> E-mail enviado! </template>
                  <a-icon type="mail" class="f14 cgreen c-pointer" />
                </a-tooltip>

                <a-tooltip v-if="note.email_is_sent != 1">
                  <template slot="title"> E-mail não enviado </template>
                  <a-icon type="mail" class="f14 red c-pointer" />
                </a-tooltip>
              </aCol>
              <aCol :span="12">
                <a-tooltip v-if="note.notification.is_read == 1">
                  <template slot="title"> Notificação lida! </template>
                  <a-icon type="eye" class="f16 cgreen c-pointer" />
                </a-tooltip>

                <a-tooltip v-if="note.notification.is_read != 1">
                  <template slot="title"> Notificação não lida </template>
                  <a-icon type="eye" class="f16 cgreen red" />
                </a-tooltip>
              </aCol>
            </aRow>
          </div>
        </div>
        <div v-if="note.reason" class="author">
          <b>Assunto:</b> {{ note.reason }} <a-divider type="vertical" />
          <span class="f10" style="color: #aaa">
            {{ formatHayaModules(note.module) }}
          </span>
        </div>
        <div class="content" v-html="formatText(note.content)" />
      </a-timeline-item>
    </a-timeline>

    <div class="a-center mt-20 mb-30" v-if="notes.list.length === 0">
      Nenhuma nota publicada.
    </div>

    <div class="mt-20 a-center">
      <a-pagination
        show-size-changer
        :default-current="notes.pagination.page"
        :page-size.sync="notes.pagination.perPage"
        :total="notes.pagination.total"
        @change="changeNotesPage"
        @showSizeChange="changeNotesPageSize"
      >
      </a-pagination>
    </div>
  </section>
</template>

<script>
// libs
import { parse, format } from "date-fns";
import formatThings from "@/mixins/general/formatThings";
import replaceOnce from "replace-once";
import modules from "@/json/hayaModules.json";

// components
import HayaRichTextField from "@/components/general/fields/HayaRichTextField.vue";

export default {
  name: "Notes",
  mixins: [formatThings],
  components: {
    HayaRichTextField,
  },
  props: {
    listType: String,
    moduleId: String,
    noteReasonsCategoryid: Number,
    module: String, // search purposes only
    moduleName: String,
    entry: Object,
    multiIds: Array,
  },
  data() {
    return {
      createNote: false,
      writtenNote: "",
      noteReason: undefined,
      loadingPublishNote: false,
      allowSendNotification: undefined,
      userGroupToReceiveNotification: "",
      reasons: [{ name: "Apenas para registrar" }],
      reasonsAllowedToSendEmail: [],
      adminUsers: [],
      responsibleUsersForNoteList: [],
      modules,
      notes: {
        list: [],
        loading: false,
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          totalPages: 0,
        },
        details: {},
      },
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.noteReasonsCategoryid) {
        this.$http
          .get(
            `/category-entry/list?page=1&category_id=${this.noteReasonsCategoryid}&per_page=300`
          )
          .then(({ data }) => {
            this.reasons = data.data;

            let allowedReasons = data.data.filter((reason) => {
              return reason.meta.allow_send_notification_and_email == "Sim";
            });

            allowedReasons.forEach((reason) => {
              this.reasonsAllowedToSendEmail.push(reason.name);
            });
          });
      }

      this.$http
        .get(
          `/user/list?page=1&per_page=100&role=admin&status=1&order=desc&order-by=created`
        )
        .then(({ data }) => {
          this.adminUsers = data.data;
        });

      this.getNotes();
    }, 600);
  },
  methods: {
    onChangeText(field, text) {
      this.writtenNote = text;
    },
    onClickAddNote() {
      if (this.createNote) {
        this.createNote = false;
      } else {
        this.createNote = true;
      }
    },
    getUsersByRole(role) {
      this.$http
        .get(
          `/user/list?page=1&per_page=100&role=${role}&status=1&order=desc&order-by=created`
        )
        .then(({ data }) => {
          this.responsibleUsersForNoteList = data.data;
        });
    },
    formatHayaModules(module) {
      let theModule = "";

      if (module) {
        let selectedModule = this.modules.filter((mod) => {
          return mod.value == module;
        });

        theModule = selectedModule.length > 0 ? selectedModule[0].name : module;
      }

      return theModule;
    },
    sendNotificationToUser(noteId) {
      this.$http
        .post("/notification/create", {
          user_id: this.entry.user.id,
          created_by: this.$store.state.userData.id,
          title: `Nova Notificação - ${this.moduleId}`,
          content: this.writtenNote,
          action: `/contracts/edit/${this.moduleId}`,
          is_read: 0,
        })
        .then(({ data }) => {
          this.$http
            .post("/note/update-field", {
              id: noteId,
              field: "notification_id",
              value: data.id,
            })
            .then(() => {
              this.getNotes();
            });
        });
    },
    sendEmailToUser(noteId) {
      this.$http
        .post("/email/contract/send-notes", {
          to: {
            name: `${this.entry.user.first_name} ${this.entry.user.last_name}`,
            email: this.entry.user.email,
          },
          subject: `Nova Notificação - ${this.moduleId}`,
          content: this.writtenNote,
          note_reason: `${this.noteReason}`,
          url: `https://${window.location.hostname}/contracts/edit/${this.moduleId}`,
          contract: this.entry,
          module_name: this.moduleName,
        })
        .then(() => {
          this.$http
            .post("/note/update-field", {
              id: noteId,
              field: "email_is_sent",
              value: true,
            })
            .then(() => {
              this.getNotes();
            });
        });
    },
    sendEmailToAdmins() {
      this.adminUsers.forEach((user) => {
        this.$http.post("/email/contract/send-notes", {
          to: {
            name: `${user.first_name} ${user.last_name}`,
            email: user.email,
          },
          subject: `Cc: Nova Notificação - ${this.moduleId}`,
          content: this.writtenNote,
          contract: this.entry,
          note_reason: `${this.noteReason}`,
          url: `https://${window.location.hostname}/contracts/edit/${this.moduleId}`,
          module_name: this.moduleName,
        });
      });
    },
    sendNotificationAndEmail(noteId) {
      this.sendNotificationToUser(noteId); // send notification to user
      this.sendEmailToUser(noteId); // send email to user
      this.sendEmailToAdmins(); // send email admins
    },
    sendNotificationAndEmailToGroup(noteId) {
      this.responsibleUsersForNoteList.forEach((user) => {
        // email
        this.$http.post("/email/contract/send-notes", {
          to: {
            name: `${user.first_name} ${user.last_name}`,
            email: user.email,
          },
          subject: `Cc: Nova Notificação - ${this.moduleId}`,
          content: this.writtenNote,
          contract: this.entry,
          note_reason: `${this.noteReason}`,
          url: `https://${window.location.hostname}/contracts/list?id=${this.moduleId}&open-modal=true`,
          module_name: this.moduleName,
        });

        // notification
        this.$http
          .post("/notification/create", {
            user_id: user.id,
            created_by: this.$store.state.userData.id,
            title: `Nova Notificação - ${this.moduleId}`,
            content: this.writtenNote,
            action: `/contracts/list?id=${this.moduleId}&open-modal=true`,
            is_read: 0,
          })
          .then(({ data }) => {
            this.$http
              .post("/note/update-field", {
                id: noteId,
                field: "notification_id",
                value: data.id,
              })
              .then(() => {
                this.getNotes();
              });
          });
      });
    },
    onChangeNoteReason(selected) {
      let theReason = this.reasons.filter((reason) => {
        return reason.name == selected;
      });

      this.allowSendNotification =
        theReason[0].meta.allow_send_notification_and_email;

      if (theReason[0].meta.responsible_user_role != undefined) {
        this.getUsersByRole(theReason[0].meta.responsible_user_role);
        this.userGroupToReceiveNotification =
          theReason[0].meta.responsible_user_role;
      } else {
        this.responsibleUsersForNoteList = [];
      }
    },
    upload(file, Editor, cursorLocation) {
      let formData = new FormData();
      formData.append("file", file);

      this.$http
        .post(
          `https://api.viajar.tur.br/v1/media/upload-to-s3?module=notes&id=${this.module}/${this.moduleId}&sub-folder=`,
          formData
        )
        .then(({ data }) => {
          Editor.insertEmbed(cursorLocation, "image", data.url);
        });
    },
    changeNotesPage(current) {
      this.notes.pagination.page = current;
      this.notes.list = [];
      this.getNotes();
    },
    changeNotesPageSize(current, pageSize) {
      this.notes.pagination.page = current;
      this.notes.pagination.perPage = pageSize;
      this.notes.list = [];
      this.getNotes();
    },
    getNotes(samePage) {
      let filters = "";

      if (this.listType === "multi") {
        filters += `&list_type=${this.listType}`;
        this.multiIds.forEach((item) => {
          if (item.module === "opportunity")
            filters += `&opportunity_id=${item.id}`;
          if (item.module === "contract") filters += `&contract_id=${item.id}`;
          if (item.module === "sale") filters += `&sale_id=${item.id}`;
        });
      } else {
        filters += `&module_id=${this.moduleId}`;
        filters += `&module=${this.module}`;
      }

      this.$http
        .post(
          `/note/list?page=${this.notes.pagination.page}&per_page=${this.notes.pagination.perPage}${filters}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.notes.pagination.page = 1;
          }

          this.$emit("onLoadNotes", data.meta);

          this.notes.pagination.total = data.meta.total;
          this.notes.pagination.totalPages = data.meta.total_pages;
          this.notes.list = data.data;
          this.loadingPublishNote = false;
          this.writtenNote = undefined;

          document.getElementById(`count-notes-${this.moduleId}`).innerHTML =
            data.meta.total;
        })
        .catch(() => {
          this.loadingPublishNote = false;
        });
    },
    publishNote() {
      if (this.writtenNote && this.noteReason) {
        this.loadingPublishNote = true;
        this.$store.state.userData.id,
          this.$http
            .post("/note/create", {
              module_id: this.moduleId,
              notification_id: 0,
              user_id: this.$store.state.userData.id,
              module: this.moduleName ? this.moduleName : this.module,
              content: this.writtenNote,
              reason: this.noteReason,
              send_notification: this.allowSendNotification,
              email_is_sent: false,
            })
            .then(({ data }) => {
              if (
                this.moduleName === "opportunity" ||
                this.moduleName === "sale" ||
                this.moduleName === "contract"
              ) {
                this.$http.post("/log/create", {
                  user_id: this.$store.state.userData.id,
                  module_id: this.moduleId,
                  module: this.moduleName,
                  action: "create-note",
                  description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} adicionou uma nota.`,
                });
              }

              if (
                this.allowSendNotification == "Sim" &&
                ["contract", "opportunity", "sale"].includes(this.moduleName)
              ) {
                this.sendNotificationAndEmail(data.id);

                if (this.responsibleUsersForNoteList.length > 0) {
                  this.sendNotificationAndEmailToGroup(data.id);
                }
              }

              this.getNotes();
              this.loadingPublishNote = false;
              this.createNote = false;
              this.$message.success(data.message);

              setTimeout(() => {
                this.writtenNote = undefined;
                this.noteReason = undefined;
                this.allowSendNotification = undefined;
              }, 1000);
            })
            .catch(({ response }) => {
              this.loadingPublishNote = false;
              this.$message.error(response.data.message);
            });
      } else {
        this.$message.warning(
          "Escreva algo no campo de anotações e selecione o assunto."
        );
      }
    },
    formatDateTimeline(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      theDate = format(theDate, "dd/MM/yyyy HH:mm");

      return `${theDate.split(" ")[0]} <br> ${theDate.split(" ")[1]} `;
    },

    formatText(text) {
      let find = ["<p><br></p>", "<img"];
      let replace = ["", "<img id='img-uploaded'"];

      return replaceOnce(text, find, replace, "gi");
    },
  },
};
</script>

<style>
#img-uploaded {
  max-width: 100%;
}

.travel-notes .ant-timeline-item-tail,
.travel-notes .ant-timeline-item-head {
  display: none !important;
}

.travel-notes .ant-timeline-item-content {
  margin: 0;
}
</style>

<style lang="sass" scoped>
.ant-select-selection__placeholder
  font-weight: 500

.travel-timeline
  height: 560px
  overflow: auto
  padding: 10px 20px 10px 90px !important
  .ant-timeline-item
    min-height: 170px
    padding: 30px 0 30px
    position: relative
    margin: 0
    font-size: 14px
    list-style: none
    background: #fff
    border: 1px solid #eee
    border-width: 1px 1px 3px
    padding: 20px !important
    border-radius: 10px
    margin-bottom: 20px
  .ant-timeline-item-tail
      display: none !important
      top: 30px
      height: calc(100% - 0px) !important
  .ant-timeline-item-content
      color: #666
      padding-left: 14px
      font-weight: 400
      font-size: 14px
      .user
          color: #155fc0
  .content p
      font-size: 13px

  br
      display: none
  .author
      font-size: 12px
      color: #000
      font-weight: 400
      margin-bottom: 10px

  .content img
      max-width: 100%
  .datetime
      position: absolute
      top: -14px
      left: -100px
      text-align: center
      i
          font-size: 18px
          color: #ddd
      .time
          padding-top: 4px
          font-size: 10px
          line-height: 12px
          color: #aaa

#notes
    .obs
        font-size: 11px
</style>
