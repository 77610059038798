<template>
  <div>
    <a-row style="padding-right: 10px">
      <a-col
        class="content-row"
        v-for="(item, index) in theList()"
        :key="index"
        :span="24"
      >
        <h3>{{ index + 1 }} - {{ item.name }}</h3>
        <div class="list">
          <a-row
            v-for="(section, index) in item.sections"
            :key="index"
            class="infos mb-10"
            :gutter="20"
          >
            <a-col :span="4">
              <span class="label">Origem:</span>
              {{ section.origin }}
            </a-col>

            <a-col :span="4">
              <span class="label">Destino:</span>
              {{ section.destination }}
            </a-col>

            <a-col :span="5">
              <span class="label">Voo:</span>
              {{ section.type }} (Nº {{ section.number }})
            </a-col>

            <a-col :span="5">
              <span class="label"> Classe:</span>
              {{ section.class }}
            </a-col>

            <a-col :span="5">
              <span class="label"> Cia:</span>
              {{ section.airline }}
            </a-col>
            <a-col class="mt-10" :span="6">
              <span class="label"> Embarque:</span>
              {{ section.departure }}
            </a-col>
            <a-col class="mt-10" :span="6">
              <span class="label"> Desembarque:</span>
              {{ section.arrival }}
            </a-col>
            <a-col class="mt-10" :span="6">
              <span class="label"> Duração:</span>
              {{ section.duration }}
            </a-col>

            <a-col class="mt-10" :span="6">
              <span class="label"> Paradas:</span>
              {{ section.stops }}
            </a-col>
          </a-row>

          <a-row class="" :gutter="20">
            <a-col class="mb-5" :span="24"><h4>VIAJANTES</h4> </a-col>
            <a-col class="travellers" :span="24">
              <a-row
                class="traveller upper"
                v-for="(traveller, index) in item.travellers"
                :key="index"
              >
                <a-col :span="18">
                  {{ traveller.name }}
                </a-col>

                <a-col :span="6">
                  {{ formatMultiDates(traveller.birthday) }}
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";

export default {
  name: "ContractFlightDetails",
  props: {
    tempContract: Object,
  },
  mixins: [formatThings],
  data() {
    return {};
  },
  methods: {
    thePassenger(id) {
      let travellers = JSON.parse(this.tempContract.travellers_rows);
      let theTraveller = {};

      travellers.forEach((traveller) => {
        if (traveller.id == id) {
          theTraveller.name = `${
            this.tempContract[`traveller_${traveller.id}_first_name`]
          } ${this.tempContract[`traveller_${traveller.id}_last_name`]}`;

          theTraveller.birthday = `${
            this.tempContract[`traveller_${traveller.id}_birthday`]
          }`;

          if (this.tempContract[`traveller_${traveller.id}_gender`]) {
            theTraveller.gender = `${
              this.tempContract[`traveller_${traveller.id}_gender`]
            }`;
          }
        }
      });

      return theTraveller;
    },
    theList() {
      let arr = [];
      let contractedServices = JSON.parse(
        this.tempContract.contracted_services
      );

      if (contractedServices.includes("flight")) {
        JSON.parse(this.tempContract["flight_sections_rows"]).forEach(
          (flight) => {
            let allSections = [];
            flight.sections.forEach((section) => {
              allSections.push({
                type: this.tempContract[
                  `flight_${flight.id}_section_${section.id}_type`
                ],
                origin:
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_origin`
                  ],
                destination:
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_destination`
                  ],
                class:
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_class`
                  ],
                airline:
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_airline`
                  ],
                duration:
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_duration`
                  ],
                number:
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_number`
                  ],
                stops:
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_stops`
                  ],
                departure: `${this.formatMultiDates(
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_departure_date`
                  ]
                )} ${
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_departure_time`
                  ]
                }`,

                arrival: `${this.formatMultiDates(
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_arrival_date`
                  ]
                )} ${
                  this.tempContract[
                    `flight_${flight.id}_section_${section.id}_arrival_time`
                  ]
                }`,
              });
            });

            let allTravellers = [];
            flight.travellers.forEach((traveller) => {
              allTravellers.push(
                this.thePassenger(
                  this.tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}`
                  ]
                )
              );
            });

            arr.push({
              name: `AÉREO ${this.tempContract[`flight_${flight.id}_type`]}`,
              type: this.tempContract[`flight_${flight.id}_type`],
              sections: allSections,
              travellers: allTravellers,
            });
          }
        );
      }

      return arr;
    },
  },
};
</script>

<style lang="sass" scoped>
.content-row
  background: #FFF
  padding: 10px
  border: 1px solid #ddd !important
  margin: 0 0 10px
  border-radius: 6px
  font-size: 13px
  font-weight: 500
  .infos
    padding-bottom: 10px
    border-bottom: 1px dashed #ddd
  .label
    display: block
    font-size: 12px
    font-weight: 500
    color: #aaa
  h4
    font-size: 10px !important
    font-weight: 600
    color: #be4178
  .travellers
    .traveller
      padding: 0 0 8px 0
      margin: 0 0 8px
      border-bottom: 1px solid #eee
    .traveller:last-child
      border: 0
      padding-bottom:0
    .font
      font-weight: 600
      border-radius: 4px
      font-size: 12px
      background: #eee
      padding: 1px 5px 2px
      margin: 4px 4px 0px 0
      text-transform: uppercase
      word-break: break-all
      float: left
  .p-row.sub
    .name
      top: 8px
      background: #FFF
      padding: 0 10px 0 22px
  .p-row.no-dot
    border: 0
    .name
      max-width: 100%
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    .name.hotel
      color: #00c100
      font-weight: 600
    .name.service
      color: #ff8000
      font-weight: 600
    .name.flight
      font-weight: 600
      color: #1890ff
  .p-row
    font-size: 12px
    border-bottom: 2px dotted #ccc
    margin: 4px
    .val
      top: 8px
      background: #FFF
      padding: 0 0px 0 5px
    .ico
      width: 25px
      text-align: center
      display: inline-block
    .name
      top: 8px
      background: #FFF
      padding: 0 10px 0 0px
      max-width: 220px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

      .num
        font-weight: 700
  padding: 10px
  .t1
    color: #00cd6b
  .t2
    color: #ff4545
  .t3
    color: #ff9a3e
  h3
    padding: 0 0 6px 0
    font-size: 14px
    font-weight: 600
    margin-bottom: 15px
    color: #be4178
    text-transform: uppercase
    border-bottom: 1px solid #ececec
</style>
