var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(_vm.log.list.length > 0)?_c('div',{staticClass:"system-logs"},[_c('aTimeline',{staticClass:"pd-20 pl-10"},_vm._l((_vm.log.list),function(item,index){return _c('a-timeline-item',{key:index,attrs:{"color":_vm.color}},[(item.module === 'opportunity')?_c('aIcon',{staticStyle:{"color":"red"},attrs:{"slot":"dot","type":"fire"},slot:"dot"}):_vm._e(),_vm._v(" "),(
            item.module === 'contract' &&
            ![
              'contract-manual-anti-fraud-concluded',
              'contract-manual-anti-fraud-status',
              'send-contract-email',
            ].includes(item.action)
          )?_c('aIcon',{staticClass:"f16",staticStyle:{"color":"#52c41a"},attrs:{"slot":"dot","type":"file-text"},slot:"dot"}):_vm._e(),_vm._v(" "),(
            item.module === 'contract' &&
            item.action === 'send-contract-email'
          )?_c('aIcon',{staticClass:"f16",staticStyle:{"color":"#2db7f5"},attrs:{"slot":"dot","type":"mail"},slot:"dot"}):_vm._e(),_vm._v(" "),(item.module === 'sale')?_c('aIcon',{staticClass:"f16",staticStyle:{"color":"#0083cd"},attrs:{"slot":"dot","type":"shopping"},slot:"dot"}):_vm._e(),_vm._v(" "),(
            ['sale', 'contract'].includes(item.module) &&
            item.action === 'contract-manual-anti-fraud-concluded'
          )?_c('img',{attrs:{"slot":"dot","src":require("@/assets/images/dashboard/contracts/fraud-green.png"),"alt":"ico","width":"18"},slot:"dot"}):_vm._e(),_vm._v(" "),(
            ['sale', 'contract'].includes(item.module) &&
            item.action === 'contract-manual-anti-fraud-status'
          )?_c('img',{attrs:{"slot":"dot","src":require("@/assets/images/dashboard/contracts/fraud-red.png"),"alt":"ico","width":"17"},slot:"dot"}):_vm._e(),_vm._v(" "),(_vm.showAction)?_c('a-tag',{staticClass:"ml-10 mr-10",attrs:{"color":_vm.theColor(item.module)}},[_vm._v("\n          "+_vm._s(item.action)+"\n        ")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"f13 cblack",domProps:{"innerHTML":_vm._s(item.description)}}),_vm._v(" "),_c('div',{staticStyle:{"margin":"-5px 0 0 -1px"}},[_c('span',{staticClass:"f10",staticStyle:{"color":"#aaa"}},[_c('aIcon',{staticClass:"cgray",attrs:{"type":"clock-circle"}}),_vm._v("\n            "+_vm._s(_vm.formatDate(item.created))+"\n            "),_c('a-divider',{attrs:{"type":"vertical"}}),_vm._v("\n            "+_vm._s(_vm.formatHayaModules(item.module))+"\n          ")],1)])],1)}),1),_vm._v(" "),_c('div',{staticClass:"mt-20"},[_c('a-pagination',{attrs:{"show-size-changer":"","default-current":_vm.log.pagination.page,"page-size-options":_vm.pageSizeOptions,"page-size":_vm.log.pagination.perPage,"total":_vm.log.pagination.total},on:{"update:pageSize":function($event){return _vm.$set(_vm.log.pagination, "perPage", $event)},"update:page-size":function($event){return _vm.$set(_vm.log.pagination, "perPage", $event)},"change":_vm.changeMultiLogPage,"showSizeChange":_vm.changeMultiLogPageSize}})],1)],1):_c('div',{staticClass:"no-results mt-60"},[_c('a-empty',{attrs:{"description":"Nenhuma alteração realizada até o momento."}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }