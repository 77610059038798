var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aFormItem',{staticClass:"hf-wrapper hf-select-wrapper"},[(_vm.label)?_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_vm._v(" "),(_vm.description)?_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.description))]):_vm._e(),_vm._v(" "),_c('aSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
      _vm.fieldKey,
      {
        initialValue: _vm.initialValue,
        rules: _vm.validationRules,
      },
    ]),expression:"[\n      fieldKey,\n      {\n        initialValue,\n        rules: validationRules,\n      },\n    ]"}],staticClass:"hf-select",class:_vm.fieldClass,attrs:{"placeholder":_vm.placeholder || 'Selecione',"optionFilterProp":_vm.optionFilterProp,"allowClear":_vm.allowClear,"dropdownMatchSelectWidth":_vm.dropdownMatchSelectWidth,"showSearch":_vm.showSearch,"loading":_vm.loading,"disabled":_vm.disabled},on:{"change":_vm.onChange,"search":_vm.onSearch,"select":_vm.onSelect}},_vm._l((_vm.list),function(item,index){return _c('aSelectOption',{key:index,attrs:{"value":item.value,"txt":item.label,"obj":item}},[_c('div',{domProps:{"innerHTML":_vm._s(item.label)}})])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }