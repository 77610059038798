<template>
    <div>
        <a-table :pagination="false" v-bind="$attrs" v-on="$listeners">
            <template
                v-for="slot in Object.keys($scopedSlots)"
                :slot="slot"
                slot-scope="scope"
                ><slot :name="slot" v-bind="scope"
            /></template>
        </a-table>
        <div class="a-center mt-20">
            <a-pagination
                show-size-changer
                :default-current="paginator.page"
                :total="paginator.total"
                @change="changePage"
                @showSizeChange="changePageSize"
                  :page-size.sync="paginator.perPage"
                :page-size-options="pageSizeOptions"
            >
                <template slot="buildOptionText" slot-scope="props">
                    <span v-if="props.value !== '99999'"
                        >{{ props.value }}/página</span
                    >
                    <span v-if="props.value === '99999'"> Todos</span>
                </template>
            </a-pagination>
        </div>
    </div>
</template>

<script>
export default {
    name: "HayaTable",
    props: {
        paginator: Object,
    },
    data() {
        return {
            pageSizeOptions: [
                "10",
                "25",
                "50",
                "100",
                "200",
                "500",
                "1000",
                "99999",
            ],
        };
    },
    methods: {
        changePage(current) {
            this.paginator.page = current;
            this.$emit("getList", true);
            //this.getHotels();
        },
        changePageSize(current, pageSize) {
            this.paginator.page = current;
            this.paginator.perPage = pageSize;
            this.$emit("getList", true);
            //this.getHotels();
        },
    },
};
</script>
