<template>
  <div>
    <a-row style="padding-right: 10px">
      <a-col
        class="content-row"
        v-for="(item, index) in theList().arr"
        :key="index"
        :span="24"
      >
        <h3>{{ index + 1 }} - {{ item.name }}</h3>
        <div class="list">
          <a-row class="infos" :gutter="20">
            <a-col :span="12">
              <span class="label">Serviço:</span>
              {{ item.type }}
            </a-col>

            <a-col :span="12">
              <span class="label">Localizador:</span>
              {{ item.locator }}
            </a-col>

            <a-col class="mb-5 mt-20" :span="24"><h4>VIAJANTES</h4> </a-col>

            <a-col class="travellers upper" :span="24">
              <a-row
                class="traveller"
                v-for="(traveller, index) in theList().allTravellers"
                :key="index"
              >
                <a-col :span="18">
                  {{ traveller.name }}
                </a-col>

                <a-col :span="6">
                  {{ formatMultiDates(traveller.birthday) }}
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";

export default {
  name: "ContractServiceDetails",
  props: {
    tempContract: Object,
  },
  mixins: [formatThings],
  data() {
    return {};
  },
  methods: {
    thePassenger(id) {
      let travellers = JSON.parse(this.tempContract.travellers_rows);
      let theTraveller = {};

      travellers.forEach((traveller) => {
        if (traveller.id == id) {
          theTraveller.name = `${
            this.tempContract[`traveller_${traveller.id}_first_name`]
          } ${this.tempContract[`traveller_${traveller.id}_last_name`]}`;

          theTraveller.birthday = `${
            this.tempContract[`traveller_${traveller.id}_birthday`]
          }`;

          if (this.tempContract[`traveller_${traveller.id}_gender`]) {
            theTraveller.gender = `${
              this.tempContract[`traveller_${traveller.id}_gender`]
            }`;
          }
        }
      });

      return theTraveller;
    },
    theList() {
      let arr = [];
      let allTravellers = [];
      let contractedServices = JSON.parse(
        this.tempContract.contracted_services
      );

      if (contractedServices.includes("service")) {
        if (this.tempContract["service_travellers"]) {
          JSON.parse(this.tempContract["service_travellers"]).forEach(
            (service) => {
              allTravellers.push(
                this.thePassenger(
                  this.tempContract[`service_traveller_${service.id}`]
                )
              );
            }
          );
        }

        JSON.parse(this.tempContract["service_rows"]).forEach((service) => {
          arr.push({
            name: this.tempContract[`service_${service.id}_name`],
            type: this.tempContract[`service_${service.id}_type`],
            locator: this.tempContract[`service_${service.id}_locator`],
          });
        });
      }

      return { arr, allTravellers };
    },
  },
};
</script>

<style lang="sass" scoped>
.content-row
  background: #FFF
  padding: 10px
  border: 1px solid #ddd !important
  margin: 0 0 10px
  border-radius: 6px
  font-size: 13px
  font-weight: 500
  .label
    display: block
    font-size: 12px
    font-weight: 500
    color: #aaa
  h4
    font-size: 10px !important
    font-weight: 600
    color: #be4178
  .travellers
    .traveller
      padding: 0 0 8px 0
      margin: 0 0 8px
      border-bottom: 1px solid #eee
    .traveller:last-child
      border: 0
      padding-bottom:0
    .font
      font-weight: 600
      border-radius: 4px
      font-size: 12px
      background: #eee
      padding: 1px 5px 2px
      margin: 4px 4px 0px 0
      text-transform: uppercase
      word-break: break-all
      float: left
  .p-row.sub
    .name
      top: 8px
      background: #FFF
      padding: 0 10px 0 22px
  .p-row.no-dot
    border: 0
    .name
      max-width: 100%
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    .name.hotel
      color: #00c100
      font-weight: 600
    .name.service
      color: #ff8000
      font-weight: 600
    .name.flight
      font-weight: 600
      color: #1890ff
  .p-row
    font-size: 12px
    border-bottom: 2px dotted #ccc
    margin: 4px
    .val
      top: 8px
      background: #FFF
      padding: 0 0px 0 5px
    .ico
      width: 25px
      text-align: center
      display: inline-block
    .name
      top: 8px
      background: #FFF
      padding: 0 10px 0 0px
      max-width: 220px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

      .num
        font-weight: 700
  padding: 10px
  .t1
    color: #00cd6b
  .t2
    color: #ff4545
  .t3
    color: #ff9a3e
  h3
    text-transform: uppercase
    padding: 0 0 6px 0
    font-size: 14px
    font-weight: 600
    margin-bottom: 15px
    color: #be4178
    border-bottom: 1px solid #ececec
</style>
d
