export default {
  data() {
    return {
      cardMachines: {
        loading: false,
        pagination: {
          page: 1,
          perPage: 20,
          total: 0,
          totalPages: 0,
        },
        details: {},
        list: [],
        filters: {
          users: {
            list: [],
            selected: [],
          },
          company: {
            list: [],
            selected: [],
          },
          companyBranch: {
            list: [],
            selected: [],
          },
          status: {
            list: [
              {
                label: "Ativo",
                value: "Ativo",
              },
              {
                label: "Desativado",
                value: "Desativado",
              },
            ],
            selected: [],
          },
          paymentLinkActiveMachine: {
            list: [
              {
                label: "Ativo",
                value: "1",
              },
              {
                label: "Desativado",
                value: "0",
              },
            ],
            selected: [],
          },
          paymentLinkStatus: {
            list: [
              {
                label: "Ativo",
                value: "Ativo",
              },
              {
                label: "Desativado",
                value: "Desativado",
              },
            ],
            selected: [],
          },
          period: {
            selected: [],
          },
          searchTerm: "",
          order: "desc",
          orderBy: "created",
        },
      },
    };
  },
  methods: {
    changeCardMachinesPage(current) {
      this.cardMachines.pagination.page = current;
      this.getCardMachines();
    },
    changeCardMachinesPageSize(current, pageSize) {
      this.cardMachines.pagination.page = current;
      this.cardMachines.pagination.perPage = pageSize;
      this.getCardMachines();
    },
    cardMachinesTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      this.cardMachines.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.cardMachines.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getCardMachines();
    },
    cardMachinesFilters() {
      let filters = "";

      if (this.cardMachines.filters.searchTerm)
        filters += `&s=${this.cardMachines.filters.searchTerm}`;

      if (this.cardMachines.filters.status.selected.length > 0)
        filters += `&status=${this.cardMachines.filters.status.selected}`;

      if (this.cardMachines.filters.company.selected.length > 0)
        filters += `&company_id=${this.cardMachines.filters.company.selected}`;

      if (
        this.cardMachines.filters.paymentLinkActiveMachine.selected.length > 0
      )
        filters += `&payment_link_active_machine=${this.cardMachines.filters.paymentLinkActiveMachine.selected}`;

      if (this.cardMachines.filters.companyBranch.selected.length > 0)
        filters += `&company_branch_id=${this.cardMachines.filters.companyBranch.selected}`;

      if (this.cardMachines.filters.paymentLinkStatus.selected.length > 0)
        filters += `&payment_link_status=${this.cardMachines.filters.paymentLinkStatus.selected}`;

      if (this.cardMachines.filters.users.selected.length > 0)
        filters += `&user_id=${this.cardMachines.filters.users.selected}`;

      filters += `&order=${this.cardMachines.filters.order}&order-by=${this.cardMachines.filters.orderBy}`;

      return filters;
    },
    async getCardMachinesList(samePage) {
      const data = await this.$http.get(
        `/card-machine/list?page=${
          this.cardMachines.pagination.page
        }&per_page=${
          this.cardMachines.pagination.perPage
        }${this.cardMachinesFilters()}`
      );

      try {
        data;

        samePage;
      } catch (err) {
        err;
      }
    },
    getCardMachines(samePage) {
      this.cardMachines.loading = true;

      return this.$http
        .get(
          `/card-machine/list?page=${
            this.cardMachines.pagination.page
          }&per_page=${
            this.cardMachines.pagination.perPage
          }${this.cardMachinesFilters()}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.cardMachines.pagination.page = 1;
          }
          this.cardMachines.pagination.total = data.meta.total;
          this.cardMachines.pagination.totalPages = data.meta.total_pages;
          this.cardMachines.list = data.data;
          this.cardMachinesByCompany();
          this.cardMachines.loading = false;
        })
        .catch(({ response }) => {
          this.cardMachines.loading = false;
          this.cardMachines.list = response.data.data;
          this.cardMachinesByCompany();
          this.cardMachines.pagination.total = response.data.meta.total;
          this.cardMachines.pagination.totalPages =
            response.data.meta.total_pages;
        });
    },
  },
};
