var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('aRow',[_c('aCol',{staticClass:"content-row",attrs:{"span":24}},[_c('h3',[_c('aRow',{attrs:{"type":"flex","justify":"space-between"}},[_c('aCol',[_vm._v(" Links de Pagamento ")]),_vm._v(" "),_c('aCol')],1)],1),_vm._v(" "),_vm._l((_vm.paymentLinkRows),function(link,index){return _c('div',{key:index,staticClass:"credit-card"},[_c('aRow',{staticClass:"infos",attrs:{"gutter":20}},[_c('aCol',{staticClass:"mb-10",attrs:{"span":24}},[_c('h4',[_c('a-icon',{attrs:{"type":"link"}}),_vm._v(" LINK PAGAMENTO #"+_vm._s(link.id))],1)]),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('span',{staticClass:"label"},[_vm._v("Total Link de Pagamento:")]),_vm._v("\n            "+_vm._s(_vm.formatPricePtBr(
                _vm.tempContract[
                  `payment_methods_payment_link_total_value_${link.id}`
                ]
              ))+"\n          ")]),_vm._v(" "),_c('aCol',{attrs:{"span":3}},[_c('span',{staticClass:"label"},[_vm._v("Parcelas:")]),_vm._v("\n            "+_vm._s(_vm.tempContract[
                `payment_methods_payment_link_installments_${link.id}`
              ])+"\n          ")]),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_c('span',{staticClass:"label"},[_vm._v("Valor parcelas:")]),_vm._v("\n            "+_vm._s(_vm.formatMultiPricePtBr(
                _vm.tempContract[
                  `payment_methods_payment_link_installments_value_${link.id}`
                ]
              ))+"\n          ")])],1),_vm._v(" "),_c('aRow',{staticClass:"mt-10",attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":6}},[_c('span',{staticClass:"label"},[_vm._v("Maquininha:")]),_vm._v("\n            "+_vm._s(_vm.tempContract[
                `payment_methods_payment_link_machine_id_${link.id}`
              ] == 14
                ? "Direto com fornecedor"
                : _vm.theCreditCardMachine(
                    _vm.tempContract[
                      `payment_methods_payment_link_machine_id_${link.id}`
                    ]
                  ))+"\n          ")]),_vm._v(" "),(
              _vm.tempContract[
                `payment_methods_payment_link_machine_id_${link.id}`
              ] == 14
            )?_c('aCol',{attrs:{"span":10}},[_c('span',{staticClass:"label"},[_vm._v("Fornecedor:")]),_vm._v("\n            "+_vm._s(_vm.tempContract[
                `payment_methods_payment_link_machine_supplier_${link.id}`
              ])+"\n          ")]):_vm._e(),_vm._v(" "),(
              _vm.tempContract[
                `payment_methods_payment_link_machine_id_${link.id}`
              ] != 14
            )?_c('aCol',{attrs:{"span":4}},[_c('span',{staticClass:"label"},[_vm._v("Taxa PDQ:")])]):_vm._e(),_vm._v(" "),(
              _vm.tempContract[
                `payment_methods_payment_link_machine_id_${link.id}`
              ] != 14
            )?_c('aCol',{attrs:{"span":4}},[_c('span',{staticClass:"label"},[_vm._v("Taxa Adm:")])]):_vm._e(),_vm._v(" "),(
              _vm.tempContract[
                `payment_methods_payment_link_machine_id_${link.id}`
              ] != 14
            )?_c('aCol',{attrs:{"span":4}},[_c('span',{staticClass:"label"},[_vm._v("Total Taxas:")])]):_vm._e(),_vm._v(" "),(
              _vm.tempContract[`payment_methods_payment_link_url_${link.id}`] !==
              undefined
            )?_c('aCol',{staticClass:"f10",attrs:{"span":24}},[_c('span',{staticClass:"label"},[_vm._v("Link:")]),_vm._v("\n            "+_vm._s(_vm.tempContract[`payment_methods_payment_link_url_${link.id}`])+"\n          ")]):_vm._e()],1)],1)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }