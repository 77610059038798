<template>
  <div>
    <a-page-header class="mt-30 pd-0 mb-10">
      <div slot="title">
        <h1>
          <img
            class="mr-5"
            src="@/assets/images/dashboard/contracts/livelo-color.png"
            alt="img"
            width="20"
          />
          Regra de Pontuação Livelo
        </h1>
      </div>

      <div slot="extra">
        <a-popconfirm
          title="Tem certeza que deseja adicionar nova regra?"
          ok-text="Sim"
          placement="left"
          cancel-text="Não"
          @confirm="addRule"
        >
          <a-button :loading="createLoading" type="primary" ghost>
            Adicionar nova regra
          </a-button>
        </a-popconfirm>
      </div>
    </a-page-header>

    <a-alert
      class="f12 mb-20"
      type="info"
      message="Cadastre as regras de pontuação Livelo."
      banner
    />

    <a-table
      class="travel-table"
      :columns="[
        {
          title: 'Nome',
          scopedSlots: { customRender: 'name' },
          width: 200,
          sorter: true,
        },

        {
          title: 'Regra',
          width: 80,
          scopedSlots: { customRender: 'rule' },
        },

        {
          title: 'Valor',
          width: 160,
          scopedSlots: { customRender: 'value' },
        },

        {
          title: 'Data inicial',
          width: 100,
          scopedSlots: { customRender: 'start_date' },
        },

        {
          title: 'Data final',
          width: 100,
          scopedSlots: { customRender: 'end_date' },
        },

        {
          title: 'Product Code',
          scopedSlots: { customRender: 'product_code' },
          width: 160,
        },

        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          class: 'status',
          sorter: true,
        },
        {
          title: '',
          key: 'action',
          align: 'right',
          width: 100,
          scopedSlots: { customRender: 'action' },
        },
      ]"
      :data-source="liveloPointsRule.list"
      :loading="liveloPointsRule.loading"
      :pagination="false"
    >
      <div slot="name" slot-scope="record">
        {{ record.name ? record.name : "Nome da regra" }}
      </div>

      <div slot="product_code" slot-scope="record">
        {{ record.product_code }}
      </div>

      <div slot="rule" slot-scope="record">
        {{ record.rule }}
      </div>
      <div slot="value" slot-scope="record">R$ {{ record.value }}</div>
      <div slot="start_date" slot-scope="record">
        {{
          record.start_date != "0000-00-00"
            ? formatDate(record.start_date)
            : "---"
        }}
      </div>
      <div slot="end_date" slot-scope="record">
        {{
          record.end_date != "0000-00-00" ? formatDate(record.end_date) : "---"
        }}
      </div>
      <div class="travel-actions" slot="action" slot-scope="record">
        <a-icon
          class=""
          type="edit-svg"
          @click="edit(record.raw)"
          style="zoom: 0.03"
        />

        <a-popconfirm
          title="Tem certeza que deseja apagar?"
          ok-text="Sim"
          placement="left"
          cancel-text="Não"
          @confirm="deleteRow(record.raw.id)"
        >
          <a-icon class="red ml-10 f14 c-pointer" type="delete" />
        </a-popconfirm>
      </div>
    </a-table>

    <div class="a-center mt-30" style="padding-bottom: 100px">
      <a-pagination
        show-size-changer
        :default-current="liveloPointsRule.pagination.page"
        :page-size.sync="liveloPointsRule.pagination.perPage"
        :total="liveloPointsRule.pagination.total"
        @change="changeLiveloPointsRulesPage"
        @showSizeChange="changeLiveloPointsRulePageSize"
      >
      </a-pagination>
    </div>

    <a-drawer
      placement="right"
      width="600px"
      :closable="true"
      :visible="openEditRuleModal"
      @close="openEditRuleModal = false"
    >
      <template slot="title"> Editar regra de pontuação </template>
      <LiveloPointsRuleEditModal
        :theRule="theRule"
        v-if="openEditRuleModal"
        @reloadLiveloPointsRuleList="getLiveloPointsRule()"
      />
    </a-drawer>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import LiveloPointsRuleEditModal from "./LiveloPointsRuleEditModal.vue";

export default {
  components: { LiveloPointsRuleEditModal },
  mixins: [formatThings],
  data() {
    return {
      theRule: {},
      openEditRuleModal: false,
      createLoading: false,
      liveloPointsRule: {
        loading: false,
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          totalPages: 0,
        },
        tempContract: {},
        details: {},
        list: [],
        filters: {
          loadingResults: false,
          users: {
            list: [],
            selected: [],
          },
          period: {
            selected: [],
          },
          id: "",
          module: "",
          searchTerm: "",
          order: "descend",
          orderBy: "created",
        },
        meta: {},
      },
    };
  },
  mounted() {
    this.getLiveloPointsRule();
  },
  methods: {
    edit(rule) {
      this.theRule = rule;
      this.openEditRuleModal = true;
    },
    deleteRow(id) {
      this.$http
        .post(`/livelo-points-rule/delete?id=${id}`)
        .then(({ data }) => {
          this.$message.success("Apagado com sucesso!");
          data;
          this.getLiveloPointsRule();
        })
        .catch(({ response }) => {
          response;
          this.$message.error("Não foi possível apagar.");
        });
    },
    addRule() {
      this.createLoading = true;

      let theRule = {};
      theRule.status = "Ativo";
      theRule.user_id = this.$store.state.userData.id;
      theRule.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.$http
        .post("/livelo-points-rule/create", theRule)
        .then(({ data }) => {
          data;
          this.createLoading = false;
          this.getLiveloPointsRule();
        })
        .catch(({ response }) => {
          this.createLoading = false;
          response;
        });
    },
    changeLiveloPointsRulesPage(current) {
      this.liveloPointsRule.pagination.page = current;
      this.getLiveloPointsRule(current);
    },
    changeLiveloPointsRulePageSize(current, pageSize) {
      this.liveloPointsRule.pagination.page = current;
      this.liveloPointsRule.pagination.perPage = pageSize;
      this.getContracts();
    },
    liveloPointsRuleTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      this.liveloPointsRule.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.liveloPointsRule.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getContracts();
    },

    getLiveloPointsRule(samePage) {
      this.liveloPointsRule.loading = true;

      let filters = "";

      if (this.liveloPointsRule.filters.users.selected.length > 0) {
        filters += `&user_id=${this.liveloPointsRule.filters.users.selected}`;
      }

      if (this.liveloPointsRule.filters.period.selected.length > 0) {
        filters += `&period=${this.liveloPointsRule.filters.period.selected[0]}|${this.liveloPointsRule.filters.period.selected[1]}`;
      }
      if (this.liveloPointsRule.filters.id) {
        filters += `&module_id=${this.liveloPointsRule.filters.id}`;
      }

      if (this.liveloPointsRule.filters.module) {
        filters += `&module=${this.liveloPointsRule.filters.module}`;
      }

      if (this.liveloPointsRule.filters.showPolicy) {
        filters += `&show_policy=${this.liveloPointsRule.filters.showPolicy}`;
      }

      if (this.liveloPointsRule.filters.searchTerm) {
        filters += `&s=${this.liveloPointsRule.filters.searchTerm}`;
      }

      filters += `&order=${this.liveloPointsRule.filters.order}&order-by=${this.liveloPointsRule.filters.orderBy}`;

      this.$http
        .get(
          `/livelo-points-rule/list?page=${this.liveloPointsRule.pagination.page}&per_page=${this.liveloPointsRule.pagination.perPage}${filters}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.liveloPointsRule.pagination.page = 1;
          }
          this.liveloPointsRule.pagination.total = data.meta.total;
          this.liveloPointsRule.pagination.totalPages = data.meta.total_pages;

          this.liveloPointsRule.list = data.data;
          this.liveloPointsRule.meta = data.meta;

          this.liveloPointsRule.loading = false;
        })
        .catch(({ response }) => {
          this.liveloPointsRule.pagination.total = response.data.meta.total;
          this.liveloPointsRule.pagination.totalPages =
            response.data.meta.total_pages;

          this.liveloPointsRule.list = [];
          this.liveloPointsRule.loading = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.rule-row
  margin-bottom: 30px
h1
  font-size: 24px
</style>
