<template>
  <div>
    <div class="tabs">
      <span
        v-if="paymentMethods.includes('billing-ticket')"
        :class="activeTab == 'billing-ticket' ? 'active' : ''"
        @click="activeTab = 'billing-ticket'"
      >
        <img
          src="@/assets/images/dashboard/barcode.png"
          height="26"
          alt="img"
        />
      </span>
      <span
        v-if="paymentMethods.includes('payment-card')"
        :class="activeTab == 'payment-card' ? 'active' : ''"
        @click="activeTab = 'payment-card'"
      >
        <a-tooltip placement="top" title=" Carta de Crédito">
          <img
            src="@/assets/images/dashboard/pay-card.png"
            alt="img"
            height="22"
          />
        </a-tooltip>
      </span>
      <span
        v-if="paymentMethods.includes('credit-card')"
        :class="activeTab == 'credit-card' ? 'active' : ''"
        @click="activeTab = 'credit-card'"
      >
        <a-tooltip placement="top" title="Cartão Crédito">
          <img
            src="@/assets/images/dashboard/credit-card.png"
            height="26"
            alt="img"
          />
        </a-tooltip>
      </span>
      <span
        v-if="paymentMethods.includes('payment-link')"
        :class="activeTab == 'payment-link' ? 'active' : ''"
        @click="activeTab = 'payment-link'"
      >
        <a-tooltip placement="top" :title="'Link de Pagamento'">
          <img src="@/assets/images/dashboard/link.png" height="20" alt="img" />
        </a-tooltip>
      </span>
      <span
        v-if="paymentMethods.includes('custom-payment')"
        :class="activeTab == 'custom-payment' ? 'active' : ''"
        @click="activeTab = 'custom-payment'"
      >
        <a-tooltip placement="top" :title="'Personalizado'">
          <img
            src="@/assets/images/dashboard/custom-payment.png"
            alt="img"
            height="23"
          />
        </a-tooltip>
      </span>
      <span
        v-if="paymentMethods.includes('bank-transfer')"
        :class="activeTab == 'bank-transfer' ? 'active' : ''"
        @click="activeTab = 'bank-transfer'"
      >
        <a-tooltip placement="top" :title="' Transferência Banc.'">
          <img
            src="@/assets/images/dashboard/bank-transfer.png"
            alt="img"
            height="22"
          />
        </a-tooltip>
      </span>
    </div>

    <div class="tabs-content">
      <div class="pd-20 a-center" v-if="paymentMethods.length == 0">
        Nenhum método de pagamento selecionado.
      </div>
      <div v-if="activeTab == 'billing-ticket'">
        <ContractPaymentMethodsBillingTicketDetails
          :tempContract="tempContract"
        />
      </div>
      <div v-if="activeTab == 'payment-card'">
        <ContractPaymentMethodsPaymentCardDetails
          :tempContract="tempContract"
        />
      </div>
      <div v-if="activeTab == 'credit-card'">
        <ContractPaymentMethodsCreditCardDetails :tempContract="tempContract" />
      </div>
      <div v-if="activeTab == 'payment-link'">
        <ContractPaymentMethodsPaymentLinkDetails
          :tempContract="tempContract"
        />
      </div>
      <div v-if="activeTab == 'custom-payment'">
        <ContractPaymentMethodsCustomPaymentDetails
          :tempContract="tempContract"
        />
      </div>
      <div v-if="activeTab == 'bank-transfer'">
        <ContractPaymentMethodsBankTransferDetails
          :tempContract="tempContract"
        />
      </div>
    </div>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
import ContractPaymentMethodsCustomPaymentDetails from "./ContractPaymentMethodsCustomPaymentDetails.vue";
import ContractPaymentMethodsBankTransferDetails from "./ContractPaymentMethodsBankTransferDetails.vue";
import ContractPaymentMethodsPaymentCardDetails from "./ContractPaymentMethodsPaymentCardDetails.vue";
import ContractPaymentMethodsBillingTicketDetails from "./ContractPaymentMethodsBillingTicketDetails.vue";
import ContractPaymentMethodsCreditCardDetails from "./ContractPaymentMethodsCreditCardDetails.vue";
import ContractPaymentMethodsPaymentLinkDetails from "./ContractPaymentMethodsPaymentLinkDetails.vue";

export default {
  name: "ContractPaymentMethodsDetails",
  components: {
    ContractPaymentMethodsCustomPaymentDetails,
    ContractPaymentMethodsBankTransferDetails,
    ContractPaymentMethodsPaymentCardDetails,
    ContractPaymentMethodsBillingTicketDetails,
    ContractPaymentMethodsCreditCardDetails,
    ContractPaymentMethodsPaymentLinkDetails,
  },
  props: {
    tempContract: Object,
  },
  mixins: [formatThings],
  data() {
    return {
      activeTab: 0,
      paymentMethods: [],
    };
  },
  mounted() {
    this.paymentMethods = this.tempContract.payment_methods
      ? JSON.parse(this.tempContract.payment_methods)
      : [];

    this.activeTab = this.paymentMethods[0];
  },
};
</script>

<style lang="sass" scoped>
//2294
.tabs
  span
    background: #FFF
    height: 45px
    width: 50px
    line-height: 2.2
    display: inline-block
    margin-right: 10px
    border: 1px solid #ddd
    padding: 6px
    text-align: center
    border-radius: 6px
    cursor: pointer
    img
      opacity: 0.3
  span.active
    img
      opacity: 1

.tabs-content
  background: #FFF
  padding: 10px
  border: 1px solid #ddd !important
  margin: 10px 0 10px
  border-radius: 6px
  font-size: 12px
  font-weight: 600
  padding: 10px
</style>
