export default {
  data() {
    return {
      log: {
        loading: false,
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          totalPages: 0,
        },
        details: {},
        list: [],
        filters: {
          loadingResults: false,
          modulesArr: [],
          users: {
            list: [],
            selected: [],
          },
          period: {
            selected: [],
          },
          id: "",
          opportunityId: "",
          contractId: "",
          saleId: "",
          searchTerm: "",
          action: "",
          actionContains: "",
          order: "desc",
          orderBy: "created",
        },
      },
      createLog: false,
      logsTableCollumns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 80,
          sorter: true,
        },
        {
          title: "Usuário",
          key: "user",
          scopedSlots: {
            customRender: "user",
          },
        },
        {
          title: "Criado em",
          dataIndex: "created",
          key: "created",
          sorter: true,
        },
        {
          title: "",
          key: "action",
          align: "right",
          scopedSlots: {
            customRender: "action",
          },
        },
      ],
    };
  },
  methods: {
    changeMultiLogPage(current) {
      this.log.pagination.page = current;
      this.getMultiLogs();
    },
    changeMultiLogPageSize(current, pageSize) {
      this.log.pagination.page = current;
      this.log.pagination.perPage = pageSize;
      this.getMultiLogs();
    },
    changeLogPage(current) {
      this.log.pagination.page = current;
      this.getLogs();
    },
    changeLogPageSize(current, pageSize) {
      this.log.pagination.page = current;
      this.log.pagination.perPage = pageSize;
      this.getLogs();
    },
    logTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      this.log.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.log.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getLogs();
    },
    onLogSelected(id) {
      this.log.details = null;
      this.createLog = false;
      this.getLog(id);
    },
    getLog(id) {
      this.$http.post("/log/details?id=" + id).then(({ data }) => {
        this.log.details = data;
        this.logForm.setFieldsValue(data);
        if (this.$route.path.split("/")[1] == "logs") {
          document.title = `${data.id} - ${data.module} `;
        }
      });
    },
    logsFilters() {
      let filters = "";
      if (!this.$root.isAdmin())
        filters += `&user_id=${this.$store.state.userData.id}`;

      if (this.log.filters.id) filters += `&module_id=${this.log.filters.id}`;

      if (this.log.filters.opportunityId)
        filters += `&opportunity_id=${this.log.filters.opportunityId}`;

      if (this.log.filters.contractId)
        filters += `&contract_id=${this.log.filters.contractId}`;

      if (this.log.filters.saleId)
        filters += `&sale_id=${this.log.filters.saleId}`;

      if (this.log.filters.module)
        filters += `&module=${this.log.filters.module}`;

      if (this.log.filters.users.selected.length > 0)
        filters += `&user_id=${this.log.filters.users.selected}`;

      if (this.log.filters.period.selected.length > 0)
        filters += `&period=${this.log.filters.period.selected[0]}|${this.log.filters.period.selected[1]}`;

      if (this.log.filters.modulesArr.length > 0)
        filters += `&modules_arr=${this.log.filters.modulesArr}`;

      if (this.log.filters.searchTerm)
        filters += `&s=${this.log.filters.searchTerm}`;

      if (this.log.filters.action)
        filters += `&action=${this.log.filters.action}`;

      if (this.log.filters.actionContains)
        filters += `&action-contains=${this.log.filters.actionContains}`;

      filters += `&order=${this.log.filters.order}&order-by=${this.log.filters.orderBy}`;

      return filters;
    },
    getLogs(samePage) {
      this.log.loading = true;

      this.$http
        .get(
          `/log/list?page=${this.log.pagination.page}&per_page=${
            this.log.pagination.perPage
          }${this.logsFilters()}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.log.pagination.page = 1;
          }

          this.$emit("onLoadLogs", data.meta);

          this.log.pagination.total = data.meta.total;
          this.log.pagination.totalPages = data.meta.total_pages;

          this.log.list = data.data;
          this.log.loading = false;

          document.getElementById(`count-logs-${this.id}`).innerHTML =
            data.meta.total;
        })
        .catch(({ response }) => {
          this.log.pagination.total = response.data.meta.total;
          this.log.pagination.totalPages = response.data.meta.total_pages;

          this.log.list = [];
          this.log.loading = false;
        });
    },
    getMultiLogs(samePage) {
      this.log.loading = true;

      this.$http
        .get(
          `/log/list-multi?page=${this.log.pagination.page}&per_page=${
            this.log.pagination.perPage
          }${this.logsFilters()}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.log.pagination.page = 1;
          }

          this.log.pagination.total = data.meta.total;
          this.log.pagination.totalPages = data.meta.total_pages;

          this.log.list = data.data;
          this.log.loading = false;

          document.getElementById(`count-logs-${this.id}`).innerHTML =
            data.meta.total;
        })
        .catch(({ response }) => {
          this.log.pagination.total = response.data.meta.total;
          this.log.pagination.totalPages = response.data.meta.total_pages;

          this.log.list = [];
          this.log.loading = false;
        });
    },
  },
};
